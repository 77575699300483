<template>
  <svg viewBox="0 0 512.002 512.002" :class="classes" id="sewage-icon">
    <g>
      <g>
        <path d="M502.798,457.711c-30.69-15.42-66.736-14.479-96.537,1.936v-85.994c42.523-40.836,66.78-97.469,66.78-156.593
                        C473.04,97.383,375.676,0.019,256,0.019S38.959,97.383,38.959,217.059c0,59.124,24.256,115.757,66.782,156.593v85.993
                        c-29.804-16.415-65.85-17.353-96.537-1.934c-8.239,4.139-11.562,14.174-7.423,22.414c4.139,8.24,14.17,11.563,22.414,7.423
                        c23.07-11.591,50.648-9.518,71.973,5.407c36.24,25.369,83.079,25.371,119.32,0.001c24.611-17.226,56.414-17.227,81.025-0.001
                        c18.122,12.686,38.888,19.029,59.662,19.028c20.767-0.001,41.539-6.343,59.659-19.027c21.327-14.929,48.905-16.998,71.973-5.408
                        c8.241,4.14,18.274,0.815,22.414-7.423C514.36,471.886,511.037,461.85,502.798,457.711z M372.867,476.471
                        c-14.045,3.478-28.827,2.514-42.462-2.906c5.383-2.771,9.072-8.371,9.072-14.841v-74.709c0-9.22-7.475-16.695-16.695-16.695
                        s-16.695,7.475-16.695,16.695v74.709c0,0.316,0.03,0.626,0.047,0.938c-10.614-5.888-21.912-9.786-33.438-11.696v-30.56
                        c0-9.22-7.475-16.695-16.695-16.695c-9.22,0-16.695,7.475-16.695,16.695v30.56c-15.047,2.493-29.715,8.361-42.965,17.636
                        c-17.211,12.047-37.935,15.658-57.261,10.856c0.021-0.345,0.052-0.688,0.052-1.038V233.755c18.986,0,14.499,0,33.391,0v50.086
                        c0,9.22,7.475,16.695,16.695,16.695c9.22,0,16.695-7.475,16.695-16.695v-50.086h100.173v83.477c0,9.22,7.475,16.695,16.695,16.695
                        s16.695-7.475,16.695-16.695v-83.477h33.391V476.471z M140.32,200.364c8.126-56.561,56.906-100.173,115.679-100.173
                        s107.544,43.612,115.67,100.173C359.992,200.364,151.831,200.364,140.32,200.364z M256,66.8
                        c-82.853,0-150.259,67.406-150.259,150.259v105.534C84.227,291.933,72.35,255.106,72.35,217.059
                        c0-101.264,82.385-183.65,183.65-183.65s183.65,82.385,183.65,183.65c0,38.047-11.877,74.873-33.391,105.534V217.059
                        C406.259,134.207,338.852,66.8,256,66.8z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M189.218,333.927c-9.22,0-16.695,7.475-16.695,16.695v66.782c0,9.22,7.475,16.695,16.695,16.695
                        s16.695-7.475,16.695-16.695v-66.782C205.913,341.402,198.438,333.927,189.218,333.927z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M256,267.146c-9.22,0-16.695,7.475-16.695,16.695v66.782c0,9.22,7.475,16.695,16.695,16.695
                        c9.22,0,16.695-7.475,16.695-16.695v-66.782C272.695,274.621,265.22,267.146,256,267.146z"/>
      </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SewageIcon",
  props: ["class"],
  computed: {
    classes() {
      return this.class
    }
  }
}
</script>
<style>
#sewage-icon.success {
  fill: #198754;
}
#sewage-icon.primary {
  fill: #0d6efd;
}
</style>