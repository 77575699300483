<template>
  <div class="d-flex flex-column align-content-start ms-1">
    <div class="fs-5 fw-bold mt-2">Microdrenagem</div>
    <div class="ms-3">
      <div class="fw-bold mt-2">Institucionalização</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-switch
            label="Existência de padronização para projeto viário e drenagem pluvial?"
            v-model="microdrenagem.existe_padrao"
            class="col-12 col-md-12"
        />
        <va-switch
            label="Serviço de verificação e análise de projetos de pavimentação e/ou loteamentos?"
            v-model="microdrenagem.servico_analise"
            class="mt-2 col-12 col-md-12"
        />
        <va-switch
            label="Estrutura de inspeção e manutenção da drenagem?"
            v-model="microdrenagem.estrutura_inspecao"
            class="mt-2 col-12 col-md-12"
        />
        <va-switch
            label="Existência de monitoramento de chuva?"
            v-model="microdrenagem.monitoramento_chuva"
            class="mt-2 col-12 col-md-12"
        />
        <va-switch
            label="Registro de incidentes envolvendo microdrenagem?"
            v-model="microdrenagem.registro_incidentes"
            class="mt-2 col-12 col-md-12"
        />
      </div>

      <div class="fw-bold mt-3">Cobertura</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-number
            label="Extensão total de ruas com serviço de microdrenagem, em Km (Guias, sarjetas e bocas-de-lobo)"
            v-model="microdrenagem.extensao_ruas"
            class="mt-2 col-12 col-md-6"
            double
        />
        <va-number
            label="Extensão total de ruas urbanas do município"
            v-model="microdrenagem.extensao_ruas_urbanas"
            class="mt-2 col-12 col-md-6"
            double
        />
      </div>

      <div class="fw-bold mt-3">Eficiência</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-number
            label="Número de dias com incidentes na microdrenagem (alagamento devia até 30 Cm, refluxo pelos PVs e Bls)"
            v-model="microdrenagem.qtd_dias_incidentes"
            class="col-12 col-md-6"
        />
        <va-number
            label="Número de dias com chuva no ano"
            v-model="microdrenagem.qtd_dias_chuva_ano"
            class="mt-2 col-12 col-md-6"
        />
      </div>

      <div class="fw-bold mt-3">Gestão</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-number
            label="Número de bocas-de-lobo limpas"
            v-model="microdrenagem.qtd_bocas_lobo_limpas"
            class="col-12 col-md-6"
        />
        <va-number
            label="Total de bocas-de-lobo na área urbana"
            v-model="microdrenagem.qtd_total_bocas_lobo"
            class="mt-2 col-12 col-md-6"
        />
        <va-number
            label="Total de recursos gastos com microdrenagem"
            v-model="microdrenagem.total_gasto"
            class="mt-2 col-12 col-md-6"
            money
        />
        <va-number
            label="Total alocado no orçamento anual para microdrenagem"
            v-model="microdrenagem.total_alocado"
            class="mt-2 col-12 col-md-6"
            money
        />
      </div>
    </div>

    <div class="mt-4">
      <va-divider/>
    </div>

    <div class="fs-5 fw-bold mt-3">Macrodrenagem</div>
    <div class="ms-3">
      <div class="fw-bold mt-2">Institucionalização</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-switch
            label="Existencia de plano diretor urbanistico com tópicos relativos à drenagem?"
            v-model="macrodrenagem.existe_plano"
            class="col-12 col-md-12"
        />
        <va-switch
            label="Existência de plano diretor de drenagem urbana"
            v-model="macrodrenagem.existe_plano_urbano"
            class="mt-2 col-12 col-md-12"
        />
        <va-switch
            label="Legislação específica de uso e ocupação do solo que trata de impermeabilização, medidas mitigadoras e compensatórias?"
            v-model="macrodrenagem.legislacao_especifica"
            class="mt-2 col-12 col-md-12"
        />
        <va-switch
            label="Monitoramento de cursos d'água (nível e vazão)? "
            v-model="macrodrenagem.monitoramento_cursos"
            class="mt-2 col-12 col-md-12"
        />
        <va-switch
            label="Registro de Incidentes envolvendo a macrodrenagem?"
            v-model="macrodrenagem.registro_incidentes"
            class="mt-2 col-12 col-md-12"
        />
      </div>

      <div class="fw-bold mt-3">Cobertura</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-number
            label="Registro de intervenção da rede hídrica do município"
            v-model="macrodrenagem.registro_intervencao"
            class="mt-2 col-12 col-md-6"
            double
        />
        <va-number
            label="Extensão da rede hídrica do município"
            v-model="macrodrenagem.extensao_rede"
            class="mt-2 col-12 col-md-6"
            double
        />
      </div>

      <div class="fw-bold mt-3">Eficiência</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-number
            label="Número de dias com incidentes na macrodrenagem (transbordamento do córregos, derrubada de pontes, solapamento de margem, etc.)"
            v-model="macrodrenagem.qtd_dias_incidentes"
            class="mt-2 col-12 col-md-6"
        />
        <va-number
            label="Número de dias com chuva no ano"
            v-model="macrodrenagem.qtd_dias_chuva_ano"
            class="mt-2 col-12 col-md-6"
        />
      </div>

      <div class="fw-bold mt-3">Gestão</div>
      <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-number
            label="Total aplicado na limpeza de córregos/estrutura de macrodrenagem em geral"
            v-model="macrodrenagem.total_aplicado"
            class="mt-2 col-12 col-md-6"
            money
        />
        <va-number
            label="Total de recursos alocados para macrodrenagem"
            v-model="macrodrenagem.total_alocado"
            class="mt-2 col-12 col-md-6"
            money
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DadosDrenagem",
  props: ["microdrenagem", "macrodrenagem"]
}
</script>
<style>
@media (max-width: 576px) {
  .va-switch__label {
    font-size: 13px !important;
  }
}
</style>