import axios from 'axios'
import store from "../store/index"
import env from './env'

const actionScope = `loader`;

const instance = axios.create({
    baseURL: env.BASE_URL
});

//Interceptors
let requestsPending = 0;
const req = {
    pending: () => {
        requestsPending++;
        store.dispatch(`${actionScope}/show`);
    },
    done: () => {
        requestsPending--;
        if (requestsPending <= 0) {
            store.dispatch(`${actionScope}/hide`);
        }
    }
};
instance.interceptors.request.use((request) => {
        req.pending();

        //Verifica se tem token no sessionstorage, se tiver adiciona na request
        let token = sessionStorage.getItem('user-token');
        if (token) {
            request.headers['Content-Type'] = 'application/json'
            request.headers['Access-Control-Allow-Origin'] = '*'
            request.headers['Authorization'] = `Bearer ${token}`
            request.headers['Accept'] = "application/json"
        }

        return request;
    },
    (error) => {
        requestsPending--;
        req.done();
        return Promise.reject(error);
    }
);
instance.interceptors.response.use((response) => {
    req.done();
    if (response.data.token) {
        sessionStorage.setItem('user-token', response.data.token)
    }
    return Promise.resolve(response);
}, (error) => {
    req.done();
    if (error.response) {
        //console.log('%c ' + error.response.status + ':', 'color: #a71d5d; font-weight: bold', error.response);

        // let message
        // switch (error.response.status) {
        //     case 400:
        //         message = 'Erro na requisição.'
        //         break;
        //     case 401:
        //         message = 'Acesso não autorizado.'
        //         break;
        //     case 403:
        //         message = error.response.data.message || 'Acesso negado.'
        //         break;
        //     case 404:
        //         message = 'Recurso não encontrado.'
        //         break;
        //     case 419:
        //         message = 'Sessão expirou, por favor faça login novamente.'
        //         break;
        //     case 429:
        //         message = 'Muitas requisições, tente novamente mais tarde.'
        //         break;
        //     case 500:
        //         message = 'Erro interno do servidor.'
        //         break;
        //     case 503:
        //         message = 'App em manutenção.'
        //         break;
        //     default:
        //         message = 'Erro desconhecido.'
        // }

        // store.dispatch("requestError", message)
    }

    if ((error.response && error.response.status === 401) && error.response.config && !error.response.config.__isRetryRequest) {
        // if you ever get an unauthorized, logout the user
        store.dispatch("auth/doLogout")
    }
    return Promise.reject(error);
});
export default instance;