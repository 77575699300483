<template>
  <div class="d-flex flex-column align-content-start ms-1">
    <div class="border-start border-primary border-3 rounded ps-1 mt-3">
      <va-number
          label="Total de domicílios com atendimento da rede de esgoto"
          v-model="esgoto.total_domicilios_com_atendimento"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Total de domicílios urbanos com atendimento com rede de esgoto"
          v-model="esgoto.total_domicilios_urbanos_com_atendimento"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Total de domicílios na área de atendimento"
          v-model="esgoto.total_domicilios_area_atendimento"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Domicílios rurais dentro da área de atendimento"
          v-model="esgoto.domicilios_rurais_dentro_da_area"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Domicílios urbanos fora da área de atendimento"
          v-model="esgoto.domicilios_urbanos_fora_da_area"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Parcela da população da amostra satisfeita"
          v-model="esgoto.parcela_populacao_satisfeita"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Número de reclamações referente ao esgoto"
          v-model="esgoto.numero_reclamacoes"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Numero total de economias (ligações de esgoto)"
          v-model="esgoto.numero_economias"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Volume de esgoto tratado (L/s)"
          v-model="esgoto.volume_tratado"
          class="mt-3 col-12 col-md-6"
          double
      />
      <va-number
          label="Capacidade da ETE"
          v-model="esgoto.capacicade_ete"
          class="mt-3 col-12 col-md-6"
          double
      />
      <va-number
          label="Volume faturado de esgoto no ano"
          v-model="esgoto.volume_faturado"
          class="mt-3 col-12 col-md-6"
          double
      />
      <va-number
          label="Despesa operacional dos últimos 12 meses"
          v-model="esgoto.despesa_operacional"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Receita operacional dos últimos 12 meses"
          v-model="esgoto.receita_operacional"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Despesa com pessoal próprio"
          v-model="esgoto.despesa_pessoal_proprio"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Economias ativas à ETE "
          v-model="esgoto.economias_ete"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Número total de empregados"
          v-model="esgoto.numero_empregados"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Volume produzido de esgoto (L/hab/dia)"
          v-model="esgoto.volume_produzido"
          class="mt-3 col-12 col-md-6"
          double
      />
      <va-number
          label="Investimentos realizados no ano"
          v-model="esgoto.investimentos_realizados"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Serviços de divida no ano (financiamentos) (valor pago do financiamento no ano)"
          v-model="esgoto.servico_divida"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Tarifa média praticada"
          v-model="esgoto.tarifa_media"
          class="mt-3 col-12 col-md-6"
          money
      />
    </div>
    <div class="my-3">
      <va-divider />
    </div>
    <div class="d-flex justify-content-between">
      <span class="fw-bold">Análises de localidades</span>
      <div>
        <div v-if="this.localidades.length>1" @click="showDelete" class="btn btn-sm btn-outline-danger me-2" title="Excluir localidade">
          <i class="bi-trash"></i>
        </div>
        <div @click="add()" class="btn btn-sm btn-success" title="Adicionar localidade">
          <i class="bi-plus rounded rounded-circle"></i>
        </div>
      </div>
    </div>
    <va-tabs v-model="tab" grow>
      <template #tabs>
        <va-tab v-for="local in localidades" :name="local.tab" :key="local.tab" :ref="'tab_'+local.tab">
          #{{local.tab}} {{ local.descricao }}
        </va-tab>
      </template>
    </va-tabs>
    <div v-for="local in localidades">
      <div v-show="tab === local.tab" class="border-start border-primary border-3 rounded ps-1 mt-3">
        <div class="fw-bold">Localidade</div>
        <va-input
            label="descricao"
            v-model="local.descricao"
            class="mt-2 col-12 col-md-4 ms-2"
        />
        <div class="fw-bold mt-2">
          Coordenadas geográficas da coleta das análises
          <a v-if="local.latitude_coleta && local.longitude_coleta" :href="getLinkMaps(local)" target="_blank">Ver no mapa</a>
        </div>
        <va-input label="Latitude" v-model="local.latitude_coleta" class="mt-2 col-12 col-md-4 ms-2" />
        <va-input label="Longitude" v-model="local.longitude_coleta" class="mt-2 col-12 col-md-4 ms-2" />
        <va-number label="Altitude (m)" v-model="local.altitude" class="mt-2 col-12 col-md-4 ms-2" />

        <div class="mt-2 ms-2"> <!-- FILE UPLOAD DA EVIDENCIA -->
          <div v-if="!local.file_evidencia" class="btn btn-sm btn-outline-primary" @click="clickEvidencia(local)">
            Evidência <i class="fs-6 bi-paperclip"></i>
          </div>
          <span class="ms-2 text-primary">
            <img
                v-if="local.file_evidencia"
                :src="local.file_evidencia_url"
                class="mt-3"
                height="90"
            />
          </span>
          <div v-if="local.file_evidencia" @click="removeFile(local)" class="ms-2 btn btn-outline-danger btn-sm"><i class="fs-6 bi-trash"></i></div>
          <input type="file" name="evidencia" class="none" :ref="'evidencia_'+local.tab" @change="onSelectFile(local)"/>
        </div>

        <div class="fw-bold mt-2">Dados das análises</div>
        <va-number label="Análise de turbidez (NTU)" v-model="local.turbidez" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de pH" v-model="local.ph" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de N (Nitrogênio) (mgN/L)" v-model="local.nitrogenio" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de P (Fósforo) (mgP/L)" v-model="local.fosforo" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de temperatura (ºC)" v-model="local.temperatura" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de Residuos Totais (mg/L)" v-model="local.residuos" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de Coliformes Fecais (NMP/100ml)" v-model="local.coliformes" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de Oxigênio Dissolvido (mg/L)" v-model="local.oxigenio" class="mt-2 col-12 col-md-4 ms-2" double />
        <va-number label="Análise de Demanda Bioquímica de Oxigênio (mg/L)" v-model="local.demanda_bioquimica" class="mt-2 col-12 col-md-4 ms-2" double />
      </div>
    </div>

    <!--------- MODAL DELETE --------->
    <va-modal v-model="showModalDelete" hide-default-actions >
      <slot>
        <span class="text-primary">
          Confirma a exclusão da localidade <b> #{{local.tab}} {{ local.descricao }} </b> ?
        </span>
        <br/>
        <span class="text-muted fs-6">A exclusão será efetivada após salvar o relatório</span>
      </slot>
      <template #footer>
        <va-button @click="showModalDelete = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="remove" color="danger">
          Excluir
        </va-button>
      </template>
    </va-modal>
  </div>
</template>
<script>
export default {
  name: "DadosEsgoto",
  props: ["esgoto", "localidades"],
  emits: ["addLocalidade", "removeLocalidade"],
  data() {
    return {
      tab: this.localidades.length ? this.localidades[0].tab : null,
      showModalDelete: false,
      local: {}
    }
  },
  methods: {
    add() {
      this.$emit('addLocalidade')
    },
    showDelete() {
      this.local = this.localidades.find((l) => {
        return l.tab === this.tab
      })
      this.showModalDelete = true
    },
    async remove() {
      await this.$emit('removeLocalidade', this.tab)
      this.tab = this.localidades[0].tab
      this.showModalDelete = false
    },
    changeTab(tab) {
      this.$nextTick(function () {
        this.$refs['tab_' + tab].onTabClick()
      })
    },
    getLinkMaps(local) {
      return "https://www.google.com/maps/search/?api=1&query="+local.latitude_coleta+","+local.longitude_coleta+"&zoom=20&map_action=map"
    },
    clickEvidencia(local) {
      this.$refs['evidencia_'+local.tab].click()
    },
    onSelectFile(local) {
      let filename = this.$refs['evidencia_'+local.tab].value;
      if (filename) {
        local.file_evidencia_url = URL.createObjectURL(this.$refs['evidencia_'+local.tab].files[0])
        local.filename = filename.split(/(\\|\/)/g).pop()
        local.file_evidencia = this.$refs['evidencia_'+local.tab].files[0]
      } else {
        local.filename = ''
        local.file_evidencia = ''
      }
    },
    removeFile(local) {
      local.filename = ''
      local.file_evidencia = ''
    }
  }
}
</script>