import api from "./api-config";

const resource = "/users";
const limit = 15

export default {
    get(page = 1) {
        return api.get(`${resource}?page=${page}&limit=${limit}`);
    },
    create(data) {
        return api.post(`${resource}`, data)
    },
    update(usuarioId, data) {
        return api.put(`${resource}/${usuarioId}`, data)
    },
    updatePassword(usuarioId, data) {
        return api.put(`${resource}-pass/${usuarioId}`, data)
    },
    delete(usuarioId) {
        return api.delete(`${resource}/${usuarioId}`)
    },
    desbloquear(usuarioId) {
        return api.put(`${resource}/${usuarioId}/desbloquear`)
    },
}