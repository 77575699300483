<template>
  <div :class="['va-input-wrapper va-input va-input-focused va-input_labeled va-input_outline', classes]">
    <div class="va-input-wrapper__content">
      <div class="va-input__container" :class="{'va-input__container-focused': focus}">
        <div class="va-input__content-wrapper">
          <div class="va-input__content">
            <label aria-hidden="true" class="va-input__label" style="color: rgb(13, 110, 253)">{{label}}</label>
            <money3
                :id="id"
                :aria-label="label"
                class="va-input__content__input"
                @focusin="focus = true"
                @focusout="focus = false"
                v-bind="config"
                v-model="model"
              />
          </div>
        </div>
      </div>
      <div class="va-input-wrapper__message-list-wrapper"></div>
    </div>
  </div>
</template>

<script>
import { Money3Component } from 'v-money3'

export default {
  name: "VaNumber",
  props: {
    label: String,
    class: String,
    modelValue: [Number,  String],
    money: Boolean,
    double: Boolean,
  },
  emits: ['update:modelValue'],
  components: { money3: Money3Component },
  data() {
    return {
      focus: false,
      model: null,
      config: {}
    }
  },
  created() {
    this.model = null
    if (this.modelValue)
      this.model = this.money || this.double ? parseFloat(this.modelValue) : parseInt(this.modelValue)

    this.config = {
      decimal: ",",
      thousands: ".",
      prefix: this.money ? "R$ " : "",
      precision: this.money || this.double ? 2 : 0,
      masked: false
    }
  },
  watch: {
    modelValue(val) {
      this.model = this.money || this.double ? parseFloat(val) : parseInt(val)
    },
    model(val) {
      this.$emit("update:modelValue", val);
    },
  },
  computed: {
    classes() {
      return this.class
    },
    id() {
      return this.label + "_" + Math.floor((Math.random() * 100000))
    }
  }
}
</script>
<style scoped>
.va-input__container-focused {
  border-color: rgb(13, 110, 253) !important;
}
</style>