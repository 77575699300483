import api from "./api-config";

const resource = "/metas";

export default {
    get(id) {
        return api.get(`${resource}/${id}`)
    },
    create(data, id) {
        return api.post(`${resource}/${id}`, data)
    }
}