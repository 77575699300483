<template>
  <div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">Dashboard</div>
          <router-link :to="{name:'Dashboard'}" class="nav-link">
            <div class="sb-nav-link-icon"><i class="fs-5 bi-grid"></i></div>
            Painel inicial
          </router-link>

          <!-- SANEAMENTO -->
          <div class="sb-sidenav-menu-heading">Relatórios</div>
          <!--                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts"-->
          <!--                       aria-expanded="false" aria-controls="collapseLayouts">-->
          <!--                        <div class="sb-nav-link-icon"><i class="fs-5 bi-file-earmark-text"></i></div>-->
          <!--                        Relatórios-->
          <!--                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>-->
          <!--                    </a>-->
          <!--                    <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne"-->
          <!--                         data-bs-parent="#sidenavAccordion">-->
          <!--                        <nav class="sb-sidenav-menu-nested nav">-->
          <router-link :to="{name:'NovoRelatorio'}" class="nav-link">
            <div class="sb-nav-link-icon">
              <i class="fs-5 bi-plus-square"></i>
            </div>
            Novo
          </router-link>
          <router-link :to="{name:'VisualizarRelatorios'}" class="nav-link">
            <div class="sb-nav-link-icon">
              <i class="fs-5 bi-eye"></i>
            </div>
            Visualizar
          </router-link>
          <!--                        </nav>-->
          <!--                    </div>-->

          <!-- ÁREA ADMIN -->
          <template v-if="isAdmin">
            <div class="sb-sidenav-menu-heading">Sistema</div>
            <router-link :to="{name:'Usuarios'}" class="nav-link">
              <div class="sb-nav-link-icon"><i class="fs-5 bi-people"></i></div>
              Usuários
            </router-link>
          </template>
        </div>
      </div>
      <!--            <div class="sb-sidenav-footer">-->
      <!--                <div class="small">Logado como:</div>-->
      <!--                ...-->
      <!--            </div>-->
    </nav>
  </div>
</template>
<script>
import store from "../store";

export default {
  name: 'SideNav',
  data() {
    return {
      isAdmin: false
    }
  },
  created() {
    const role = store.getters["auth/getRole"]
    this.isAdmin = (role === 'Admin')
  }
}
</script>
<style>
.router-link-active:not(.not-active) {
  color: white !important;
  background-color: rgba(44, 130, 224, .3);
}

.router-link-active i {
  color: white;
}
</style>