<template v-if="relatorio">
  <div v-if="relatorio" class="relatorio lh-base">
    <va-modal v-model="showLoadingModal" hide-default-actions>
      <slot>
        <div class="text-center">
          <span class="text-primary">{{message}}</span>
        </div>
        <div v-if="!emitted" class="my-4">
          <va-inner-loading :loading="loading"></va-inner-loading>
        </div>
      </slot>
      <template #footer>
        <va-button @click="close()" outline>
          Fechar
        </va-button>
      </template>
    </va-modal>

    <!--=========== PDF 1 ===========-->
    <div id="pdf-content" class="m-3 text-center">
      <!--    CAPA   -->
      <div class="pt-4 h6 text-muted">Sistema Municipal de Informações de Saneamento</div>
      <img style="position: absolute;right: 50px;top: 20px;" class="img-fluid" width="70" src="./../assets/logo.png"/>

      <div class="row" style="height:300px">
        <div class="col-12 align-self-center h4 text-primary">RELATÓRIO DE INFORMAÇÕES DE SANEAMENTO</div>
      </div>
      <div class="row" style="height:300px">
        <div class="col-12 align-self-end">
          <img class="img-fluid" style="max-height: 130px" :src="relatorio.usuario.url"/>
        </div>
        <div class="col-12 align-self-start d-flex mt-5">
          <div class="align-self--start row border-5 border-end border-primary rounded me-5">
            <div class="offset-4 col-8 h2 pt-1 text-right justify">{{relatorio.usuario.nome}}</div>
            <div class="col-12 h1 text-right fw-bold">{{relatorio.periodo}}</div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="height: 300px">
        <div class="align-self-end h6 text-muted">www.smisa.com.br</div>
      </div>

      <div class="page-break"></div>

      <!-- TEXTO CONTRACAPA -->
      <div class="row mx-4">
        <div class="col-12 text-center mt-5">
          <div class="align-self-center lead fw-bold">
            INTRODUÇÃO
          </div>
          <div class="indent">
              <p class="mt-4">
                Considerando o inciso VI, Art.9 da Lei Federal nº 11.445/2007, o Plano Municipal de Saneamento Básico
                instituído pelo município, bem como visando o acesso à informação e controle social do saneamento local,
                apresentamos o SISTEMA MUNICIPAL DE INFORMAÇÕES EM SANEAMENTO.
              </p>
              <p class="mt-4">
                O SIMSA é composto de informações básicas do ano-base, de indicadores ambientais e de saneamento,
                bem como a evolução e execução das metas previstas no PMSB, abrangendo o abastecimento de água, esgotamento
                sanitário, resíduos sólidos e drenagem urbana.
              </p>
              <p class="mt-4">
                Com este intuito, apresenta-se a seguir, as informações do município, bem como dados baseados no PMSB,
                desenvolvimento de metas no ano-base para atingir as demandas do município na questão do acesso ao saneamento
                a toda a comunidade.
              </p>
          </div>
        </div>
      </div>
      <div class="page-break"></div>

      <div class="mx-5">

        <!-- DADOS GERAIS-->
        <div class="mt-3 text-start">
          <div class="lead fw-bold text-center">RELATÓRIO DE INFORMAÇÕES DE SANEAMENTO</div>
          <div class="fw-bold">{{relatorio.usuario.nome}}</div>
          <div>{{relatorio.usuario.endereco}}</div>
          <div>CNPJ: {{cnpjMask(relatorio.usuario.cnpj)}}</div>
          <div class="fw-bold">Período: {{relatorio.periodo}}</div>
          <div class="fw-bold">Localidade: {{relatorio.localidade}}</div>
          <div>População total: {{ format(relatorio.populacao_total) }}</div>
          <div>População urbana: {{ format(relatorio.populacao_urbana) }}</div>
        </div>

        <!--      DADOS ÁGUA    -->
        <div class="mt-3 text-start row">
          <div class="lead fw-bold">Dados referentes à água:</div>
          <div class="col-12 border border-bottom"></div>
          <div class="col-9">
            <div>Total de domicílios com atendimento da rede de água</div>
            <div>Total de domicílios urbanos com atendimento com rede de água</div>
            <div>Parcela da população da amostra satisfeita</div>
            <div>Número de reclamações por falta de água</div>
            <div>Numero total de economias (ligações de água)</div>
            <div>Total de domicílios na área de atendimento</div>
            <div>Domicílios rurais dentro da área de atendimento</div>
            <div>Domicílios urbanos fora da área de atendimento</div>
            <div>Numero total de economias com hidrometro</div>
            <div>Volume entregue de água (L/dia)</div>
            <div>Volume de água de uso social e operacional (L/dia)</div>
            <div>Volume faturado de água no ano</div>
            <div>Despesa operacional dos ultimos 12 meses</div>
            <div>Receita operacional ultimos 12 meses (o que foi arrecadado)</div>
            <div>Despesa com pessoal proprio</div>
            <div>Número total de empregados</div>
            <div>Volume de consumo (L/dia)</div>
            <div>Vazão produzida (L/s)</div>
            <div>Capacidade da ETA (L/s)</div>
            <div>Investimentos realizados no ano</div>
            <div>Serviços de dívida no ano (financiamentos) (valor pago do financiamento no ano)</div>
            <div>Tarifa média praticada</div>
          </div>
          <div class="col-3">
            <div>{{ format(relatorio.agua.total_domicilios_com_atendimento) }}</div>
            <div>{{ format(relatorio.agua.total_domicilios_urbanos_com_atendimento) }}</div>
            <br/>
            <div>{{ format(relatorio.agua.parcela_populacao_satisfeita) }}</div>
            <div>{{ format(relatorio.agua.numero_reclamacoes) }}</div>
            <div>{{ format(relatorio.agua.total_economias_ligacoes) }}</div>
            <div>{{ format(relatorio.agua.total_domicilios_area_atendimento) }}</div>
            <div>{{ format(relatorio.agua.domicilios_rurais_dentro_da_area) }}</div>
            <div>{{ format(relatorio.agua.domicilios_urbanos_fora_da_area) }}</div>
            <div>{{ format(relatorio.agua.total_economias_hidrometro, true) }}</div>
            <div>{{ format(relatorio.agua.volume_entregue, true) }}</div>
            <div>{{ format(relatorio.agua.volume_uso_social_operacional, true) }}</div>
            <div>{{ format(relatorio.agua.volume_faturado, true, true) }}</div>
            <div>{{ format(relatorio.agua.despesa_operacional, true, true) }}</div>
            <div>{{ format(relatorio.agua.receita_operacional, true, true) }}</div>
            <div>{{ format(relatorio.agua.despesa_pessoal_proprio, true, true) }}</div>
            <div>{{ format(relatorio.agua.total_empregados) }}</div>
            <div>{{ format(relatorio.agua.volume_consumo, true) }}</div>
            <div>{{ format(relatorio.agua.vazao_produzida, true) }}</div>
            <div>{{ format(relatorio.agua.capacidade_eta, true) }}</div>
            <div>{{ format(relatorio.agua.investimentos_realizados, true, true) }}</div>
            <div>{{ format(relatorio.agua.servicos_divida, true, true) }}</div>
            <br/>
            <div>{{ format(relatorio.agua.tarifa_media, true, true) }}</div>
          </div>
        </div>

        <div class="page-break"></div>

        <div class="mt-3 text-start">
          <div class="fw-bold">Dados mensais referentes à água:</div>
          <table class="table w-100 fs-7 mt-1">
            <thead>
              <tr>
                <th>Mês</th>
                <th class="text-center">Coliformes totais</th>
                <th class="text-center">Cloro residual</th>
                <th class="text-center">Turbidez</th>
                <th class="text-center">Fluoretos</th>
                <th class="text-center">Cor</th>
                <th class="text-center">pH</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="mes in relatorio.agua.meses" :key="mes.id">
                <td>{{ mes.abrev }}</td>
                <td class="text-center">{{ mes.bacteriologica ? "Sim" : "Não"}}</td>
                <td class="text-center">{{ format(mes.cloro, true) }}</td>
                <td class="text-center">{{ format(mes.turbidez, true) }}</td>
                <td class="text-center">{{ format(mes.fluoretos, true) }}</td>
                <td class="text-center">{{ format(mes.cor, true) }}</td>
                <td class="text-center">{{ format(mes.ph, true) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-3 text-start">
          <div class="lead fw-bold mt-2">Indicadores referente à Água</div>
          <table class="table w-100 fs-7 mt-1">
            <thead>
            <tr>
              <th>Nome do Indicador</th>
              <th class="d-none d-md-table-cell">Definição</th>
              <th>Unidade</th>
              <th class="d-none d-md-table-cell">Finalidade</th>
              <th>Resultado</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="indic in agua" :key="indic.nome">
              <td>{{ indic.descricao }}</td>
              <td class="d-none d-md-table-cell">
                <span>{{indic.definicao}}</span>
              </td>
              <td>{{ indic.unidade }}</td>
              <td class="d-none d-md-table-cell">{{ indic.finalidade }}</td>
              <td>{{ indicador(relatorio.agua[indic.nome]) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mt-4 mb-1">
          <span class="h5 fw-bold">Classificação do IQA</span>
        </div>
        <div class="d-flex justify-content-center row">
          <div>
            <div class="classificacao row no-wrap border m-0 bg-primary text-center" :class="{'selected': relatorio.agua.indic_iqa > 97}">
              <div class="col-1">(<span v-if="relatorio.agua.indic_iqa > 97">X</span>)</div>
              <div class="col-6">Excelente</div>
              <div class="col-5">IQA > 97</div>
            </div>
            <div class="classificacao row no-wrap border m-0 bg-success" :class="{'selected': relatorio.agua.indic_iqa > 90 && relatorio.agua.indic_iqa <= 97}">
              <div class="col-1">(<span v-if="relatorio.agua.indic_iqa > 90 && relatorio.agua.indic_iqa <= 97">X</span>)</div>
              <div class="col-6">Boa</div>
              <div class="col-5">90 &lt IQA &le; 97</div>
            </div>
            <div class="classificacao row no-wrap border m-0 bg-warning" :class="{'selected': relatorio.agua.indic_iqa > 80 && relatorio.agua.indic_iqa <= 90}">
              <div class="col-1">(<span v-if="relatorio.agua.indic_iqa > 80 && relatorio.agua.indic_iqa <= 90">X</span>)</div>
              <div class="col-6">Aceitável</div>
              <div class="col-5">80 &lt IQA &le; 90</div>
            </div>
            <div class="classificacao row no-wrap border m-0 bg-danger" :class="{'selected': relatorio.agua.indic_iqa > 55 && relatorio.agua.indic_iqa <= 80}">
              <div class="col-1">(<span v-if="relatorio.agua.indic_iqa > 55 && relatorio.agua.indic_iqa <= 80">X</span>)</div>
              <div class="col-6">Aceitável com restrições</div>
              <div class="col-5">55 &lt IQA &le; 80</div>
            </div>
            <div class="classificacao row no-wrap border m-0 bg-purple" :class="{'selected': relatorio.agua.indic_iqa <= 55 && relatorio.agua.indic_iqa !== null}">
              <div class="col-1">(<span v-if="relatorio.agua.indic_iqa <= 55 && relatorio.agua.indic_iqa !== null">X</span>)</div>
              <div class="col-6">Péssima</div>
              <div class="col-5">IQA &le; 55</div>
            </div>
          </div>
        </div>
        <!--      FIM DADOS ÁGUA    -->
      </div>
      <div class="page-break"></div>

      <!--    DADOS ESGOTO    -->
      <div class="mx-5">
          <div class="mt-3 text-start row">
            <div class="lead fw-bold">Dados referentes à esgoto:</div>
            <div class="col-12 border border-bottom"></div>
            <div class="col-9">
              <div>Total de domicílios com atendimento da rede de esgoto</div>
              <div>Total de domicílios urbanos com atendimento com rede de esgoto</div>
              <div>Total de domicílios na área de atendimento</div>
              <div>Domicílios rurais dentro da área de atendimento</div>
              <div>Domicílios urbanos fora da área de atendimento</div>
              <div>Parcela da população da amostra satisfeita</div>
              <div>Número de reclamações referente ao esgoto</div>
              <div>Numero total de economias (ligações de esgoto)</div>
              <div>Volume de esgoto tratado (L/s)</div>
              <div>Capacidade da ETE</div>
              <div>Volume faturado de esgoto no ano</div>
              <div>Despesa operacional dos últimos 12 meses</div>
              <div>Receita operacional dos últimos 12 meses</div>
              <div>Despesa com pessoal próprio</div>
              <div>Economias ativas à ETE </div>
              <div>Número total de empregados</div>
              <div>Volume produzido de esgoto (L/hab/dia)</div>
              <div>Investimentos realizados no ano</div>
              <div>Serviços de divida no ano (financiamentos) (valor pago do financiamento no ano)</div>
              <div>Tarifa média praticada</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.esgoto.total_domicilios_com_atendimento) }}</div>
              <div>{{ format(relatorio.esgoto.total_domicilios_urbanos_com_atendimento) }}</div>
              <br/>
              <div>{{ format(relatorio.esgoto.total_domicilios_area_atendimento) }}</div>
              <div>{{ format(relatorio.esgoto.domicilios_rurais_dentro_da_area) }}</div>
              <div>{{ format(relatorio.esgoto.domicilios_urbanos_fora_da_area) }}</div>
              <div>{{ format(relatorio.esgoto.parcela_populacao_satisfeita) }}</div>
              <div>{{ format(relatorio.esgoto.numero_reclamacoes) }}</div>
              <div>{{ format(relatorio.esgoto.numero_economias, true) }}</div>
              <div>{{ format(relatorio.esgoto.volume_tratado, true) }}</div>
              <div>{{ format(relatorio.esgoto.capacicade_ete, true) }}</div>
              <div>{{ format(relatorio.esgoto.volume_faturado, true, true) }}</div>
              <div>{{ format(relatorio.esgoto.despesa_operacional, true, true) }}</div>
              <div>{{ format(relatorio.esgoto.receita_operacional, true, true) }}</div>
              <div>{{ format(relatorio.esgoto.despesa_pessoal_proprio, true, true) }}</div>
              <div>{{ format(relatorio.esgoto.economias_ete) }}</div>
              <div>{{ format(relatorio.esgoto.numero_empregados) }}</div>
              <div>{{ format(relatorio.esgoto.volume_produzido, true) }}</div>
              <div>{{ format(relatorio.esgoto.investimentos_realizados, true, true) }}</div>
              <div>{{ format(relatorio.esgoto.servico_divida, true, true) }}</div>
              <br/>
              <div>{{ format(relatorio.esgoto.tarifa_media, true, true) }}</div>
            </div>
          </div>

          <div class="text-start">
            <div v-for="local in relatorio.esgoto.localidades" class="row mt-3">
              <div class="fw-bold"> Análises localidade {{ local.descricao }}</div>

              <div class="fw-bold ps-4 col-9">Coordenadas geográficas da coleta da análise</div>
              <div class="col-3"></div>

              <div class="col-9 ps-5">
                <div>Latitude</div>
                <div>Longitude</div>
                <div>Altitude</div>
              </div>
              <div class="col-3">
                <div>{{ local.latitude_coleta }}</div>
                <div>{{ local.longitude_coleta }}</div>
                <div>{{ local.altitude }}</div>
              </div>

              <div class="fw-bold ps-4">Análises</div>
              <div class="col-9 ps-5">
                <div>Análise de turbidez (NTU)</div>
                <div>Análise de pH livre residual</div>
                <div>Análise de N (Nitrogênio) (mgN/L)</div>
                <div>Análise de P (Fósforo) (mgP/L)</div>
                <div>Análise de temperatura (ºC)</div>
                <div>Análise de Residuos Totais (mg/L)</div>
                <div>Análise de Coliformes Fecais (NMP/100ml)</div>
                <div>Análise de Oxigênio Dissolvido (mg/L)</div>
                <div>Análise de Demanda Bioquímica de Oxigênio (mg/L)</div>
              </div>
              <div class="col-3">
                <div>{{ format(local.turbidez, true) }}</div>
                <div>{{ format(local.ph, true) }}</div>
                <div>{{ format(local.nitrogenio, true) }}</div>
                <div>{{ format(local.fosforo, true) }}</div>
                <div>{{ format(local.temperatura, true) }}</div>
                <div>{{ format(local.residuos, true) }}</div>
                <div>{{ format(local.coliformes, true) }}</div>
                <div>{{ format(local.oxigenio, true) }}</div>
                <div>{{ format(local.demanda_bioquimica, true) }}</div>
              </div>
            </div>
          </div>

          <div class="mt-3 text-start">
            <div class="lead fw-bold mt-2">Indicadores referente à Esgoto</div>
            <table class="table w-100 fs-7 mt-1">
              <thead>
              <tr>
                <th>Nome do Indicador</th>
                <th class="d-none d-md-table-cell">Definição</th>
                <th>Unidade</th>
                <th class="d-none d-md-table-cell">Finalidade</th>
                <th>Resultado</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="indic in esgoto" :key="indic.nome">
                <td>{{ indic.descricao }}</td>
                <td class="d-none d-md-table-cell">
                  <span>{{indic.definicao}}</span>
                </td>
                <td>{{ indic.unidade }}</td>
                <td class="d-none d-md-table-cell">{{ indic.finalidade }}</td>
                <td>{{ indicador(relatorio.esgoto[indic.nome]) }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="text-center mt-4">
            <span class="h5 fw-bold">Classificação do IQA</span>
            <br/>
            <span class="h6 muted">Localidades</span>
          </div>
          <div class="d-flex justify-content-center row">
            <div v-for="loc in relatorio.esgoto.localidades" class="mt-3">
              <span class="h6"><b>{{loc.descricao}} <span v-if="!isNaN(loc.indic_iqa)">({{indicador(loc.indic_iqa)}})</span></b></span>
              <div>
                <div class="classificacao row bg-primary no-wrap border m-0" :class="{'selected': loc.indic_iqa > 79}">
                  <div class="col-1">(<span v-if="loc.indic_iqa > 79">X</span>)</div>
                  <div class="col-6">Ótima</div>
                  <div class="col-5">IQA > 79</div>
                </div>
                <div class="classificacao row bg-success no-wrap border m-0" :class="{'selected': loc.indic_iqa > 51 && loc.indic_iqa <= 79}">
                  <div class="col-1">(<span v-if="loc.indic_iqa > 51 && loc.indic_iqa <= 79">X</span>)</div>
                  <div class="col-6">Boa</div>
                  <div class="col-5">51 &lt IQA &le; 79</div>
                </div>
                <div class="classificacao row bg-warning no-wrap border m-0" :class="{'selected': loc.indic_iqa > 36 && loc.indic_iqa <= 52}">
                  <div class="col-1">(<span v-if="loc.indic_iqa > 36 && loc.indic_iqa <= 52">X</span>)</div>
                  <div class="col-6">Regular</div>
                  <div class="col-5">36 &lt IQA &le; 51</div>
                </div>
                <div class="classificacao row bg-danger no-wrap border m-0" :class="{'selected': loc.indic_iqa > 19 && loc.indic_iqa <= 36}">
                  <div class="col-1">(<span v-if="loc.indic_iqa > 19 && loc.indic_iqa <= 36">X</span>)</div>
                  <div class="col-6">Ruim</div>
                  <div class="col-5">19 &lt IQA &le; 36</div>
                </div>
                <div class="classificacao row bg-purple no-wrap border m-0" :class="{'selected': loc.indic_iqa <= 19 && loc.indic_iqa !== null}">
                  <div class="col-1">(<span v-if="loc.indic_iqa <= 19 && loc.indic_iqa !== null">X</span>)</div>
                  <div class="col-6">Péssima</div>
                  <div class="col-5">IQA &le; 19</div>
                </div>
              </div>
              <div><img :src="loc.file_evidencia_url" height="90" /></div>
            </div>
          </div>
      </div>
      <div class="page-break"></div>

      <!--    DADOS RESIDUOS    -->
      <div class="mx-5">
          <div class="mt-3 text-start row">
            <div class="lead fw-bold">Dados referentes à resíduos:</div>
            <div class="col-12 border border-bottom"></div>

            <div class="mt-3 fw-bold">Informações gerais</div>
            <div class="ps-4 col-9">
              <div>Natureza jurídica do órgão municipal responsável</div>
              <div>Existência de algum serviço concedido</div>
              <div>Órgão também presta serviço de água/esgoto?</div>
              <div>Extensão de vias públicas</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.natureza_juridica }}</div>
              <div>{{ relatorio.residuos.servico_concedido ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.presta_servico ? "Sim" : "Não" }}</div>
              <div>{{ format(relatorio.residuos.extensao_vias_publicas, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Cobrança dos serviços</div>
            <div class="ps-5 col-9">
              <div>Regular?</div>
              <div>Forma regular</div>
              <div>Especiais</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.cobranca_regular ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.forma_regular }}</div>
              <div>{{ relatorio.residuos.especiais ? "Sim" : "Não" }}</div>
            </div>

            <div class="mt-3 fw-bold">Coleta Regular</div>
            <div class="ps-4 col-9">
              <div>Total de domicílios urbanos atendidos por coleta de lixo</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.domicilios_atendidos) }}</div>
            </div>

            <div class="mt-3 fw-bold">Receitas e despesas com serviços de limpeza urbana</div>
            <div class="ps-4 col-9">
              <div>Despesa Corrente da Prefeitura</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.despesa_corrente, true, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Receitas</div>
            <div class="ps-5 col-9">
              <div>Orçada (R$/ano)</div>
              <div>Arrecadada (R$/ano)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.receita_orcada, true, true) }}</div>
              <div>{{ format(relatorio.residuos.receita_arrecadada, true, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Despesas, segundo o agente executor</div>
            <div class="ps-5 col-9">
              <div>Total (R$/ano)</div>
              <div>Público (R$/ano)</div>
              <div>Privado (R$/ano)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.despesa_total, true, true) }}</div>
              <div>{{ format(relatorio.residuos.despesa_publico, true, true) }}</div>
              <div>{{ format(relatorio.residuos.despesa_privado, true, true) }}</div>
            </div>

            <div class="mt-3 fw-bold">Recursos federais recebidos para manejo de resíduos sólidos</div>
            <div class="ps-4 col-9">
              <div>Ocorrência</div>
              <div>Valor (R$/ano)</div>
              <div>Tipo</div>
              <div>Aplicação</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.recursos_federais_ocorrencia ? "Sim" : "Não" }}</div>
              <div>{{ format(relatorio.residuos.recursos_federais_valor, true, true) }}</div>
              <div>{{ relatorio.residuos.recursos_federais_tipo }}</div>
              <div>{{ relatorio.residuos.recursos_federais_aplicacao }}</div>
            </div>

            <div class="mt-3 fw-bold">Qtd. tot. trab. remun. de todo o manejo RSU, segundo o agente executor</div>
            <div class="ps-4 col-9">
              <div>Total empregado</div>
              <div>Público empregado</div>
              <div>Privado empregado</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.total_empregado) }}</div>
              <div>{{ format(relatorio.residuos.publico_empregado) }}</div>
              <div>{{ format(relatorio.residuos.privado_empregado) }}</div>
            </div>

            <div class="mt-3 fw-bold">Trabalhadores de frentes de trabalho temporárias</div>
            <template v-for="frente in relatorio.residuos.frentes">
              <div class="ps-4 fw-bold">Frente {{frente.descricao}}</div>
              <div class="ps-5 col-9">
                <div>Quantidade Empregados</div>
                <div>Duração (meses)</div>
                <div>Serviços predominantes</div>
              </div>
              <div class="col-3">
                <div>{{ format(frente.qtd_empregados) }}</div>
                <div>{{ format(frente.duracao)}}</div>
                <div>{{ frente.servicos_predominantes }}</div>
              </div>
            </template>

            <div class="mt-3 fw-bold">Despesas com manejo de resíduos sólidos segundo tipo de serviço realizado</div>
            <div class="ps-4 fw-bold">Coleta de Resíduos Solidos domiciliares e públicos</div>
            <div class="ps-5 col-9">
              <div>Total (R$/ano)</div>
              <div>Público (R$/ano)</div>
              <div>Privado (R$/ano)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.residuos_solidos_domiciliares_total, true, true) }}</div>
              <div>{{ format(relatorio.residuos.residuos_solidos_domiciliares_publico, true, true) }}</div>
              <div>{{ format(relatorio.residuos.residuos_solidos_domiciliares_privado, true, true) }}</div>
            </div>

            <div class="ps-4 fw-bold">Coleta de Resíduos Solidos serviços de saúde</div>
            <div class="ps-5 col-9">
              <div>Total (R$/ano)</div>
              <div>Público (R$/ano)</div>
              <div>Privado (R$/ano)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.residuos_solidos_saude_total, true, true) }}</div>
              <div>{{ format(relatorio.residuos.residuos_solidos_saude_publico, true, true) }}</div>
              <div>{{ format(relatorio.residuos.residuos_solidos_saude_privado, true, true) }}</div>
            </div>

            <div class="ps-4 fw-bold">Varrição de logradouros públicos</div>
            <div class="ps-5 col-9">
              <div>Total (R$/ano)</div>
              <div>Público (R$/ano)</div>
              <div>Privado (R$/ano)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.varricao_logradouros_total, true, true) }}</div>
              <div>{{ format(relatorio.residuos.varricao_logradouros_publico, true, true) }}</div>
              <div>{{ format(relatorio.residuos.varricao_logradouros_privado, true, true) }}</div>
            </div>

            <div class="ps-4 fw-bold">Demais serviços, inclusive admin. e com unid. de proc.</div>
            <div class="ps-5 col-9">
              <div>Total (R$/ano)</div>
              <div>Público (R$/ano)</div>
              <div>Privado (R$/ano)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.demais_servicos_total, true, true) }}</div>
              <div>{{ format(relatorio.residuos.demais_servicos_publico, true, true) }}</div>
              <div>{{ format(relatorio.residuos.demais_servicos_privado, true, true) }}</div>
            </div>

            <div class="mt-3 fw-bold">Quantidades de trabalhadores remunerados alocados no manejo de resíduos sólidos, segundo o tipo de serviço realizado</div>
            <div class="ps-4 fw-bold">Total (empregado)</div>
            <div class="ps-5 col-9">
              <div>Público (empregado)</div>
              <div>Privado (empregado)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_total_publico) }}</div>
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_total_privado) }}</div>
            </div>
            <div class="ps-4 fw-bold">Coleta</div>
            <div class="ps-5 col-9">
              <div>Público (empregado)</div>
              <div>Privado (empregado)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_coleta_publico) }}</div>
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_coleta_privado) }}</div>
            </div>
            <div class="ps-4 fw-bold">Varrição</div>
            <div class="ps-5 col-9">
              <div>Público (empregado)</div>
              <div>Privado (empregado)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_varricao_publico) }}</div>
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_varricao_privado) }}</div>
            </div>
            <div class="ps-4 fw-bold">Capina e Roçada</div>
            <div class="ps-5 col-9">
              <div>Público (empregado)</div>
              <div>Privado (empregado)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_capina_publico) }}</div>
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_capina_privado) }}</div>
            </div>
            <div class="ps-4 fw-bold">Unidades de Processamento</div>
            <div class="ps-5 col-9">
              <div>Público (empregado)</div>
              <div>Privado (empregado)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_processamento_publico) }}</div>
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_processamento_privado) }}</div>
            </div>
            <div class="ps-4 fw-bold">Outros Serviços</div>
            <div class="ps-5 col-9">
              <div>Público (empregado)</div>
              <div>Privado (empregado)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_outros_servicos_publico) }}</div>
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_outros_servicos_privado) }}</div>
            </div>
            <div class="ps-4 fw-bold">Gerenciais e Administrativos</div>
            <div class="ps-5 col-9">
              <div>Público (empregado)</div>
              <div>Privado (empregado)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_gerenciais_adm_publico) }}</div>
              <div>{{ format(relatorio.residuos.qtd_trabalhadores_gerenciais_adm_privado) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre população atendida, estrutura operacional, frequência e terceirização do serviço de coleta domiciliar e pública</div>
            <div class="ps-4 col-9">
              <div>Coleta Noturna (existe ou não)</div>
              <div>Coleta com elevação de contêiner</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_noturna ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_elevacao_conteiner ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">População atentida declarada</div>
            <div class="ps-5 col-9">
              <div>Urbana do Município (hab.)</div>
              <div>Rural do Município (hab.)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.populacao_atendida_urbana) }}</div>
              <div>{{ format(relatorio.residuos.populacao_atendida_rural) }}</div>
            </div>
            <div class="ps-4 fw-bold">População atendida, segundo a frequência</div>
            <div class="ps-5 col-9">
              <div>Diária</div>
              <div>Duas ou três vezes na semana</div>
              <div>Uma vez por semana</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.populacao_atendida_diaria) }}</div>
              <div>{{ format(relatorio.residuos.populacao_atendida_duas_tres_vezes_semana) }}</div>
              <div>{{ format(relatorio.residuos.populacao_atendida_uma_vez_semana) }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade de coletadores e mot.</div>
            <div class="ps-5 col-9">
              <div>Prefeitura (empregados)</div>
              <div>Empresas (empregados)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_coletadores_prefeitura) }}</div>
              <div>{{ format(relatorio.residuos.qtd_coletadores_empresas) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre quantidades de resíduos sólidos domiciliares e públicos coletados, segundo o tipo de resíduo (Ocorrência de coleta de RPU junto com RDO)</div>
            <div class="ps-4 fw-bold">Quantidade total de resíduos coletados</div>
          </div>
          <div class="page-break"></div>
          <div class="mt-3 text-start row">
            <div class="ps-5 col-9">
              <div>Total</div>
              <div>Prefeitura</div>
              <div>Empresas</div>
              <div>Assoc. Catadores</div>
              <div>Outro Executor</div>
              <div>Quantidade reaproveitada</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_residuos_coletados_total) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_coletados_prefeitura) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_coletados_empresas) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_coletados_catadores) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_coletados_outro) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_coletados_reaproveitados) }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade de resíduos domiciliares coletados</div>
            <div class="ps-5 col-9">
              <div>Total</div>
              <div>Prefeitura</div>
              <div>Empresas</div>
              <div>Assoc. Catadores</div>
              <div>Outro Executor</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_residuos_domiciliares_coletados_total) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_domiciliares_coletados_prefeitura) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_domiciliares_coletados_empresas) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_domiciliares_coletados_catadores) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_domiciliares_coletados_outro) }}</div>
            </div>

            <div class="ps-4 fw-bold">Quantidade de resíduos públicos coletados</div>
            <div class="ps-5 col-9">
              <div>Total</div>
              <div>Prefeitura</div>
              <div>Empresas</div>
              <div>Assoc. Catadores</div>
              <div>Outro Executor</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_residuos_publicos_coletados_total) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_publicos_coletados_prefeitura) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_publicos_coletados_empresas) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_publicos_coletados_catadores) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_publicos_coletados_outro) }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade de resíduos privados coletados</div>
            <div class="ps-5 col-9">
              <div>Total</div>
              <div>Prefeitura</div>
              <div>Empresas</div>
              <div>Assoc. Catadores</div>
              <div>Outro Executor</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.qtd_residuos_privados_coletados_total) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_privados_coletados_prefeitura) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_privados_coletados_empresas) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_privados_coletados_catadores) }}</div>
              <div>{{ format(relatorio.residuos.qtd_residuos_privados_coletados_outro) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações diversas sobre coleta de resíduos sólidos</div>
            <div class="ps-4 fw-bold">Coleta de resíduos inertes</div>
            <div class="ps-5 col-9">
              <div>Quantidade de resíduos sólidos inertes gerados</div>
              <div>Quantidade de resíduos sólidos inertes reaproveitados</div>
              <div>Uso de balança (sim ou não)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.residuos_solidos_inertes_gerados) }}</div>
              <div>{{ format(relatorio.residuos.residuos_solidos_inertes_reaproveitados) }}</div>
              <div>{{ relatorio.residuos.uso_balanca ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Serviço terceirizado de coleta de RDO+RPU</div>
            <div class="ps-5 col-9">
              <div>Valor contratual (R$/t)</div>
              <div>Incluído transporte até a unid. Transbordo destino final</div>
              <div>Distância média até a unidade (km)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.servico_rdo_rpu_valor, true, true) }}</div>
              <div>{{ relatorio.residuos.servico_rdo_rpu_incluido_transporte ? "Sim" : "Não" }}</div>
              <div>{{ format(relatorio.residuos.servico_rdo_rpu_distancia, true) }}</div>
            </div>
          </div>
          <div class="page-break"></div>
          <div class="mt-3 text-start row">
            <div class="ps-4 fw-bold">Serviço terceirizado de transporte de unidade transbordo à destino final</div>
            <div class="ps-5 col-9">
              <div>Valor contratual (R$/t)</div>
              <div>Distância média até a unidade (km)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.servico_transporte_valor, true, true) }}</div>
              <div>{{ format(relatorio.residuos.servico_transporte_distancia, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Serviço terceirizado de disposição final em aterro</div>
            <div class="ps-5 col-9">
              <div>Ocorrência de operação do aterro por execução privado (exist.)</div>
              <div>Valor contratual (R$/t)</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.servico_aterro_ocorrencia ? "Sim" : "Não"}}</div>
              <div>{{ format(relatorio.residuos.servico_aterro_valor, true, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Remessa de resíduos domiciliares ou públicos p/ outro município</div>
            <div class="ps-5 col-9">
              <div>Ocorrência</div>
              <div>Município(s) de destino</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.remessa_outro_municipio_ocorrencia) }}</div>
              <div>{{ format(relatorio.residuos.remessa_outro_municipio_destinos) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre destinação final de resíduos sólidos domiciliares</div>
            <div class="ps-4 fw-bold">Enquadramento das intalações</div>
            <div class="ps-5 col-9">
              <div>Índice de qualidade das instalações</div>
              <div>Previsão de vida útil do aterro (anos)</div>
              <div>Vida útil restante do aterro (anos)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.indice_qualidade, true) }}</div>
              <div>{{ format(relatorio.residuos.previsao_vida_aterro) }}</div>
              <div>{{ format(relatorio.residuos.vida_restante_aterro) }}</div>
            </div>
            <div class="mt-3 fw-bold">Informações sobre veículos de agentes públicos - na coleta de resíduos sólidos domiciliares e públicos</div>
            <div class="ps-4 fw-bold">Quantidade de veículos de agentes públicos</div>
            <div class="ps-5 col-9">
              <div>Caminhão compactador</div>
              <div>Cam. bascul. carroceria ou baú</div>
              <div>Caminhões poliguindaste</div>
              <div>Trator agrícola com reboque</div>
              <div>Tração animal</div>
              <div>Embarcações</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.veiculos_publicos_caminhao_compactador) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_publicos_caminhao_basculante) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_publicos_caminhao_poliguindaste) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_publicos_trator_agricola) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_publicos_trator_animal) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_publicos_embarcacoes) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre veículos de agentes privados - na coleta de resíduos sólidos domiciliares e públicos</div>
            <div class="ps-4 fw-bold">Quantidade de veículos de agentes privados</div>
            <div class="ps-5 col-9">
              <div>Caminhão compactador</div>
              <div>Cam. bascul. carroceria ou baú</div>
              <div>Caminhões poliguindaste</div>
              <div>Trator agrícola com reboque</div>
              <div>Tração animal</div>
              <div>Embarcações</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.veiculos_privados_caminhao_compactador) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_privados_caminhao_basculante) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_privados_caminhao_poliguindaste) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_privados_trator_agricola) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_privados_trator_animal) }}</div>
              <div>{{ format(relatorio.residuos.veiculos_privados_embarcacoes) }}</div>
            </div>

            <div class="mt-4 fw-bold">Informações sobre coleta seletiva de resíduos sólidos</div>
            <div class="ps-4 col-9">
              <div>Existência de coleta seletiva</div>
              <div>Domicilios atendidos</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_seletiva ? "Sim" : "Não" }}</div>
              <div>{{ format(relatorio.residuos.coleta_seletiva_domicilios_atendidos) }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade recolhida (exceto matéria orgânica)</div>
            <div class="ps-5 col-9">
              <div>Total</div>
              <div>Prefeitura ou SLU</div>
              <div>Empresas contradas</div>
              <div>Catadores c/apoio Prefe</div>
              <div>Outros</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.coleta_seletiva_total, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_seletiva_pref_ou_slu, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_seletiva_empresas, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_seletiva_catadores, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_seletiva_outros, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Forma de execução</div>
            <div class="ps-5 col-9">
              <div>Porta a porta em dias específicos</div>
              <div>Postos de entrega voluntária</div>
              <div>Outra forma</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_seletiva_porta_a_porta }}</div>
              <div>{{ relatorio.residuos.coleta_seletiva_postos_entrega }}</div>
              <div>{{ relatorio.residuos.coleta_seletiva_outra_forma }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre triagem de resíduos sólidos - (provenientes ou não da coleta seletiva)</div>
            <div class="ps-4 fw-bold">Materiais rescuperados, exceto material orgânico e rejeito</div>
            <div class="ps-5 col-9">
              <div>Total (t)</div>
              <div>Papel e papelão</div>
              <div>Plástico</div>
              <div>Metais</div>
              <div>Vidros</div>
              <div>Outros</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.material_recuperado_total, true) }}</div>
              <div>{{ format(relatorio.residuos.material_recuperado_papel, true) }}</div>
              <div>{{ format(relatorio.residuos.material_recuperado_plastico, true) }}</div>
              <div>{{ format(relatorio.residuos.material_recuperado_metais, true) }}</div>
              <div>{{ format(relatorio.residuos.material_recuperado_vidros, true) }}</div>
              <div>{{ format(relatorio.residuos.material_recuperado_outros, true) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre coleta de resíduos sólidos de serviços de saúde</div>
            <div class="ps-4 col-9">
              <div>Ocorr. De cobrança pela coleta diferenciada</div>
              <div>Prefeitura controla executores</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_saude_ocorrencia ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_saude_prefeitura_controla ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Execução de coleta dif. de RSS</div>
            <div class="ps-5 col-9">
              <div>Existe</div>
              <div>Prefeitura ou SLU</div>
              <div>Empresa contratada</div>
              <div>Próprio Gerador ou contrat</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_saude_rss_existe ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_saude_rss_prefeitura ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_saude_rss_empresa ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_saude_rss_gerador ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Veículos utilizados</div>
            <div class="ps-5 col-9">
              <div>Exclusivo</div>
              <div>Da coleta domiciliar em viagem exclusiva</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_saude_veiculos_exclusivo ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_saude_veiculos_viagem ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade de RSS coletados</div>
            <div class="ps-5 col-9">
              <div>Total</div>
              <div>Prefeitura ou contratados</div>
              <div>Geradores ou contratados</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.coleta_saude_qtd_rss_total, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_saude_qtd_rss_prefeitura, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_saude_qtd_rss_geradores, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Coleta de RSS em unidades públicas de saúde terceirizada</div>
            <div class="ps-5 col-9">
              <div>Ocorrência</div>
              <div>Valor Contratual</div>
              <div>Inclui tratamento RSS</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_saude_rss_saude_ocorrencia ? "Sim" : "Não" }}</div>
              <div>{{ format(relatorio.residuos.coleta_saude_rss_saude_valor, true, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_saude_rss_saude_tratamento) }}</div>
            </div>
            <div class="ps-4 fw-bold">Trat. de RSS terceirizado</div>
            <div class="ps-5 col-9">
              <div>Valor Contratual</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.coleta_saude_rss_terceirizado_valor, true, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Remessa de RSS para outros municípios</div>
            <div class="ps-5 col-9">
              <div>Ocorrência</div>
              <div>Município(s)</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_saude_remessa_municipio_ocorrencia ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_saude_remessa_municipios }}</div>
            </div>
            <div class="ps-4 fw-bold">Manejo RSS de serviços de saúde</div>
            <div class="ps-5 col-9">
              <div>Índice de Qualidade</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.coleta_saude_manejo_indice) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre coleta de resíduos sólidos da construção civil</div>
            <div class="ps-4 col-9">
              <div>Existência de empresa especilaizada</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_construcao_existe ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Serviço executado pela prefeitura</div>
            <div class="ps-5 col-9">
              <div>Existência</div>
              <div>Cobrança</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_construcao_prefeitura_existe ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_construcao_prefeitura_cobranca ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Existência de serviço de coleta de RCD feita por autônomos</div>
            <div class="ps-5 col-9">
              <div>Com caminhões tipo basculantes ou carroceria</div>
              <div>Com carroças ou outro tipo de veículo de pequena capacidade</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.coleta_construcao_autonomo_caminhao ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.coleta_construcao_autonomo_carroca ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade coletada</div>
            <div class="ps-5 col-9">
              <div>Prefeitura ou contratado por ela</div>
              <div>Caçambeiros e autônomos contratados pelo gerador</div>
              <div>Próprio gerador</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.coleta_construcao_qtd_prefeitura, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_construcao_qtd_autonomos, true) }}</div>
              <div>{{ format(relatorio.residuos.coleta_construcao_qtd_gerador, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade reaproveitada</div>
            <div class="ps-5 col-9">
              <div>Total</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.coleta_construcao_qtd_reaproveitada, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Destinação final</div>
            <div class="ps-5 col-9">
              <div>Índice de Qualidade</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.coleta_construcao_destinacao_indice) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre serviço de varrição</div>
            <div class="ps-4 fw-bold">Extensão de sarjeta varrida</div>
            <div class="ps-5 col-9">
              <div>Público (km)</div>
              <div>Privado (km)</div>
              <div>Total (km)</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.varricao_extensao_publico, true) }}</div>
              <div>{{ format(relatorio.residuos.varricao_extensao_privado, true) }}</div>
              <div>{{ format(relatorio.residuos.varricao_extensao_total, true) }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade de varredores</div>
            <div class="ps-5 col-9">
              <div>Público</div>
              <div>Privado</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.varricao_qtd_publico) }}</div>
              <div>{{ format(relatorio.residuos.varricao_qtd_privado) }}</div>
            </div>
            <div class="ps-4 fw-bold">Serviço terceirizado</div>
            <div class="ps-5 col-9">
              <div>Valor Contratual</div>
              <div>Ocorrência de varrição mecanizada</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.varricao_privado_valor, true, true) }}</div>
              <div>{{ relatorio.residuos.varricao_privado_varricao_mecanizada ? "Sim" : "Não" }}</div>
            </div>
            <div class="mt-3 fw-bold">Despesa</div>
            <div class="ps-4 col-9">
              <div>Despesa total</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.varricao_despesa_total, true, true) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre serviço de capina e roçada</div>
            <div class="ps-4 fw-bold">Serviço de capina e roçada</div>
            <div class="ps-5 col-9">
              <div>Existência</div>
              <div>Manual</div>
              <div>Mecanizada</div>
              <div>Quimica</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.capina_existe ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.capina_manual ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.capina_mecanizada ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.capina_quimica ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Quantidade de trabalhadores</div>
            <div class="ps-5 col-9">
              <div>Agente Executor - Público</div>
              <div>Agente Executor - Privado</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.residuos.capina_qtd_publico) }}</div>
              <div>{{ format(relatorio.residuos.capina_qtd_privado) }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre outros serviços executados pela prefeitura</div>
            <div class="ps-4 fw-bold">Outros serviços executados pela prefeitura</div>
            <div class="ps-5 col-9">
              <div>Lavação de vias e praças</div>
              <div>Poda de árvores</div>
              <div>Limpeza de feiras e mercados</div>
              <div>Limpeza de praias</div>
              <div>Limpeza de boca-de-lobo</div>
              <div>Pintura de meio fio</div>
              <div>Limpeza de lotes vagos</div>
              <div>Remoção animais mortos</div>
              <div>Coleta de pneus velhos</div>
              <div>Coleta de pilhas baterias</div>
              <div>Coleta de resíduos volumosos</div>
              <div>Coleta de Lâmpadas Fluorescentes</div>
              <div>Coleta de resíduos eletrônico</div>
              <div>Outros Serviços</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.outros_prefeitura_lavacao ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_poda ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_limpeza_feiras ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_limpeza_praias ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_limpeza_boca_lobo ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_pintura ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_limpeza_lotes ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_remocao_animais ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_coleta_pneus ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_coleta_pilhas ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_coleta_volumosos ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_coleta_lampadas ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_coleta_eletronicos ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_prefeitura_outros ? "Sim" : "Não" }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre outros serviços executados por empresas contratadas</div>
            <div class="ps-4 fw-bold">Outros serviços executados por empresas contratadas</div>
            <div class="ps-5 col-9">
              <div>Lavação de vias e praças</div>
              <div>Poda de árvores</div>
              <div>Limpeza de feiras e mercados</div>
              <div>Limpeza de praias</div>
              <div>Limpeza de boca-de-lobo</div>
              <div>Pintura de meio fio</div>
              <div>Limpeza de lotes vagos</div>
              <div>Remoção animais mortor</div>
              <div>Coleta de pneus velhos</div>
              <div>Coleta de pilhas baterias</div>
              <div>Coleta de resíduos volumosos</div>
              <div>Coleta de Lâmpadas Fluorescentes</div>
              <div>Coleta de resíduos eletrônico</div>
              <div>Outros Serviços</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.outros_empresa_lavacao ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_poda ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_limpeza_feiras ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_limpeza_praias ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_limpeza_boca_lobo ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_limpeza_lotes ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_pintura ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_remocao_animais ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_coleta_pneus ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_coleta_pilhas ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_coleta_volumosos ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_coleta_lampadas ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_coleta_eletronicos ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_empresa_outros ? "Sim" : "Não" }}</div>
            </div>
            <div class="mt-3 fw-bold">Informações sobre outros serviços executados por outros agentes</div>
            <div class="ps-4 fw-bold">Outros serviços executados por outros agentes</div>
            <div class="ps-5 col-9">
              <div>Lavação de vias e praças</div>
              <div>Poda de árvores</div>
              <div>Limpeza de feiras e mercados</div>
              <div>Limpeza de praias</div>
              <div>Limpeza de boca-de-lobo</div>
              <div>Pintura de meio fio</div>
              <div>Limpeza de lotes vagos</div>
              <div>Remoção animais mortor</div>
              <div>Coleta de pneus velhos</div>
              <div>Coleta de pilhas baterias</div>
              <div>Coleta de resíduos volumosos</div>
              <div>Coleta de Lâmpadas Fluorescentes</div>
              <div>Coleta de resíduos eletrônico</div>
              <div>Outros Serviços</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.outros_agentes_lavacao ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_poda ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_limpeza_feiras ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_limpeza_praias ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_limpeza_boca_lobo ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_limpeza_lotes ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_pintura ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_remocao_animais ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_coleta_pneus ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_coleta_pilhas ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_coleta_volumosos ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_coleta_lampadas ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_coleta_eletronicos ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.outros_agentes_outros ? "Sim" : "Não" }}</div>
            </div>

            <div class="mt-3 fw-bold">Informações sobre catadores</div>
            <div class="ps-4 col-9">
              <div>Existência de catadores disperso</div>
              <div>Existência de trabalho social executado pela Prefeitura</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.catadores_disperso ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.residuos.catadores_trabalho_social ? "Sim" : "Não" }}</div>
            </div>
            <div class="ps-4 fw-bold">Organização</div>
            <div class="ps-5 col-9">
              <div>Existência de organização formal (sim ou não)</div>
              <div>Quantidade de entidades associativas (entidades)</div>
              <div>Quantidade de associados (pessoas)</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.residuos.catadores_organizacao_formal ? "Sim" : "Não" }}</div>
              <div>{{ format(relatorio.residuos.catadores_organizacao_qtd_entidades) }}</div>
              <div>{{ format(relatorio.residuos.catadores_organizacao_qtd_associados) }}</div>
            </div>
          </div>
      </div>
      <div class="page-break"></div>

      <!--   INDICADORES RESIDUOS -->
      <div class="mx-5">
        <div class="mt-3 text-start">
            <div class="lead fw-bold mt-2">Indicadores referente à Resíduos</div>
            <table class="table w-100 fs-7 mt-1">
              <thead>
              <tr>
                <th>Nome do Indicador</th>
                <th class="d-none d-md-table-cell">Definição</th>
                <th>Unidade</th>
                <!--              <th class="d-none d-md-table-cell">Finalidade</th>-->
                <th>Resultado</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="indic in residuos" :key="indic.nome">
                <td>{{ indic.descricao }}</td>
                <td class="d-none d-md-table-cell definicao">
                  <span v-html="indic.definicao"></span>
                </td>
                <td>{{ indic.unidade }}</td>
                <!--              <td class="d-none d-md-table-cell">{{ indic.finalidade }}</td>-->
                <td>{{ indicador(relatorio.residuos[indic.nome]) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
      </div>
      <div class="page-break"></div>

      <!--      DADOS DRENAGEM    -->
      <div class="mx-5">
          <div class="mt-3 text-start row">
            <div class="lead fw-bold">Dados referentes à microdrenagem:</div>
            <div class="col-12 border border-bottom"></div>

            <div class="mt-3 fw-bold">Institucionalização</div>
            <div class="ps-4 col-9">
              <div>Existência de padronização para projeto viário e drenagem pluvial?</div>
              <div>Serviço de verificação e análise de projetos de pavimentação e/ou loteamentos?</div>
              <div>Estrutura de inspeção e manutenção da drenagem?</div>
              <div>Existência de monitoramento de chuva?</div>
              <div>Registro de incidentes envolvendo microdrenagem?</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.microdrenagem.existe_padrao ? "Sim" : "Não" }}</div>
              <br/>
              <div>{{ relatorio.microdrenagem.servico_analise ? "Sim" : "Não" }}</div>
              <br/>
              <div>{{ relatorio.microdrenagem.estrutura_inspecao ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.microdrenagem.monitoramento_chuva ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.microdrenagem.registro_incidentes ? "Sim" : "Não" }}</div>
            </div>

            <div class="mt-3 fw-bold">Cobertura</div>
            <div class="ps-4 col-9">
              <div>Extensão total de ruas com serviço de microdrenagem, em Km (Guias, sarjetas e bocas-de-lobo)</div>
              <div>Extensão total de ruas urbanas do município</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.microdrenagem.extensao_ruas, true) }}</div>
              <br/>
              <div>{{ format(relatorio.microdrenagem.extensao_ruas_urbanas, true) }}</div>
            </div>

            <div class="mt-3 fw-bold">Eficência</div>
            <div class="ps-4 col-9">
              <div>Número de dias com incidentes na microdrenagem (alagamento devia até 30 Cm, refluxo pelos PVs e Bls)</div>
              <div>Número de dias com chuva no ano</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.microdrenagem.qtd_dias_incidentes) }}</div>
              <br/>
              <div>{{ format(relatorio.microdrenagem.qtd_dias_chuva_ano) }}</div>
            </div>

            <div class="mt-3 fw-bold">Gestão</div>
            <div class="ps-4 col-9">
              <div>Número de bocas-de-lobo limpas</div>
              <div>Total de bocas-de-lobo na área urbana</div>
              <div>Total de recursos gastos com microdrenagem</div>
              <div>Total alocado no orçamento anual para microdrenagem</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.microdrenagem.qtd_bocas_lobo_limpas) }}</div>
              <div>{{ format(relatorio.microdrenagem.qtd_total_bocas_lobo) }}</div>
              <div>{{ format(relatorio.microdrenagem.total_gasto, true, true) }}</div>
              <div>{{ format(relatorio.microdrenagem.total_alocado, true, true) }}</div>
            </div>
          </div>

          <!--      INDICADORES MICRO -->
          <div class="mt-3 text-start">
            <div class="lead fw-bold mt-2">Indicadores referente à microdrenagem</div>
            <table class="va-table w-100 fs-7 bg-gray mt-1 rounded-2">
              <thead>
              <tr>
                <th>Nome do Indicador</th>
                <th>Definição</th>
                <th>Unidade</th>
                <th>Finalidade</th>
                <th>Resultado</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="indic in micro" :key="indic.nome">
                <td>{{ indic.descricao }}</td>
                <td>
                <span
                    v-if="indic.definicao.length"
                    class="text-decoration-underline"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="indic.definicao">Visualizar
                </span>
                </td>
                <td>{{ indic.unidade }}</td>
                <td>{{ indic.finalidade }}</td>
                <td>{{ indicador(relatorio.microdrenagem[indic.nome]) }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-3 text-start row">
            <div class="lead fw-bold">Dados referentes à macrodrenagem:</div>
            <div class="col-12 border border-bottom"></div>

            <div class="mt-3 fw-bold">Institucionalização</div>
            <div class="ps-4 col-9">
              <div>Existencia de plano diretor urbanistico com tópicos relativos à drenagem?</div>
              <div>Existência de plano diretor de drenagem urbana</div>
              <div>Legislação específica de uso e ocupação do solo que trata de impermeabilização, medidas mitigadoras e compensatórias?</div>
              <div>Monitoramento de cursos d'água (nível e vazão)?</div>
              <div>Registro de Incidentes envolvendo a macrodrenagem?</div>
            </div>
            <div class="col-3">
              <div>{{ relatorio.macrodrenagem.existe_plano ? "Sim" : "Não" }}</div>
              <br/>
              <div>{{ relatorio.macrodrenagem.existe_plano_urbano ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.macrodrenagem.legislacao_especifica ? "Sim" : "Não" }}</div>
              <br/>
              <br/>
              <div>{{ relatorio.macrodrenagem.monitoramento_cursos ? "Sim" : "Não" }}</div>
              <div>{{ relatorio.macrodrenagem.registro_incidentes ? "Sim" : "Não" }}</div>
            </div>

            <div class="mt-3 fw-bold">Cobertura</div>
            <div class="ps-4 col-9">
              <div>Registro de intervenção da rede hídrica do município</div>
              <div>Extensão da rede hídrica do município</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.macrodrenagem.registro_intervencao, true) }}</div>
              <div>{{ format(relatorio.macrodrenagem.extensao_rede, true) }}</div>
            </div>

            <div class="mt-3 fw-bold">Eficência</div>
            <div class="ps-4 col-9">
              <div>Número de dias com incidentes na macrodrenagem (transbordamento do córregos, derrubada de pontes, solapamento de margem, etc.)</div>
              <div>Número de dias com chuva no ano</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.macrodrenagem.qtd_dias_incidentes) }}</div>
              <br/>
              <br/>
              <div>{{ format(relatorio.macrodrenagem.qtd_dias_chuva_ano) }}</div>
            </div>

            <div class="mt-3 fw-bold">Gestão</div>
            <div class="ps-4 col-9">
              <div>Total aplicado na limpeza de córregos/estrutura de relatorio.macrodrenagem em geral</div>
              <div>Total de recursos alocados para relatorio.macrodrenagem</div>
            </div>
            <div class="col-3">
              <div>{{ format(relatorio.macrodrenagem.total_aplicado, true, true) }}</div>
              <br/>
              <div>{{ format(relatorio.macrodrenagem.total_alocado, true, true) }}</div>
            </div>

          </div>
          <!--      INDICADORES MACRO -->
          <div class="mt-3 text-start">
            <div class="lead fw-bold mt-2">Indicadores referente à macrodrenagem</div>
            <table class="va-table w-100 fs-7 bg-gray mt-1 rounded-2">
              <thead>
              <tr>
                <th>Nome do Indicador</th>
                <th>Definição</th>
                <th>Unidade</th>
                <th>Finalidade</th>
                <th>Resultado</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="indic in macro" :key="indic.nome">
                <td>{{ indic.descricao }}</td>
                <td>
                <span
                    v-if="indic.definicao.length"
                    class="text-decoration-underline"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="indic.definicao">Visualizar
                </span>
                </td>
                <td>{{ indic.unidade }}</td>
                <td>{{ indic.finalidade }}</td>
                <td>{{ indicador(relatorio.macrodrenagem[indic.nome]) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--      FIM DADOS DRENAGEM    -->

        </div>
      <div class="page-break"></div>

      <!--=========== METAS =============-->
      <div class="mx-5" v-if="relatorio.metas.length > 0">
          <div class="mt-3 text-start">
            <div class="lead fw-bold mt-2">METAS</div>
            <div class="col-12 border border-bottom"></div>
            <table v-for="tipo in tipos" class="table w-100 fs-7 bg-gray mt-1 rounded-2">
              <template v-if="relatorio.metas[tipo.key]">
                <thead>
                <tr><th colspan="3" class="text-center"><b>{{tipo.nome}}</b></th></tr>
                <tr>
                  <th>Meta</th>
                  <th>Objetivo</th>
                  <th>Resultado</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="meta in relatorio.metas[tipo.key]" :key="meta.id">
                  <td>{{ meta.descricao }}</td>
                  <td>{{ meta.objetivo }}</td>
                  <td>{{ meta.resultado }}</td>
                </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      <div class="page-break"></div>
      <div id="pdf-content-final" class="mx-5 mt-3">
        <div class="mt-3 text-start row">
          <div class="fw-bold">1 - Ações de Emergência e Contingência para abastecimento de água</div>
          <div class="mt-3 indent">
            <p>As ações de respostas a emergências visam descrever as medidas e ações que devem ser adotadas para
              enfrentamento de situações atípicas, para prevenir e reduzir os impactos quando da ocorrência de sinistros,
              acidentes e desastres naturais, conferindo maior segurança e confiabilidade operacional aos sistemas.</p>
            <p>A definição de medidas e ações em resposta a situações de emergência estão estabelecidas na tabela, onde
              estão contempladas as potenciais emergências, classificadas como situações adversas, com as medidas que
              devem ser adotadas em cada adversidade nos diferentes sistemas que integram o saneamento básico
              municipal.</p>
            <p>Recomenda-se também que os operadores dos serviços façam o registro das situações emergenciais com a
              avaliação crítica dos procedimentos sugeridos nesta tabela, para a introdução dos aperfeiçoamentos
              necessários, com o detalhamento que cada caso requer.</p>
            <p>Deverão ser cadastradas fontes alternativas de abastecimento de água para situações emergenciais, que
              atendam as exigências da Portaria do Ministério da Saúde.</p>
          </div>
          <div class="text-center">
            <div class="text-muted fs-7">Medidas de emergência e contingência</div>
            <img class="img-fluid" src="./../assets/tabela_1.png"/>
          </div>
        </div>
        <div class="page-break"></div>
        <div class="mt-3 text-start row">
          <div class="text-center">
            <div class="text-muted fs-7 mt-4">Medidas de Emergência</div>
            <img class="img-fluid w-75" src="./../assets/tabela_2.png"/>
            </div>
            <div class="mt-3 indent">
              <p>Considerando os diversos níveis dos agentes envolvidos e as suas respectivas competências e dando
                prioridade aos procedimentos cuja paralisação pode causar os maiores impactos à saúde pública e ao meio ambiente,
                apresentam-se a seguir os planos de contingência para abastecimento de água.</p>
              <div class="ms-3" style="text-align:justify">
                <li>Acompanhamento da produção de água através da realização de medições na entrada e saída da estação de
                  tratamento de água, se houver;</li>
                <li>Controle de parâmetros dos equipamentos em operação: horas trabalhadas,
                  corrente elétrica, tensão, consumo de energia, vibração e temperatura;</li>
                <li>Controle de equipamentos de reserva e em manutenção;</li>
                <li>Sistema de gerenciamento da manutenção: cadastro dos equipamentos e instalações; programação de manutenções
                  preventivas; geração e controle de ordens de serviços de manutenções preventivas e corretivas; registros e
                  históricos das manutenções; realização de manutenções em equipamentos de alta criticidade;</li>
                <li>Manutenção preventiva das bombas do sistema de produção de água em oficina especializada;</li>
                <li>Plano de inspeções periódicas e adequações das adutoras de água bruta;</li>
                <li>Acompanhamento das vazões encaminhadas aos setores de distribuição, dos níveis de reservação, da situação
                  de operação dos conjuntos moto-bomba e das vazões mínimas noturnas para gerenciamento das perdas, com registros históricos;</li>
                <li>Acompanhamento da regularidade no abastecimento por setor de distribuição;</li>
                <li>Pesquisa planejada de vazamentos não visíveis na rede de distribuição e ramais de água;</li>
                <li>Acompanhamento geral do estado da hidrometria instalada e manutenção preventiva;</li>
                <li>Controle da qualidade da água dos mananciais e das captações subterrâneas e superficiais, se houver;</li>
                <li>Manutenção de base de dados e acompanhamento de gestão de riscos ambientais através dos órgãos competentes;</li>
                <li>Controle da qualidade da água produzida com análises de diversos parâmetros nos sistemas de tratamento de água;</li>
                <li>Plano de Ação de Emergência para atuação nos casos de vazamentos de cloro na estação de tratamento de água, se houver;</li>
                <li>Plano de Ação para contenção de vazamentos de produtos químicos;</li>
                <li>Plano de Ação para atuação em casos de incêndio;</li>
                <li>Plano de limpeza e desinfecção dos reservatórios de distribuição de água;</li>
                <li>Controle da qualidade da água distribuída, realizado por laboratório especializado, conforme previsto na
                  Portaria 2.914 do Ministério da Saúde, através de coletas em diversos pontos da rede de distribuição e na saída do processo de tratamento;</li>
                <li>Plano de vistoria e acompanhamento dos sistemas de distribuição de água com equipes volantes 24 horas por dia.</li>
              </div>
            </div>
          </div>
        <div class="page-break"></div>
        <div class="mt-3 text-start row">
          <div class="fw-bold">2 - Ações de Emergência e Contingência para Esgotamento Sanitário</div>
          <div class="mt-3 indent">
            <p>As ações de respostas a emergências visam descrever as medidas e ações que devem ser adotadas para
              enfrentamento de situações atípicas, para prevenir e reduzir os impactos quando da ocorrência de sinistros,
              acidentes e desastres naturais, conferindo maior segurança e confiabilidade operacional aos sistemas.</p>
            <p>A definição de medidas e ações em resposta a situações de emergência estãoestabelecidas na tabela, onde
              estão contempladas as potenciais emergências, classificadas como situações adversas, com as medidas que
              devem ser adotadas em cada adversidade nos diferentes sistemas que integram o saneamento básico
              municipal.</p>
            <p>Recomenda-se também que os operadores dos serviços façam o registro das situações emergenciais com a
              avaliação crítica dos procedimentos sugeridos nesta tabela, para a introdução dos aperfeiçoamentos
              necessários, com o detalhamento que cada caso requer.</p>
          </div>
          <div class="text-center">
            <div class="text-muted fs-7">Medidas de emergência e contingência</div>
            <img class="img-fluid mb-3" src="./../assets/tabela_3.png"/>
          </div>
        </div>
        <div class="page-break"></div>
        <div class="mt-3 text-start row">
          <div class="text-center page-break">
            <div class="text-muted fs-7 mt-4">Medidas de Emergência</div>
            <img class="img-fluid w-75" src="./../assets/tabela_4.png"/>
          </div>
          <div class="mt-3 indent">
            <p>São apresentadas as seguintes ações preventivas para o sistema de esgotamento sanitário, o qual deve ser
              complementado conforme as intervenções propostas no presente Plano. </p>
            <div class="ms-3" style="text-align:justify">
              <li>Acompanhamento da vazão de esgotos tratados, se houver ou quando houver tratamento;</li>
              <li>Controle de parâmetros dos equipamentos em operação, como horas trabalhadas, corrente, tensão e consumo
                de energia;
              </li>
              <li>Controle de equipamentos de reserva e em manutenção;</li>
              <li>Sistema de gerenciamento da manutenção: cadastro dos equipamentos e instalações; programação de
                manutenções preventivas; geração e controle de ordens de serviços de manutenções preventivas e corretivas;
                registros e históricos das manutenções;
              </li>
              <li>Acompanhamento das variáveis de processo da estação de tratamento de esgotos, com registros
                históricos;
              </li>
              <li>Inspeção periódica no sistema de tratamento de esgotos;</li>
              <li>Manutenção preventiva das bombas do sistema de esgotos em oficina especializada;</li>
              <li>Manutenção com limpeza preventiva programada das estações elevatórias de esgoto, se houver;</li>
              <li>Manutenção preventiva e corretiva de coletores e ramais de esgoto com equipamentos apropriados;</li>
              <li>Acompanhamento sistemático das estações elevatórias de esgoto;</li>
              <li>Controle da qualidade dos efluentes: controle periódico da qualidade dos esgotos tratados na estação de
                tratamento de esgoto, realizado por laboratório específico e de acordo com a legislação vigente;
              </li>
              <li>Plano de ação para contenção de vazamentos de produtos químicos;</li>
              <li>Plano de vistoria e acompanhamento do sistema de esgotamento sanitário existente com equipes volantes 24
                horas por dia.
              </li>
            </div>
          </div>
        </div>

        <div class="mt-3 text-start row">
          <div class="fw-bold">3 - Ações de Emergência e Contingência de drenagem Urbana</div>
          <div class="mt-3 indent">
            <p>As ações de respostas a emergências visam descrever as medidas e ações que devem ser adotadas para
              enfrentamento de situações atípicas, para prevenir e reduzir os impactos quando da ocorrência de sinistros,
              acidentes e desastres naturais, conferindo maior segurança e confiabilidade operacional aos sistemas.</p>
            <p>A definição de medidas e ações em resposta a situações de emergência estãoestabelecidas na tabela, onde
              estão contempladas as potenciais emergências, classificadas como situações adversas, com as medidas que
              devem ser adotadas em cada adversidade nos diferentes sistemas que integram o saneamento básico
              municipal.</p>
            <p>Recomenda-se também que os operadores dos serviços façam o registro das situações emergenciais com a
              avaliação crítica dos procedimentos sugeridos nesta tabela, para a introdução dos aperfeiçoamentos
              necessários, com o detalhamento que cada caso requer.</p>
          </div>
        </div>
        <div class="page-break"></div>
        <div class="mt-3 text-start row">
          <div class="text-center">
            <div class="text-muted fs-7">Plano de Contingência e Emergência</div>
            <img class="img-fluid" src="./../assets/tabela_5.png"/>
            <div class="text-muted fs-7 mt-4">Medidas de Emergência</div>
            <img class="img-fluid w-75" src="./../assets/tabela_6.png"/>
          </div>
        </div>
        <div class="page-break"></div>
        <div class="mt-3 text-start row">
          <div class="fw-bold">4 - Ações de Emergência e Contingência de Resíduos Sólidos</div>
          <div class="mt-3 indent">
            <p>As ações de respostas a emergências visam descrever as medidas e ações que devem ser adotadas para enfrentamento de situações atípicas, para prevenir e reduzir os impactos quando da ocorrência de sinistros, acidentes e desastres naturais, conferindo maior segurança e confiabilidade operacional aos sistemas.</p>
            <p>A definição de medidas e ações em resposta a situações de emergência estão estabelecidas na tabela, onde estão contempladas as potenciais emergências, classificadas como situações adversas, com as medidas que devem ser adotadas em cada adversidade nos diferentes sistemas que integram o saneamento básico municipal.</p>
            <p>Recomenda-se também que os operadores dos serviços façam o registro das situações emergenciais com a avaliação crítica dos procedimentos sugeridos nesta tabela, para a introdução dos aperfeiçoamentos necessários, com o detalhamento que cada caso requer.</p>
          </div>
          <div class="text-center">
            <div class="text-muted fs-7">Medidas de emergência contingência</div>
            <img class="img-fluid" src="./../assets/tabela_7.png"/>
          </div>
        </div>
        <div class="page-break"></div>
        <div class="mt-3 text-start row">
          <div class="text-center">
            <div class="text-muted fs-7 mt-4">Medidas de Emergência</div>
            <img class="img-fluid w-75" src="./../assets/tabela_8.png"/>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div class="text-center">
          <span class="text-muted fs-7">Emitido por www.smisa.com.br em {{formatDateTime(Date())}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RelatorioService from "../services/relatorio-service";
import filters from "../services/filters";

export default {
  name: "DownloadPDF",
  props: ["_id"],
  mixins: [filters],
  emits: ['closeModal'],
  data() {
    return {
      relatorio: null,
      indicadores: [],
      loading: false,
      emitted: false,
      showLoadingModal: false,
      message: "Aguarde, relatório sendo gerado...",
      tipos: [
        {'key':'agua', 'nome': 'Água'},
        {'key':'esgoto', 'nome': 'Esgoto'},
        {'key':'residuo', 'nome': 'Resíduos Sólidos'},
        {'key':'drenagem', 'nome': 'Drenagem'}
      ],
    }
  },
  computed: {
    agua() {
      return this.indicadores.filter((i) => i.tipo === "agua")
    },
    esgoto() {
      return this.indicadores.filter((i) => i.tipo === "esgoto")
    },
    residuos() {
      return this.indicadores.filter((i) => i.tipo === "residuos")
    },
    micro() {
      return this.indicadores.filter((i) => i.tipo === "microdrenagem")
    },
    macro() {
      return this.indicadores.filter((i) => i.tipo === "macrodrenagem")
    }
  },
  async mounted() {
    let rel = await RelatorioService.getOne(this._id).catch((err) => {
      this.$vaToast.init({message: err.response.statusText, color: 'warning'})
    })
    if (rel) {
      this.relatorio = rel.data.reg
      this.indicadores = filters.methods.getIndicadores()

      if (this.relatorio.esgoto)
        this.relatorio.esgoto.localidades.forEach((loc) => {
          this.indicadores.push({
            tipo: "esgoto",
            nome: "indic_iqa_" + loc.id,
            descricao: "IQA - Índice de Qualidade das Águas - " + loc.descricao,
            definicao: "O IQA é calculado pelo produto ponderado das qualidades de água correspondentes aos parâmetros - Oxigênio Dissolvido (OD); - Coliformes Fecais; - Potencial Hidrogeniônico (pH); - Demanda Bioquímica de Oxigênio (DBO5); - Nitrogênio Total; - Fósforo Total; - Turbidez; - Sólidos Totais e; - Temperatura;",
            unidade: "0-100%",
            finalidade: "Avaliar a qualidade das águas superficiais do município"
          })
          this.relatorio.esgoto["indic_iqa_" + loc.id] = loc.indic_iqa
        })
      if (this.relatorio.metas.length) {
        this.relatorio.metas.forEach((meta) => { //Agrupar metas por tipo
          !!this.relatorio.metas[meta.tipo] ? this.relatorio.metas[meta.tipo].push(meta) : this.relatorio.metas[meta.tipo] = [meta]
        })
      }
      document.title = "Relatório " + this.relatorio.periodo
    }
    this.message = "Aguarde, relatório sendo gerado..."
    setTimeout(function() {
      window.print()
	console.log("AQUI")
      window.close()
    }, 500)
  },
  methods: {
    format(num, double=false, real=false, deb) {
      if (deb) debugger
      if (typeof num === 'boolean') {
        return num ? 'Sim' : 'Não'
      }
      if (typeof num === 'string') {
        num = parseFloat(num)
      }

      if (isNaN(num) || num === null) {
        num = 0
      }

      if (real || double)
        num = num.toFixed(2).replace(".",",")

      num = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      return (real ? 'R$ ' : '') + num
    },

    getLinkMaps(local) {
      return "https://www.google.com/maps/search/?api=1&query="+local.latitude_coleta+","+local.longitude_coleta+"&zoom=20&map_action=map"
    },

    close() {
      this.showLoadingModal = false
      this.$emit('closeModal')
    }
  }
}
</script>
<style scoped>
.table th, .table td {
  vertical-align: center;
  text-align: justify;
}
div::before { /* Para divs vazias ocuparem espaço e não quebrarem ordem de valores*/
  content: ""
}
div, div>li, div>p {
  margin-bottom: 6px;
}
.fs-7 {
  font-size: 13px
}
.classificacao.selected {
  font-weight: bold;
}
.bg-purple {
  background-color: rebeccapurple;
}
.indent {
  text-align: justify
}
.indent p {
  text-indent: 30px;
  text-align: justify;
}
.definicao table {
  font-size: 12px;
}
.definicao table tr td{
  padding: 0
}
@media print {
  .page-break { page-break-before: always; } /* page-break-after works, as well */
}
</style>
