import api from "./api-config";

const resource = "/perfil";

export default {
    get() {
        return api.get(`${resource}`);
    },
    update(data) {
        return api.put(`${resource}`, data)
    },
    addImg(data) {
        return api.post(`${resource}-img`, data)
    },
    deleteImg() {
        return api.delete(`${resource}-img`)
    },
    updatePassword(data) {
        return api.put(`${resource}-pass`, data)
    },
}