import { createStore } from 'vuex'
import { loader } from './modules/loader';
import { auth } from './modules/auth';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
      // requestError({ commit }, message) {
      //     this.$vaToast.init({message: 'HAHA', color: 'warning'}) //COMO USAR TOAST AQUI ??
      // }
  },
  modules: {
      loader,
      auth
  }
})
