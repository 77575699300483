<template>
  <div class="d-flex flex-column align-content-start ms-1">
    <div class="fw-bold mt-1">Informações gerais</div>
    <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
      <va-input
          label="Natureza jurídica do órgão municipal responsável"
          v-model="residuos.natureza_juridica"
          class="mt-2 col-12 col-md-6"
      />
      <va-switch
          label="Existência de algum serviço concedido"
          v-model="residuos.servico_concedido"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Órgão também presta serviço de água/esgoto?"
          v-model="residuos.presta_servico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Extensão de vias públicas"
          v-model="residuos.extensao_vias_publicas"
          class="mt-2 col-12 col-md-6"
          double
      />
      <div class="fw-bold mt-2">Cobrança dos serviços</div>
      <div class="ms-2">
        <va-switch
            label="Regular?"
            v-model="residuos.cobranca_regular"
            class="mt-2 col-12 col-md-6"
        />
        <va-input
            label="Forma regular"
            v-model="residuos.forma_regular"
            class="mt-2 col-12 col-md-6"
        />
        <va-switch
            label="Especiais"
            v-model="residuos.especiais"
            class="mt-2 col-12 col-md-6"
        />
      </div>
    </div>

    <div class="fw-bold mt-3">Coleta Regular</div>
    <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
      <va-number
          label="Total de domicílios urbanos atendidos por coleta de lixo"
          v-model="residuos.domicilios_atendidos"
          class="col-12 col-md-6"
      />
    </div>

    <div class="fw-bold mt-3">Receitas e despesas com serviços de limpeza urbana</div>
    <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-number
            label="Despesa Corrente da Prefeitura"
            v-model="residuos.despesa_corrente"
            class="col-12 col-md-6"
            money
        />
      <div class="fw-bold mt-2">Receitas</div>
      <div class="ms-2">
        <va-number
            label="Orçada (R$/ano)"
            v-model="residuos.receita_orcada"
            class="mt-2 col-12 col-md-6"
            money
        />
        <va-number
            label="Arrecadada (R$/ano)"
            v-model="residuos.receita_arrecadada"
            class="mt-2 col-12 col-md-6"
            money
        />
      </div>
      <div class="fw-bold mt-2">Despesas, segundo o agente executor</div>
      <div class="ms-2">
        <va-number
            label="Total (R$/ano)"
            v-model="residuos.despesa_total"
            class="mt-2 col-12 col-md-6"
            money
        />
        <va-number
            label="Público (R$/ano)"
            v-model="residuos.despesa_publico"
            class="mt-2 col-12 col-md-6"
            money
        />
        <va-number
            label="Privado (R$/ano)"
            v-model="residuos.despesa_privado"
            class="mt-2 col-12 col-md-6"
            money
        />
      </div>
    </div>

    <div class="fw-bold mt-3">Recursos federais recebidos para manejo de resíduos sólidos</div>
    <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
      <va-switch
          label="Ocorrência"
          v-model="residuos.recursos_federais_ocorrencia"
          class="col-12 col-md-6"
      />
      <va-number
          label="Valor (R$/ano)"
          v-model="residuos.recursos_federais_valor"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-input
          label="Tipo"
          v-model="residuos.recursos_federais_tipo"
          class="mt-2 col-12 col-md-6"
      />
      <va-input
          label="Aplicação"
          v-model="residuos.recursos_federais_aplicacao"
          class="mt-2 col-12 col-md-6"
      />
    </div>

    <div class="fw-bold mt-3">Qtd. tot. trab. remun. de todo o manejo RSU, segundo o agente executor</div>
    <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
      <va-number
          label="Total empregado"
          v-model="residuos.total_empregado"
          class="col-12 col-md-6"
      />
      <va-number
          label="Público empregado"
          v-model="residuos.publico_empregado"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado empregado"
          v-model="residuos.privado_empregado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="d-flex justify-content-between mt-4">
    <span class="fw-bold">Trabalhadores de frentes de trabalho temporárias</span>
    <div>
      <div v-if="this.frentes && this.frentes.length>1" @click="showDelete" class="btn btn-sm btn-danger me-2"
           title="Excluir localidade">
        <i class="bi-trash"></i>
      </div>
      <div @click="add()" class="btn btn-sm btn-success" title="Adicionar frente temporária">
        <i class="bi-plus rounded rounded-circle"></i>
      </div>
    </div>
  </div>
  <va-tabs v-model="tab" grow>
    <template #tabs>
      <va-tab v-for="frente in frentes" :name="frente.tab" :key="frente.tab" :ref="'tab_'+frente.tab">
        #{{ frente.tab }} {{ frente.descricao }}
      </va-tab>
    </template>
  </va-tabs>
  <div v-for="frente in frentes" class="ms-2">
    <div v-show="tab === frente.tab" class="row">
      <div class="col-12 col-md-6 border-start border-primary border-3 rounded ps-1 mt-2">
        <va-input label="Descricao" v-model="frente.descricao"/>
        <va-number label="Quantidade Empregados" v-model="frente.qtd_empregados" class="mt-2 col-12"/>
        <va-number label="Duração (meses)" v-model="frente.duracao" class="mt-2 col-12"/>
      </div>
      <va-input
          type="textarea"
          label="Serviços predominantes"
          v-model="frente.servicos_predominantes"
          class="mt-2 col-12 col-md-6"
          placeholder="Serviços predominantes"
          rows="4"
      />
    </div>
  </div>
  <!--------- MODAL DELETE --------->
  <va-modal v-model="showModalDelete" hide-default-actions>
    <slot>
        <span class="text-primary">
          Confirma a exclusão da frente <b> #{{ frente.tab }} {{ frente.descricao }} </b> ?
        </span>
      <br/>
      <span class="text-muted fs-6">A exclusão será efetivada após salvar o relatório</span>
    </slot>
    <template #footer>
      <va-button @click="showModalDelete = false" class="mr-2">
        Cancelar
      </va-button>
      <va-button @click="remove" color="danger">
        Excluir
      </va-button>
    </template>
  </va-modal>

  <div class="fw-bold mt-4">Despesas com manejo de resíduos sólidos</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Coleta de Resíduos Solidos domiciliares e públicos</div>
    <div class="ms-2">
      <va-number
          label="Total (R$/ano)"
          v-model="residuos.residuos_solidos_domiciliares_total"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Público (R$/ano)"
          v-model="residuos.residuos_solidos_domiciliares_publico"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Privado (R$/ano)"
          v-model="residuos.residuos_solidos_domiciliares_privado"
          class="mt-2 col-12 col-md-6"
          money
      />
    </div>
    <div class="fw-bold mt-2">Coleta de Resíduos Solidos serviços de saúde</div>
    <div class="ms-2">
      <va-number
          label="Total (R$/ano)"
          v-model="residuos.residuos_solidos_saude_total"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Público (R$/ano)"
          v-model="residuos.residuos_solidos_saude_publico"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Privado (R$/ano)"
          v-model="residuos.residuos_solidos_saude_privado"
          class="mt-2 col-12 col-md-6"
          money
      />
    </div>
    <div class="fw-bold mt-2">Varrição de logradouros públicos</div>
    <div class="ms-2">
      <va-number
          label="Total (R$/ano)"
          v-model="residuos.varricao_logradouros_total"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Público (R$/ano)"
          v-model="residuos.varricao_logradouros_publico"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Privado (R$/ano)"
          v-model="residuos.varricao_logradouros_privado"
          class="mt-2 col-12 col-md-6"
          money
      />
    </div>
    <div class="fw-bold mt-2">Demais serviços, inclusive admin. e com unid. de proc.</div>
    <div class="ms-2">
      <va-number
          label="Total (R$/ano)"
          v-model="residuos.demais_servicos_total"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Público (R$/ano)"
          v-model="residuos.demais_servicos_publico"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Privado (R$/ano)"
          v-model="residuos.demais_servicos_privado"
          class="mt-2 col-12 col-md-6"
          money
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Quantidades de trabalhadores remunerados alocados no manejo de resíduos sólidos, segundo o
    tipo de serviço realizado</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Total (empregado)</div>
    <div class="ms-2">
      <va-number
          label="Público (empregado)"
          v-model="residuos.qtd_trabalhadores_total_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado (empregado)"
          v-model="residuos.qtd_trabalhadores_total_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Coleta</div>
    <div class="ms-2">
      <va-number
          label="Público (empregado)"
          v-model="residuos.qtd_trabalhadores_coleta_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado (empregado)"
          v-model="residuos.qtd_trabalhadores_coleta_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Varrição</div>
    <div class="ms-2">
      <va-number
          label="Público (empregado)"
          v-model="residuos.qtd_trabalhadores_varricao_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado (empregado)"
          v-model="residuos.qtd_trabalhadores_varricao_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Capina e Roçada</div>
    <div class="ms-2">
      <va-number
          label="Público (empregado)"
          v-model="residuos.qtd_trabalhadores_capina_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado (empregado)"
          v-model="residuos.qtd_trabalhadores_capina_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Unidades de Processamento</div>
    <div class="ms-2">
      <va-number
          label="Público (empregado)"
          v-model="residuos.qtd_trabalhadores_processamento_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado (empregado)"
          v-model="residuos.qtd_trabalhadores_processamento_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Outros Serviços</div>
    <div class="ms-2">
      <va-number
          label="Público (empregado)"
          v-model="residuos.qtd_trabalhadores_outros_servicos_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado (empregado)"
          v-model="residuos.qtd_trabalhadores_outros_servicos_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Gerenciais e Administrativos</div>
    <div class="ms-2">
      <va-number
          label="Público (empregado)"
          v-model="residuos.qtd_trabalhadores_gerenciais_adm_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado (empregado)"
          v-model="residuos.qtd_trabalhadores_gerenciais_adm_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre população atendida, estrutura operacional, frequência e terceirização do serviço
    de coleta domiciliar e pública</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <va-switch
        label="Coleta Noturna (existe ou não)"
        v-model="residuos.coleta_noturna"
        class="col-12 col-md-6 d-flex"
    />
    <va-switch
        label="Coleta com elevação de contêiner"
        v-model="residuos.coleta_elevacao_conteiner"
        class="mt-2 col-12 col-md-6"
    />
    <div class="fw-bold mt-2">População atentida declarada</div>
    <div class="ms-2">
      <va-number
          label="Urbana do Município (hab.)"
          v-model="residuos.populacao_atendida_urbana"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Rural do Município (hab.)"
          v-model="residuos.populacao_atendida_rural"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">População atendida, segundo a frequência</div>
    <div class="ms-2">
      <va-number
          label="Diária"
          v-model="residuos.populacao_atendida_diaria"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Duas ou três vezes na semana"
          v-model="residuos.populacao_atendida_duas_tres_vezes_semana"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Uma vez por semana"
          v-model="residuos.populacao_atendida_uma_vez_semana"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Quantidade de coletadores e mot.</div>
    <div class="ms-2">
      <va-number
          label="Prefeitura (empregados)"
          v-model="residuos.qtd_coletadores_prefeitura"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Empresas (empregados)"
          v-model="residuos.qtd_coletadores_empresas"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre quantidades de resíduos sólidos domiciliares e públicos coletados, segundo o tipo
    de resíduo (Ocorrência de coleta de RPU junto com RDO)</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold mt-2">Quantidade total de resíduos coletados</div>
    <div class="ms-2">
      <va-number
          label="Total"
          v-model="residuos.qtd_residuos_coletados_total"
          class="col-12 col-md-6"
      />
      <va-number
          label="Prefeitura"
          v-model="residuos.qtd_residuos_coletados_prefeitura"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Empresas"
          v-model="residuos.qtd_residuos_coletados_empresas"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Assoc. Catadores"
          v-model="residuos.qtd_residuos_coletados_catadores"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Outro Executor"
          v-model="residuos.qtd_residuos_coletados_outro"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Quantidade reaproveitada"
          v-model="residuos.qtd_residuos_coletados_reaproveitados"
          class="col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Quantidade de resíduos domiciliares coletados</div>
    <div class="ms-2">
      <va-number
          label="Total"
          v-model="residuos.qtd_residuos_domiciliares_coletados_total"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Prefeitura"
          v-model="residuos.qtd_residuos_domiciliares_coletados_prefeitura"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Empresas"
          v-model="residuos.qtd_residuos_domiciliares_coletados_empresas"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Assoc. Catadores"
          v-model="residuos.qtd_residuos_domiciliares_coletados_catadores"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Outro Executor"
          v-model="residuos.qtd_residuos_domiciliares_coletados_outro"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Quantidade de resíduos públicos coletados</div>
    <div class="ms-2">
      <va-number
          label="Total"
          v-model="residuos.qtd_residuos_publicos_coletados_total"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Prefeitura"
          v-model="residuos.qtd_residuos_publicos_coletados_prefeitura"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Empresas"
          v-model="residuos.qtd_residuos_publicos_coletados_empresas"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Assoc. Catadores"
          v-model="residuos.qtd_residuos_publicos_coletados_catadores"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Outro Executor"
          v-model="residuos.qtd_residuos_publicos_coletados_outro"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Quantidade de resíduos privados coletados</div>
    <div class="ms-2">
      <va-number
          label="Total"
          v-model="residuos.qtd_residuos_privados_coletados_total"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Prefeitura"
          v-model="residuos.qtd_residuos_privados_coletados_prefeitura"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Empresas"
          v-model="residuos.qtd_residuos_privados_coletados_empresas"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Assoc. Catadores"
          v-model="residuos.qtd_residuos_privados_coletados_catadores"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Outro Executor"
          v-model="residuos.qtd_residuos_privados_coletados_outro"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações diversas sobre coleta de resíduos sólidos</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold mt-2">Coleta de resíduos inertes</div>
    <div class="ms-2">
      <va-number
          label="Quantidade de resíduos sólidos inertes gerados"
          v-model="residuos.residuos_solidos_inertes_gerados"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Quantidade de resíduos sólidos inertes reaproveitados"
          v-model="residuos.residuos_solidos_inertes_reaproveitados"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <va-switch
        label="Uso de balança (sim ou não)"
        v-model="residuos.uso_balanca"
        class="mt-2 col-12 col-md-6 d-flex"
    />
    <div class="fw-bold mt-2">Serviço terceirizado de coleta de RDO+RPU</div>
    <div class="ms-2">
      <va-number
          label="Valor contratual (R$/t)"
          v-model="residuos.servico_rdo_rpu_valor"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-switch
          label="Incluído transporte até a unid. Transbordo destino final"
          v-model="residuos.servico_rdo_rpu_incluido_transporte"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-number
          label="Distância média até a unidade (km)"
          v-model="residuos.servico_rdo_rpu_distancia"
          class="mt-2 col-12 col-md-6"
          double
      />
    </div>
    <div class="fw-bold mt-2">Serviço terceirizado de transporte de unidade transbordo à destino final</div>
    <div class="ms-2">
      <va-number
          label="Valor contratual (R$/t)"
          v-model="residuos.servico_transporte_valor"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-number
          label="Distância média até a unidade (km)"
          v-model="residuos.servico_transporte_distancia"
          class="mt-2 col-12 col-md-6"
          double
      />
    </div>
    <div class="fw-bold mt-2">Serviço terceirizado de disposição final em aterro</div>
    <div class="ms-2">
      <va-switch
          label="Ocorrência de operação do aterro por execução privado (exist.)"
          v-model="residuos.servico_aterro_ocorrencia"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-number
          label="Valor contratual (R$/t)"
          v-model="residuos.servico_aterro_valor"
          class="mt-2 col-12 col-md-6"
          money
      />
    </div>
    <div class="fw-bold mt-2">Remessa de resíduos domiciliares ou públicos p/ outro município</div>
    <div class="ms-2">
      <va-number
          label="Ocorrência"
          v-model="residuos.remessa_outro_municipio_ocorrencia"
          class="mt-2 col-12 col-md-6"
      />
      <va-input
          type="textarea"
          label="Município(s) de destino"
          v-model="residuos.remessa_outro_municipio_destinos"
          placeholder="Município(s) de destino"
          rows="3"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre destinação final de resíduos sólidos domiciliares</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Enquadramento das intalações</div>
    <div class="ms-2">
      <va-number
          label="Índice de qualidade das instalações"
          v-model="residuos.indice_qualidade"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Previsão de vida útil do aterro (anos)"
          v-model="residuos.previsao_vida_aterro"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Vida útil restante do aterro (anos)"
          v-model="residuos.vida_restante_aterro"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre veículos de agentes públicos - na coleta de resíduos
    sólidos domiciliares e públicos</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Quantidade de veículos de agentes públicos</div>
    <div class="ms-2">
      <va-number
          label="Caminhão compactador"
          v-model="residuos.veiculos_publicos_caminhao_compactador"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Cam. bascul. carroceria ou baú"
          v-model="residuos.veiculos_publicos_caminhao_basculante"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Caminhões poliguindaste"
          v-model="residuos.veiculos_publicos_caminhao_poliguindaste"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Trator agrícola com reboque"
          v-model="residuos.veiculos_publicos_trator_agricola"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Tração animal "
          v-model="residuos.veiculos_publicos_trator_animal"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Embarcações"
          v-model="residuos.veiculos_publicos_embarcacoes"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre veículos de agentes privados - na coleta de resíduos
    sólidos domiciliares e públicos</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Quantidade de veículos de agentes privados</div>
    <div class="ms-2">
      <va-number
          label="Caminhão compactador"
          v-model="residuos.veiculos_privados_caminhao_compactador"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Cam. bascul. carroceria ou baú"
          v-model="residuos.veiculos_privados_caminhao_basculante"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Caminhões poliguindaste"
          v-model="residuos.veiculos_privados_caminhao_poliguindaste"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Trator agrícola com reboque"
          v-model="residuos.veiculos_privados_trator_agricola"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Tração animal "
          v-model="residuos.veiculos_privados_trator_animal"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Embarcações"
          v-model="residuos.veiculos_privados_embarcacoes"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre coleta seletiva de resíduos sólidos</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <va-switch
        label="Existência de coleta seletiva"
        v-model="residuos.coleta_seletiva"
        class="col-12 col-md-6 d-flex"
    />
    <va-number
        label="Domicilios atendidos"
        v-model="residuos.coleta_seletiva_domicilios_atendidos"
        class="mt-2 col-12 col-md-6"
    />
    <div class="fw-bold mt-2">Quantidade recolhida (exceto matéria orgânica)</div>
    <div class="ms-2">
      <va-number
          label="Total"
          v-model="residuos.coleta_seletiva_total"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Prefeitura ou SLU"
          v-model="residuos.coleta_seletiva_pref_ou_slu"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Empresas contradas"
          v-model="residuos.coleta_seletiva_empresas"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Catadores c/apoio Prefe"
          v-model="residuos.coleta_seletiva_catadores"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Outros"
          v-model="residuos.coleta_seletiva_outros"
          class="mt-2 col-12 col-md-6"
          double
      />
    </div>
    <div class="fw-bold mt-2">Forma de execução</div>
    <div class="ms-2">
      <va-input
          type="textarea"
          label="Porta a porta em dias específicos"
          v-model="residuos.coleta_seletiva_porta_a_porta"
          placeholder="Porta a porta em dias específicos"
          rows="3"
          class="mt-2 col-12 col-md-6 d-inline-flex"
      />
      <va-input
          type="textarea"
          label="Postos de entrega voluntária"
          v-model="residuos.coleta_seletiva_postos_entrega"
          placeholder="Postos de entrega voluntária"
          rows="3"
          class="mt-2 col-12 col-md-6 d-inline-flex"
      />
      <va-input
          type="textarea"
          label="Outra forma"
          v-model="residuos.coleta_seletiva_outra_forma"
          placeholder="Outra forma"
          rows="3"
          class="mt-2 col-12 col-md-6 d-inline-flex"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre triagem de resíduos sólidos - (provenientes ou não da
    coleta seletiva)</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Materiais rescuperados, exceto material orgânico e rejeito</div>
    <div class="ms-2">
      <va-number
          label="Total (t) "
          v-model="residuos.material_recuperado_total"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Papel e papelão"
          v-model="residuos.material_recuperado_papel"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Plástico"
          v-model="residuos.material_recuperado_plastico"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Metais"
          v-model="residuos.material_recuperado_metais"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Vidros"
          v-model="residuos.material_recuperado_vidros"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Outros"
          v-model="residuos.material_recuperado_outros"
          class="mt-2 col-12 col-md-6"
          double
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre coleta de resíduos sólidos de serviços de saúde</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <va-switch
        label="Ocorr. De cobrança pela coleta diferenciada"
        v-model="residuos.coleta_saude_ocorrencia"
        class="col-12 col-md-6 d-flex"
    />
    <va-switch
        label="Prefeitura controla executores"
        v-model="residuos.coleta_saude_prefeitura_controla"
        class="mt-2 col-12 col-md-6 d-flex"
    />
    <div class="fw-bold mt-2">Execução de coleta dif. de RSS</div>
    <div class="ms-2">
      <va-switch
          label="Existe"
          v-model="residuos.coleta_saude_rss_existe"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Prefeitura ou SLU"
          v-model="residuos.coleta_saude_rss_prefeitura"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Empresa contratada"
          v-model="residuos.coleta_saude_rss_empresa"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Próprio Gerador ou contrat"
          v-model="residuos.coleta_saude_rss_gerador"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
    <div class="fw-bold mt-2">Veículos utilizados</div>
    <div class="ms-2">
      <va-switch
          label="Exclusivo"
          v-model="residuos.coleta_saude_veiculos_exclusivo"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Da coleta domiciliar em viagem exclusiva"
          v-model="residuos.coleta_saude_veiculos_viagem"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
    <div class="fw-bold mt-2">Quantidade de RSS coletados</div>
    <div class="ms-2">
      <va-number
          label="Total"
          v-model="residuos.coleta_saude_qtd_rss_total"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Prefeitura ou contratados"
          v-model="residuos.coleta_saude_qtd_rss_prefeitura"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Geradores ou contratados"
          v-model="residuos.coleta_saude_qtd_rss_geradores"
          class="mt-2 col-12 col-md-6"
          double
      />
    </div>
    <div class="fw-bold mt-2">Coleta de RSS em unidades públicas de saúde terceirizada</div>
    <div class="ms-2">
      <va-switch
          label="Ocorrência"
          v-model="residuos.coleta_saude_rss_saude_ocorrencia"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-number
          label="Valor Contratual"
          v-model="residuos.coleta_saude_rss_saude_valor"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-switch
          label="Inclui tratamento RSS"
          v-model="residuos.coleta_saude_rss_saude_tratamento"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
    <div class="fw-bold mt-2">Trat. de RSS terceirizado</div>
    <div class="ms-2">
      <va-number
          label="Valor Contratual"
          v-model="residuos.coleta_saude_rss_terceirizado_valor"
          class="mt-2 col-12 col-md-6"
          money
      />
    </div>
    <div class="fw-bold mt-2">Remessa de RSS para outros municípios</div>
    <div class="ms-2">
      <va-switch
          label="Ocorrência"
          v-model="residuos.coleta_saude_remessa_municipio_ocorrencia"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-input
          type="textarea"
          label="Município(s)"
          v-model="residuos.coleta_saude_remessa_municipios"
          placeholder="Município(s)"
          rows="3"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Manejo RSS de serviços de saúde</div>
    <div class="ms-2">
      <va-number
          label="Índice de Qualidade"
          v-model="residuos.coleta_saude_manejo_indice"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre coleta de resíduos sólidos da construção civil</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <va-switch
        label="Existência de empresa especilaizada"
        v-model="residuos.coleta_construcao_existe"
        class="col-12 col-md-6 d-flex"
    />
    <div class="fw-bold mt-2">Serviço executado pela prefeitura</div>
    <div class="ms-2">
      <va-switch
          label="Existência"
          v-model="residuos.coleta_construcao_prefeitura_existe"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Cobrança"
          v-model="residuos.coleta_construcao_prefeitura_cobranca"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
    <div class="fw-bold mt-2">Existência de serviço de coleta de RCD feita por autônomos</div>
    <div class="ms-2">
      <va-switch
          label="Com caminhões tipo basculantes ou carroceria"
          v-model="residuos.coleta_construcao_autonomo_caminhao"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Com carroças ou outro tipo de veículo de pequena capacidade"
          v-model="residuos.coleta_construcao_autonomo_carroca"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
    <div class="fw-bold mt-2">Quantidade coletada</div>
    <div class="ms-2">
      <va-number
          label="Prefeitura ou contratado por ela"
          v-model="residuos.coleta_construcao_qtd_prefeitura"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Caçambeiros e autônomos contratados pelo gerador"
          v-model="residuos.coleta_construcao_qtd_autonomos"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Próprio gerador"
          v-model="residuos.coleta_construcao_qtd_gerador"
          class="mt-2 col-12 col-md-6"
          double
      />
    </div>
    <div class="fw-bold mt-2">Quantidade reaproveitada</div>
    <div class="ms-2">
      <va-number
          label="Total"
          v-model="residuos.coleta_construcao_qtd_reaproveitada"
          class="mt-2 col-12 col-md-6 d-flex"
          double
      />
    </div>
    <div class="fw-bold mt-2">Destinação final</div>
    <div class="ms-2">
      <va-number
          label="Índice de Qualidade"
          v-model="residuos.coleta_construcao_destinacao_indice"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre serviço de varrição</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Extensão de sarjeta varrida</div>
    <div class="ms-2">
      <va-number
          label="Público (km)"
          v-model="residuos.varricao_extensao_publico"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Privado (km)"
          v-model="residuos.varricao_extensao_privado"
          class="mt-2 col-12 col-md-6"
          double
      />
      <va-number
          label="Total (km)"
          v-model="residuos.varricao_extensao_total"
          class="mt-2 col-12 col-md-6"
          double
      />
    </div>
    <div class="fw-bold mt-2">Quantidade de varredores</div>
    <div class="ms-2">
      <va-number
          label="Público"
          v-model="residuos.varricao_qtd_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Privado"
          v-model="residuos.varricao_qtd_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
    <div class="fw-bold mt-2">Serviço terceirizado</div>
    <div class="ms-2">
      <va-number
          label="Valor Contratual"
          v-model="residuos.varricao_privado_valor"
          class="mt-2 col-12 col-md-6"
          money
      />
      <va-switch
          label="Ocorrência de varrição mecanizada"
          v-model="residuos.varricao_privado_varricao_mecanizada"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
    <div class="fw-bold mt-2">Despesa</div>
    <div class="ms-2">
      <va-number
          label="Despesa total"
          v-model="residuos.varricao_despesa_total"
          class="mt-2 col-12 col-md-6"
          money
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre serviço de capina e roçada</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Serviço de capina e roçada</div>
    <div class="ms-2">
      <va-switch
          label="Existência"
          v-model="residuos.capina_existe"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Manual"
          v-model="residuos.capina_manual"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Mecanizada"
          v-model="residuos.capina_mecanizada"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Quimica"
          v-model="residuos.capina_quimica"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
    <div class="fw-bold mt-2">Quantidade de trabalhadores</div>
    <div class="ms-2">
      <va-number
          label="Agente Executor - Público"
          v-model="residuos.capina_qtd_publico"
          class="mt-2 col-12 col-md-6"
      />
      <va-number
          label="Agente Executor - Privado"
          v-model="residuos.capina_qtd_privado"
          class="mt-2 col-12 col-md-6"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre outros serviços executados pela prefeitura</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Outros serviços executados pela prefeitura</div>
    <div class="ms-2">
      <va-switch
          label="Lavação de vias e praças"
          v-model="residuos.outros_prefeitura_lavacao"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Poda de árvores"
          v-model="residuos.outros_prefeitura_poda"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de feiras e mercados"
          v-model="residuos.outros_prefeitura_limpeza_feiras"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de praias"
          v-model="residuos.outros_prefeitura_limpeza_praias"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de boca-de-lobo"
          v-model="residuos.outros_prefeitura_limpeza_boca_lobo"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Pintura de meio fio"
          v-model="residuos.outros_prefeitura_pintura"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de lotes vagos"
          v-model="residuos.outros_prefeitura_limpeza_lotes"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Remoção animais mortos"
          v-model="residuos.outros_prefeitura_remocao_animais"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de pneus velhos"
          v-model="residuos.outros_prefeitura_coleta_pneus"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de pilhas baterias"
          v-model="residuos.outros_prefeitura_coleta_pilhas"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de resíduos volumosos"
          v-model="residuos.outros_prefeitura_coleta_volumosos"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de Lâmpadas Fluorescentes"
          v-model="residuos.outros_prefeitura_coleta_lampadas"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de resíduos eletrônico"
          v-model="residuos.outros_prefeitura_coleta_eletronicos"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Outros Serviços"
          v-model="residuos.outros_prefeitura_outros"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre outros serviços executados por empresas contratadas</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Outros serviços executados por empresas contratadas</div>
    <div class="ms-2">
      <va-switch
          label="Lavação de vias e praças"
          v-model="residuos.outros_empresa_lavacao"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Poda de árvores"
          v-model="residuos.outros_empresa_poda"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de feiras e mercados"
          v-model="residuos.outros_empresa_limpeza_feiras"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de praias"
          v-model="residuos.outros_empresa_limpeza_praias"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de boca-de-lobo"
          v-model="residuos.outros_empresa_limpeza_boca_lobo"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Pintura de meio fio"
          v-model="residuos.outros_empresa_limpeza_lotes"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de lotes vagos"
          v-model="residuos.outros_empresa_pintura"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Remoção animais mortor"
          v-model="residuos.outros_empresa_remocao_animais"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de pneus velhos"
          v-model="residuos.outros_empresa_coleta_pneus"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de pilhas baterias"
          v-model="residuos.outros_empresa_coleta_pilhas"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de resíduos volumosos"
          v-model="residuos.outros_empresa_coleta_volumosos"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de Lâmpadas Fluorescentes"
          v-model="residuos.outros_empresa_coleta_lampadas"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de resíduos eletrônico"
          v-model="residuos.outros_empresa_coleta_eletronicos"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Outros Serviços"
          v-model="residuos.outros_empresa_outros"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre outros serviços executados por outros agentes</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <div class="fw-bold">Outros serviços executados por outros agentes</div>
    <div class="ms-2">
      <va-switch
          label="Lavação de vias e praças"
          v-model="residuos.outros_agentes_lavacao"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Poda de árvores"
          v-model="residuos.outros_agentes_poda"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de feiras e mercados"
          v-model="residuos.outros_agentes_limpeza_feiras"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de praias"
          v-model="residuos.outros_agentes_limpeza_praias"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de boca-de-lobo"
          v-model="residuos.outros_agentes_limpeza_boca_lobo"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Pintura de meio fio"
          v-model="residuos.outros_agentes_limpeza_lotes"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Limpeza de lotes vagos"
          v-model="residuos.outros_agentes_pintura"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Remoção animais mortor"
          v-model="residuos.outros_agentes_remocao_animais"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de pneus velhos"
          v-model="residuos.outros_agentes_coleta_pneus"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de pilhas baterias"
          v-model="residuos.outros_agentes_coleta_pilhas"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de resíduos volumosos"
          v-model="residuos.outros_agentes_coleta_volumosos"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de Lâmpadas Fluorescentes"
          v-model="residuos.outros_agentes_coleta_lampadas"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Coleta de resíduos eletrônico"
          v-model="residuos.outros_agentes_coleta_eletronicos"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-switch
          label="Outros Serviços"
          v-model="residuos.outros_agentes_outros"
          class="mt-2 col-12 col-md-6 d-flex"
      />
    </div>
  </div>

  <div class="fw-bold mt-4">Informações sobre catadores</div>
  <div class="ms-2 border-start border-primary border-3 rounded ps-1 mt-2">
    <va-switch
        label="Existência de catadores disperso"
        v-model="residuos.catadores_disperso"
        class="col-12 col-md-6 d-flex"
    />
    <va-switch
        label="Existência de trabalho social executado pela Prefeitura"
        v-model="residuos.catadores_trabalho_social"
        class="mt-2 col-12 col-md-6 d-flex"
    />
    <div class="fw-bold mt-2">Organização</div>
    <div class="ms-2">
      <va-switch
          label="Existência de organização formal (sim ou não)"
          v-model="residuos.catadores_organizacao_formal"
          class="mt-2 col-12 col-md-6 d-flex"
      />
      <va-input
          label="Quantidade de entidades associativas (entidades)"
          v-model="residuos.catadores_organizacao_qtd_entidades"
          class="mt-2 col-12 col-md-6"
      />
      <va-input
        label="Quantidade de associados (pessoas)"
        v-model="residuos.catadores_organizacao_qtd_associados"
        class="mt-2 col-12 col-md-6"
    />
    </div>
  </div>
</template>
<script>
export default {
  name: "DadosResiduos",
  props: ["residuos", "frentes"],
  emits: ["addFrente", "removeFrente"],
  data() {
    return {
      tab: this.frentes && this.frentes[0] ? this.frentes[0].tab : null,
      showModalDelete: false,
      frente: {}
    }
  },
  methods: {
    add() {
      this.$emit('addFrente')
    },
    showDelete() {
      this.frente = this.frentes.find((l) => {
        return l.tab === this.tab
      })
      this.showModalDelete = true
    },
    async remove() {
      await this.$emit('removeFrente', this.tab)
      this.tab = this.frentes[0].tab
      this.showModalDelete = false
    },
    changeTab(tab) {
      this.$nextTick(function () {
        this.$refs['tab_' + tab].onTabClick()
      })
    }
  }
}
</script>
<style>
@media (max-width: 576px) {
  .va-switch__label {
    font-size: 13px !important;
  }
}
</style>