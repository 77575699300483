<template>
  <div class="flex">
    <va-card :bordered="false">
      <va-card-title>

        <va-breadcrumbs color="black">
          <va-breadcrumbs-item>
            <router-link :to="{name:'VisualizarRelatorios'}" class="text-decoration-none text-dark">Relatórios</router-link>
          </va-breadcrumbs-item>
          <va-breadcrumbs-item label='Metas'/>
        </va-breadcrumbs>

        <div class="ms-auto">
          <div class="btn btn-success btn-sm d-inline-flex" @click="save()">
            <i class="bi-save me-2"/><span class="text-capitalize">Salvar</span>
          </div>
        </div>

      </va-card-title>
      <va-card-content>

        <div class="d-flex align-items-center mb-1">
          <div class="fs-4 fw-bold">Metas {{periodo}}</div>
        </div>

        <va-form ref="formMetas" enctype="multipart/form-data">
          <va-tabs v-model="tab" grow>
            <template #tabs>
              <template v-for="tipo in tipos">
                <va-tab :name="tipo.key">
                  {{ tipo.nome }}
                </va-tab>
              </template>
            </template>
          </va-tabs>
          <div>
            <div class="row text-center mt-4 fs-7">
              <span class="text-black-50 col-12 col-md-4">Meta</span>
              <span class="text-black-50 col-12 col-md-4">Objetivo</span>
              <span class="text-black-50 col-12 col-md-4">Resultado</span>
            </div>
            <template v-for="tipo in tipos">
              <div v-show="tab === tipo.key" class="text-center">
                <div class="d-flex mb-1 justify-content-center align-items-center" v-for="(meta, key) in metas[tipo.key]">
                  <va-input
                      type="textarea"
                      v-model="meta.descricao"
                      placeholder="Descreva a meta"
                      rows="8"
                  />
                  <va-input
                      type="textarea"
                      v-model="meta.objetivo"
                      class="ms-1 me-1"
                      placeholder="Descreva o objetivo"
                      rows="8"
                  />
                  <va-input
                      type="textarea"
                      v-model="meta.resultado"
                      placeholder="Descreva o resultado"
                      rows="8"
                  />
                  <i v-if="metas[tipo.key].length>1"
                     @click="metas[tipo.key].splice(key, 1)"
                     class="bi-trash text-danger pointer"
                     title="Excluir Meta"
                  ></i>
                </div>
                <div @click="addMeta(tipo.key)" class="btn btn-sm btn-primary mt-2" title="Adicionar Meta">
                  <i class="bi-plus rounded rounded-circle"></i>Meta
                </div>
              </div>
            </template>
          </div>
        </va-form>

      </va-card-content>
    </va-card>

  </div>
</template>
<script>
import MetasService from "../../../services/metas-service"
export default {
  name: 'Metas',
  props: ['_id', '_periodo'],
  data() {
    return {
      periodo: this._periodo,
      tab: 'agua',
      metas: [],
      tipos: [
          {'key':'agua', 'nome': 'Água'},
          {'key':'esgoto', 'nome': 'Esgoto'},
          {'key':'residuo', 'nome': 'Resíduos Sólidos'},
          {'key':'drenagem', 'nome': 'Drenagem'}
      ],
    }
  },

  async mounted() {
    await this.getMetas(this._id)
  },

  methods: {
    addMeta(key) {
      this.metas[key].push({
        'descricao':'',
        'objetivo': '',
        'resultado': '',
        'tipo': key
      })
    },

    async getMetas(idrelatorio) {
      let vm = this
      let metas = await MetasService.get(idrelatorio).catch((err) => {
        vm.$vaToast.init({message: err.response.statusText, color: 'warning'})
      })
      if (!!metas && metas.data.regs.length) {
        metas.data.regs.forEach((meta) => { //Agrupar metas por tipo
          !!vm.metas[meta.tipo] ? vm.metas[meta.tipo].push(meta) : vm.metas[meta.tipo] = [meta]
        })
      }
      //Se algum tipo não tiver nenhuma meta adiciona uma vazia
      vm.tipos.forEach(function(t) {
        if (!vm.metas[t.key] || !vm.metas[t.key].length) {
          vm.metas[t.key] = [{
            'descricao': '',
            'objetivo': '',
            'resultado': '',
            'tipo': t.key
          }]
        }
      })
    },

    async save() {
      let data = []
          .concat(this.metas['agua'])
          .concat(this.metas['esgoto'])
          .concat(this.metas['drenagem'])
          .concat(this.metas['residuo'])
      let retorno = await MetasService.create(data, this._id).catch((err) => {
        this.$vaToast.init({message: err.response.statusText, color: 'danger'})
      })
      if (retorno.data.erro) {
        this.$vaToast.init({message: retorno.data.mensagem, color: 'danger'})
      } else {
        this.$vaToast.init({message: retorno.data.mensagem ?? "Metas salvas", color: 'success'})
      }
    },
  }
}
</script>
<style scoped>
.fs-7 {
  font-size: 13px !important;
}
@media (max-width: 576px) {
  .fs-4 {
    font-size: 13px !important;
  }
}
</style>