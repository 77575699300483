<template>
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <!-- Navbar Brand-->
    <router-link :to="{ name: 'Dashboard' }" class="navbar-brand ps-3 not-active">SMISA</router-link>

    <!-- Sidebar Toggle-->
    <button class="d-block d-lg-none btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" href="#!" @click="menuToggle()">
      <i class="fs-4 bi-list text-white"></i>
    </button>

    <!-- Navbar Search-->
    <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
    </form>

    <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle truncate" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown"
           aria-expanded="false">
          <i class="fs-4 bi-person"></i>
          {{ nome }}
        </a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
          <li>
            <router-link :to="{ name:'Perfil'}" class="dropdown-item not-active">Perfil</router-link>
          </li>
          <li>
            <hr class="dropdown-divider"/>
          </li>
          <li><a @click="logout()" class="dropdown-item" href="">Sair</a></li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'NavBar',
  data() {
    return {
      nome: "Usuário"
    }
  },
  created() {
    this.nome = sessionStorage.getItem('username')
  },
  methods: {
    menuToggle: function (close = true) {
      document.body.classList.toggle('sb-sidenav-toggled');
    },
    logout: function () {
      this.$store.dispatch("auth/doLogout").then(() => {
        this.$router.push({ name: 'Login' })
      })
    }
  },
  watch: {
    '$route' () {
      document.body.classList.remove('sb-sidenav-toggled');
    }
  }
}
</script>
<style scoped>
@media (max-width: 576px) {
  .truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>