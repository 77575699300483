<template>
  <Loader :visible="loading"/>

  <NavBar/>

  <div id="layoutSidenav">
    <SideNav/>

    <div id="layoutSidenav_content">
      <main class="px-0 py-2 p-md-3 bg-light-gray h-100 overflow-auto">
        <router-view/>
      </main>
<!--            <footer class="py-4 bg-light mt-auto">-->
<!--                <div class="container-fluid px-4">-->
<!--                    <div class="d-flex align-items-center justify-content-between small">-->
<!--                        <div class="text-muted">Copyright &copy; Your Website 2021</div>-->
<!--                        <div>-->
<!--                            <a href="#">Privacy Policy</a>-->
<!--                            &middot;-->
<!--                            <a href="#">Terms &amp; Conditions</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </footer>-->
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar";
import SideNav from "../../components/SideNav";
import Loader from "../../components/Loader";
import { mapState } from "vuex";

export default {
  components: {SideNav, NavBar, Loader},
  computed: {
    ...mapState("loader", ["loading"])
  }
}
</script>
<style scoped>
.bg-light-gray {
  background-color: #f6f7f6;
}
.toast-zindex {
  z-index: 1080 !important;
}
#layoutSidenav_content {
  height: calc(100vh - 56px)
}
</style>