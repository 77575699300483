import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import VaNumber from './components/VaNumber'
import ToastInterceptor from './components/ToastInterceptor'

require('./css/font.css')
require('./css/styles.css')

const token = sessionStorage.getItem('user-token')
if (token) {
    axios.defaults.headers.common['Authorization'] = token
}

createApp(App)
    .use(store)
    .use(router)
    .use(VuesticPlugin, {
        colors: {
            // Default colors
            primary: '#0d6efd',
            secondary: '#6c757d',
            success: '#198754',
            info: '#0dcaf0',
            danger: '#dc3545',
            warning: '#ffc107',
            gray: '#6c757d',
            dark: '#212529'
        },
        components: {
            VaToast: {
                offsetY: 65,
                customClass: 'toast-zindex'
            },
            VaInput: {
                // outline: true
            },
            VaSwitch: {
                trueInnerLabel: "Sim",
                falseInnerLabel: "Não",
                size: "small"
            }
        }
    })
    .component("VaNumber", VaNumber)
    .component("ToastInterceptor", ToastInterceptor)
    .mount('#app')