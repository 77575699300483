<template>
  <div class="flex">
    <va-card :bordered="false">
      <va-card-title>

        <va-breadcrumbs color="black">
          <va-breadcrumbs-item>
            <router-link :to="{name:'Dashboard'}" class="text-decoration-none text-dark">Painel Inicial</router-link>
          </va-breadcrumbs-item>
          <va-breadcrumbs-item :label="(isNew ? 'Novo' : 'Editar') + ' Relatório'"/>
        </va-breadcrumbs>

        <div class="ms-auto">
          <div class="btn btn-success btn-sm d-inline-flex" @click="save()">
            <i class="bi-save me-2"/><span class="text-capitalize">Salvar</span>
          </div>
        </div>

      </va-card-title>
      <va-card-content>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <div @click="avancar(1)"
               :class="{[step_off]: step > 1, [step_on]: step === 1}"
               class="btn rounded shadow-sm"><i class="fs-3 bi-plus"></i>
          </div>

          <div class="col"><va-progress-bar size="small" :model-value="step > 1 ? 100 : 0"/></div>

          <div @click="avancar(2)"
               :class="{[step_off]: step > 2, [step_on]: step === 2}"
               class="btn rounded shadow-sm"><i class="fs-3 bi-droplet"></i>
          </div>

          <div class="col"><va-progress-bar size="small" :model-value="step > 2 ? 100 : 0"/></div>

          <div @click="avancar(3)"
               :class="{[step_off]: step > 3, [step_on]: step === 3}"
               class="btn rounded shadow-sm">
            <i class="fs-3">
              <sewage-icon class="pb-2 img-icon" :class="{'primary': step >= 3}"/>
            </i>
          </div>

          <div class="col"><va-progress-bar size="small" :model-value="step > 3 ? 100 : 0"/></div>

          <div @click="avancar(4)"
               :class="{[step_off]: step > 4, [step_on]: step === 4}"
               class="btn rounded shadow-sm"><i class="fs-3 bi-trash"></i>
          </div>

          <div class="col"><va-progress-bar size="small" :model-value="step > 4 ? 100 : 0"/></div>

          <div @click="avancar(5)"
               :class="{[step_off]: step > 5, [step_on]: step === 5}"
               class="btn rounded shadow-sm"><i class="fs-3 bi-tsunami"></i>
          </div>

          <div class="col"><va-progress-bar size="small" :model-value="step > 5 ? 100 : 0"/></div>

          <div @click="avancar(6)"
               :class="{[step_on]: step === 6}"
               class="btn rounded shadow-sm"><i class="fs-3 bi-graph-up"></i>
          </div>
        </div>

        <div class="d-flex align-items-center mb-1">
          <div v-if="step === 1" class="fs-4 fw-bold">Dados Gerais</div>
          <div v-if="step === 2" class="fs-4 fw-bold">Dados referentes à Água</div>
          <div v-if="step === 3" class="fs-4 fw-bold">Dados referentes à Esgoto</div>
          <div v-if="step === 4" class="fs-4 fw-bold">Dados referentes à Resíduos</div>
          <div v-if="step === 5" class="fs-4 fw-bold">Dados referentes à Micro e Macrodrenagem</div>
          <div v-if="step === 6" class="fs-4 fw-bold">Indicadores</div>
          <div :class="{disabled: step < 2}" @click="voltar()" class="ms-auto btn btn-sm btn-outline-primary">
            <i class="bi-arrow-left-circle me-2"></i>Voltar
          </div>
          <div v-if="step < 6" @click="avancar()" class="ms-1 btn btn-sm btn-primary">
            <i class="bi-arrow-right-circle me-2"></i>Avançar
          </div>
          <div v-if="step === 6" @click="emitir()" class="ms-1 btn btn-sm btn-success">
            <i class="bi-download me-2"></i> Emitir Relatório
          </div>
        </div>

        <va-form ref="formRelatorio" enctype="multipart/form-data">
          <dados-gerais
              v-if="step === 1"
              :relatorio="relatorio"
          />

          <dados-agua
              v-if="step === 2"
              :agua="agua"
              :meses="meses"
          />

          <dados-esgoto
              v-if="step === 3"
              :esgoto="esgoto"
              :localidades="localidades"
              @add-localidade="addLocalidade"
              @remove-localidade="removeLocalidade"
              ref="esgoto"
          />

          <dados-residuos
              v-if="step === 4"
              :residuos="residuos"
              :frentes="frentes_temporarias"
              @add-frente="addFrente"
              @remove-frente="removeFrente"
              ref="residuos"
          />

          <dados-drenagem
              v-if="step === 5"
              :microdrenagem="microdrenagem"
              :macrodrenagem="macrodrenagem"
          />

          <dados-indicadores
              v-if="step === 6"
              :relatorio="relatorio"
              :isNew="isNew"
          />
        </va-form>

      </va-card-content>
    </va-card>

  </div>
</template>
<script>
import DadosGerais from "./DadosGerais";
import DadosAgua from "./DadosAgua";
import DadosEsgoto from "./DadosEsgoto";
import DadosResiduos from "./DadosResiduos";
import DadosDrenagem from "./DadosDrenagem";
import DadosIndicadores from "./DadosIndicadores";
import VaNumber from "../../../components/VaNumber";
import SewageIcon from "../../../components/SewageIcon";
import DownloadPDF from "../../../components/DownloadPDF";

import RelatorioService from "../../../services/relatorio-service"
import LocalidadeService from "../../../services/localidade-service"

export default {
  name: 'Relatorio',
  components: { DadosGerais, DadosAgua, DadosResiduos, DadosDrenagem, DadosIndicadores, DadosEsgoto, VaNumber, SewageIcon, DownloadPDF},
  props: ['_id', '_step'],
  data() {
    return {
      step_on: "text-primary border-primary border-2",
      step_off: "text-primary border-primary",
      isNew: true,
      step: 1,
      relatorio: {},
      agua: {},
      esgoto: {},
      residuos: {},
      microdrenagem: {},
      macrodrenagem: {},
      meses: [
        {"mes": 1, "nome": "Janeiro", "abrev" : "Jan"},
        {"mes": 2, "nome": "Fevereiro", "abrev" : "Fev"},
        {"mes": 3, "nome": "Março", "abrev" : "Mar"},
        {"mes": 4, "nome": "Abril", "abrev" : "Abr"},
        {"mes": 5, "nome": "Maio", "abrev" : "Mai"},
        {"mes": 6, "nome": "Junho", "abrev" : "Jun"},
        {"mes": 7, "nome": "Julho", "abrev" : "Jul"},
        {"mes": 8, "nome": "Agosto", "abrev" : "Ago"},
        {"mes": 9, "nome": "Setembro", "abrev" : "Set"},
        {"mes": 10, "nome": "Outubro", "abrev" : "Out"},
        {"mes": 11, "nome": "Novembro", "abrev" : "Nov"},
        {"mes": 12, "nome": "Dezembro", "abrev" : "Dez"}
      ],
      localidades: [{ 'tab': 1 }],
      localidades_remove: [],
      frentes_temporarias: [{ 'tab': 1 }],
      frentes_temporarias_remove: []
    }
  },
  async mounted() {
    if (this._id) { //Se tiver id nas props, é edição
      this.isNew = false
      await this.getRelatorio()
    }
    //Se tiver step nas props, já abrir na aba em questão
    this.step = this._step ? parseInt(this._step) : 1
  },
  methods: {
    avancar(goto) {
      if (this.step !== 1 || this.$refs.formRelatorio.validate())
        if (goto)
          this.step = goto
        else
          this.step += 1
    },

    voltar() {
      this.step -= 1
    },

    async emitir() {
      await this.save(true)
      let routeData = this.$router.resolve({name: 'DownloadRelatorio', params: {_id: this.relatorio.id}});
      window.open(routeData.href, '_blank');
    },

    addLocalidade() {
      let tab = this.localidades[this.localidades.length-1].tab + 1;
      this.localidades.push({'tab': tab})
      this.$refs.esgoto.changeTab(tab)
    },

    removeLocalidade(tab) {
      this.localidades_remove.push({id: this.localidades[tab-1].id});
      return this.localidades = this.localidades.filter((l) => {
        return l.tab !== tab
      })
    },

    addFrente() {
      let tab = this.frentes_temporarias.length ? this.frentes_temporarias[this.frentes_temporarias.length-1].tab + 1 : 1;
      this.frentes_temporarias.push({'tab': tab})
      this.$refs.residuos.changeTab(tab)
    },

    removeFrente(tab) {
      this.frentes_temporarias_remove.push({id: this.frentes_temporarias[tab-1].id});
      return this.frentes_temporarias = this.frentes_temporarias.filter((l) => {
        return l.tab !== tab
      })
    },

    async getRelatorio(id=null) {
      let idrelatorio = this._id ?? id;
      let rel = await RelatorioService.getOne(idrelatorio).catch((err) => {
        this.$vaToast.init({message: err.response.statusText, color: 'warning'})
      })
      if (rel) {
        this.relatorio = rel.data.reg
        this.agua = rel.data.reg.agua || {}
        this.esgoto = rel.data.reg.esgoto || {}
        this.residuos = rel.data.reg.residuos || {}
        this.microdrenagem = rel.data.reg.microdrenagem || {}
        this.macrodrenagem = rel.data.reg.macrodrenagem || {}
        this.meses = (rel.data.reg.agua && rel.data.reg.agua.meses.length) ? rel.data.reg.agua.meses : this.meses
        this.localidades = (rel.data.reg.esgoto && rel.data.reg.esgoto.localidades.length) ? rel.data.reg.esgoto.localidades : this.localidades
        if (this.localidades.length) {
          this.localidades.forEach((l, i) => l.tab = i+1)
        }
        this.frentes_temporarias = rel.data.reg.residuos ? rel.data.reg.residuos.frentes : this.frentes_temporarias
        if (this.frentes_temporarias.length) {
          this.frentes_temporarias.forEach((l, i) => l.tab = i+1)
        }
      } else {
        await this.$router.push({name: 'VisualizarRelatorios'})
      }
    },

    async save(download = false) {
      if (this.$refs.formRelatorio.validate()) {
        this.agua.meses = this.meses
        this.esgoto.localidades = this.localidades
        this.esgoto.localidades_remove = this.localidades_remove
        this.residuos.frentes = this.frentes_temporarias
        this.residuos.frentes_remove = this.frentes_temporarias_remove
        let retorno
        let idrelatorio
        let data = {
          'dadosRelatorio': {
            ...this.relatorio,
            'agua': this.agua,
            'esgoto': this.esgoto,
            'residuos': this.residuos,
            'microdrenagem': this.microdrenagem,
            'macrodrenagem': this.macrodrenagem
          }
        };
        if (this.isNew) { //Se for novo chama método create, senão, update
          retorno = await RelatorioService.create(data).catch((err) => {
            this.$vaToast.init({message: err.response.statusText, color: 'warning'})
          })
          idrelatorio = retorno.data.reg.id;
        } else {
          retorno = await RelatorioService.update(data).catch((err) => {
            this.$vaToast.init({message: err.response.statusText, color: 'warning'})
          })
          let dadosRetorno = JSON.parse(retorno.config.data);
          idrelatorio = dadosRetorno.dadosRelatorio.id;
        }
        if (retorno.data && !download) {
          if (retorno.data.erro){
            this.$vaToast.init({message: retorno.data.mensagem, color: 'warning'})
          } else {
            ///////////////////////////////////////////////////
            // aqui a logica para deleter imagem das localidades removidas
            if (this.esgoto.localidades_remove.length) {
              this.esgoto.localidades_remove.forEach((localremove) => {
                LocalidadeService.deleteImg(localremove.id).then((res) => {
                  console.log('success local remove: ', res);
                }).catch((err) => {
                  console.log('error local remove: ' + err)
                })
              })
            }

            ///////////////////////////////////////////////////
            // aqui a logica para salvar imagem das localidades
            if (this.localidades.length) {
              // grava dados do form na constante
              const dados_localidades = this.localidades;
              // atualiza dados dos objetos com dados da consulta do banco
              await this.getRelatorio(idrelatorio);
              // percorre dados form e dados atualizados para inserir/deletar imagens
              this.localidades.forEach((localret, keyret) => {
                dados_localidades.forEach((local, key) => {
                  if (keyret === key) {
                    console.log('local: ', local.file_evidencia, local.id, localret.id);
                    if (local.file_evidencia) {
                      if (localret.id && local.file_evidencia !== localret.file_evidencia) {
                        let formData = new FormData()
                        formData.append('file', local.file_evidencia)
                        LocalidadeService.addImg(formData, localret.id).then((res) => {
                          console.log('success: ', res);
                        }).catch((err) => {
                          console.log('error: ' + err)
                        })
                      }
                    } else {
                      if (localret.file_evidencia) {
                        LocalidadeService.deleteImg(local.id).then((res) => {
                          console.log('success: ', res);
                        }).catch((err) => {
                          console.log('error: ' + err)
                        })
                      }
                    }
                  }
                })
              })
              // atualiza objtos com imagens novas
              await this.getRelatorio(idrelatorio)
            }
            // fim imagens localidades
            ///////////////////////////////////////////////////

            this.$vaToast.init({message: retorno.data.mensagem ?? "Relatório salvo", color: 'success'})
            if (this.isNew) //se for novo, envia para o editar com o id do relatório criado
              await this.$router.push({name: 'EditarRelatorio', params: {_id: retorno.data.reg.id, _step: this.step}})
            else
              await this.getRelatorio(idrelatorio)
          }
        }
      }
    },
  },
}
</script>
<style scoped>
.img-icon {
  width: 30px;
}
.va-input-wrapper.va-switch:not(.va-switch--checked) > .va-input-wrapper__content > .va-switch__container > .va-switch__inner > .va-switch__track > .va-switch__track-label {
  color: #00000096 !important;
}
.va-input-wrapper.va-switch.va-switch--checked > .va-input-wrapper__content > .va-switch__container > .va-switch__inner > .va-switch__track {
  background-color: #0d6efd !important;
}

@media (max-width: 576px) {
  .fs-4 {
    font-size: 13px !important;
  }
  .va-switch__label {
    font-size: 13px !important;
  }
}
</style>