<template>
  <div class="overlay" v-show="visible">
    <div class="loader">
      <va-progress-circle indeterminate size="large" color="#fff">
        ...
      </va-progress-circle>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: ["visible"]
};
</script>
<style>
.overlay {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: .5;
  z-index: 999999;
}
.loader {
  background: transparent;
  transition: 0.15s ease-in opacity;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all
}
</style>