<template>
  <div class="d-flex flex-column align-content-start ms-1">
    <div class="border-start border-primary border-3 rounded ps-1 mt-3">
      <input
          type="hidden"
          v-model="relatorio.id"
          class="hide"
      />
      <va-input
          type="text"
          label="Período (ano)"
          v-model="relatorio.periodo"
          class="col-10 col-md-4"
          outline
          :mask="{blocks: [4]}"
          :rules="[value => (value && value.length === 4 && !isNaN(value)) || 'Insira o ano com 4 dígitos']"
          autofocus
      />
      <va-input
          type="input"
          label="Localidade"
          v-model="relatorio.localidade"
          class="mt-3 col-10 col-md-4"
          outline
          :rules="[value => (value && value.length > 0) || 'Campo obrigatório']"
          autofocus
      />
      <va-number
          label="Total de domicílios"
          v-model="relatorio.total_domicilios"
          class="mt-3 col-10 col-md-4"
      />
      <va-number
          label="População Total (IBGE)"
          v-model="relatorio.populacao_total"
          class="mt-3 col-10 col-md-4"
      />
      <va-number
          label="População Urbana"
          v-model="relatorio.populacao_urbana"
          class="mt-3 col-10 col-md-4"
      />
      <va-input
          type="textarea"
          label="Comentários"
          v-model="relatorio.comentario"
          class="mt-3 col-12 col-md-6"
          placeholder="Comentário do relatório"
          rows="8"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "DadosGerais",
  props: ["relatorio"]
}
</script>