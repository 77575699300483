<template>
  <div class="d-flex flex-column align-content-start ms-1">
    <div class="border-start border-primary border-3 rounded ps-1 mt-3">
      <va-number
          label="Total de domicílios com atendimento da rede de água"
          v-model="agua.total_domicilios_com_atendimento"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Total de domicílios urbanos com atendimento com rede de água"
          v-model="agua.total_domicilios_urbanos_com_atendimento"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Parcela da população da amostra satisfeita"
          v-model="agua.parcela_populacao_satisfeita"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Número de reclamações por falta de água"
          v-model="agua.numero_reclamacoes"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Numero total de economias (ligações de água)"
          v-model="agua.total_economias_ligacoes"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Total de domicílios na área de atendimento"
          v-model="agua.total_domicilios_area_atendimento"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Domicílios rurais dentro da área de atendimento"
          v-model="agua.domicilios_rurais_dentro_da_area"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Domicílios urbanos fora da área de atendimento"
          v-model="agua.domicilios_urbanos_fora_da_area"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Numero total de economias com hidrometro"
          v-model="agua.total_economias_hidrometro"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Volume entregue de água (L/dia)"
          v-model="agua.volume_entregue"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Volume de água de uso social e operacional (L/dia)"
          v-model="agua.volume_uso_social_operacional"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Volume faturado de água no ano"
          v-model="agua.volume_faturado"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Despesa operacional dos ultimos 12 meses"
          v-model="agua.despesa_operacional"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Receita operacional ultimos 12 meses (o que foi arrecadado)"
          v-model="agua.receita_operacional"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Despesa com pessoal proprio"
          v-model="agua.despesa_pessoal_proprio"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Número total de empregados"
          v-model="agua.total_empregados"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Volume de consumo (L/dia)"
          v-model="agua.volume_consumo"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Vazão produzida (L/s)"
          v-model="agua.vazao_produzida"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Capacidade da ETA (L/s)"
          v-model="agua.capacidade_eta"
          class="mt-3 col-12 col-md-6"
      />
      <va-number
          label="Investimentos realizados no ano"
          v-model="agua.investimentos_realizados"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Serviços de dívida no ano (financiamentos) (valor pago do financiamento no ano)"
          v-model="agua.servicos_divida"
          class="mt-3 col-12 col-md-6"
          money
      />
      <va-number
          label="Tarifa média praticada"
          v-model="agua.tarifa_media"
          class="mt-3 col-12 col-md-6"
          money
      />
    </div>
    <div class="my-3">
      <va-divider/>
    </div>
    <span class="fw-bold">Dados mensais referentes à água:</span>
    <va-tabs v-model="tab_mes" grow>
      <template #tabs>
        <va-tab v-for="mes in meses" :name="mes.mes" :key="mes.mes">
          {{ mes.abrev }}
        </va-tab>
      </template>
    </va-tabs>
    <div v-for="mes in meses">
      <div v-show="tab_mes === mes.mes" class="border-start border-primary border-3 rounded ps-2 mt-3">
        <div class="text-center mt-3 lead fw-bold">{{ mes.nome }}</div>
        <va-switch
            label="Análise de coliformes totais"
            v-model="mes.bacteriologica"
            class="col-12 col-md-4"
        />
        <va-number label="Análise de cloro livre residual" v-model="mes.cloro" class="mt-2 col-12 col-md-4" double />
        <va-number label="Análise de turbidez" v-model="mes.turbidez" class="mt-2 col-12 col-md-4" double />
        <va-number label="Análise de fluoretos" v-model="mes.fluoretos" class="mt-2 col-12 col-md-4" double />
        <va-number label="Análise de cor" v-model="mes.cor" class="mt-2 col-12 col-md-4" double />
        <va-number label="Análise de pH" v-model="mes.ph" class="mt-2 col-12 col-md-4" double />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DadosAgua",
  props: ["agua", "meses"],
  data() {
    return {
      tab_mes: 1,
      mes: []
    }
  }
}
</script>