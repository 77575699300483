<template>
  <div class="d-flex flex-column align-content-start ms-1 lh-base">
    <template v-if="isNew"><span>Salve o relatório para que os indicadores sejam calculados</span></template>
    <template v-else>
      <div class="lead fw-bold mt-2">Indicadores referente à Água</div>
      <table class="va-table w-100 fs-7 bg-gray mt-1 rounded-2">
        <thead>
          <tr>
            <th>Nome do Indicador</th>
            <th class="d-none d-md-table-cell">Definição</th>
            <th>Unidade</th>
            <th class="d-none d-md-table-cell">Finalidade</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="indic in agua" :key="indic.nome">
            <td>{{ indic.descricao }}</td>
            <td class="d-none d-md-table-cell">
              <span
                  v-if="indic.definicao.length"
                  class="text-decoration-underline"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="indic.definicao">Visualizar
              </span>
            </td>
            <td>{{ indic.unidade }}</td>
            <td class="d-none d-md-table-cell">{{ indic.finalidade }}</td>
            <td>{{ indicador(relatorio.agua[indic.nome]) }}</td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-3 mb-1">
        <span class="h5 fw-bold">Classificação do IQA</span>
      </div>
      <div class="d-flex justify-content-center">
        <div class="border border-1 text-center bg-gray text--no-wrap">
          <div class="classificacao row text-white bg-primary" :class="{'selected': relatorio.agua.indic_iqa > 97}">
            <div class="col-7">Excelente</div>
            <div class="col-4">IQA > 97</div>
          </div>
          <div class="classificacao row text-white bg-success" :class="{'selected': relatorio.agua.indic_iqa > 90 && relatorio.agua.indic_iqa <= 97}">
            <div class="col-7">Boa</div>
            <div class="col-5">90 &lt IQA &le; 97</div>
          </div>
          <div class="classificacao row text-white bg-warning" :class="{'selected': relatorio.agua.indic_iqa > 80 && relatorio.agua.indic_iqa <= 90}">
            <div class="col-7">Aceitável</div>
            <div class="col-5">80 &lt IQA &le; 90</div>
          </div>
          <div class="classificacao row text-white bg-danger" :class="{'selected': relatorio.agua.indic_iqa > 55 && relatorio.agua.indic_iqa <= 80}">
            <div class="col-7">Aceitável com restrições</div>
            <div class="col-5">55 &lt IQA &le; 80</div>
          </div>
          <div class="classificacao row text-white bg-purple" :class="{'selected': relatorio.agua.indic_iqa <= 55 && relatorio.agua.indic_iqa !== null}">
            <div class="col-7">Péssima</div>
            <div class="col-5">IQA &le; 55</div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <va-divider></va-divider>
      </div>

      <div class="lead fw-bold mt-3">Indicadores referente à Esgoto</div>
      <table class="va-table w-100 fs-7 bg-gray mt-1 rounded-2">
          <thead>
          <tr>
            <th>Nome do Indicador</th>
            <th>Definição</th>
            <th>Unidade</th>
            <th>Finalidade</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="indic in esgoto" :key="indic.nome">
          <td>{{ indic.descricao }}</td>
          <td>
            <span
                v-if="indic.definicao.length"
                class="text-decoration-underline"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="indic.definicao">Visualizar
            </span>
          </td>
          <td>{{ indic.unidade }}</td>
          <td>{{ indic.finalidade }}</td>
          <td>{{ indicador(relatorio.esgoto[indic.nome]) }}</td>
        </tr>
        </tbody>
      </table>

      <div class="text-center mt-3">
        <span class="h5 fw-bold">Classificação do IQA</span>
        <br/>
        <span class="h6 muted">Localidades</span>
      </div>
      <div class="d-flex justify-content-evenly text-center mt-2">
        <div v-for="loc in relatorio.esgoto.localidades" class="row mx-4 mw-50">
          <span class="h6"><b># {{loc.descricao}}</b> <span v-if="!isNaN(loc.indic_iqa)">({{indicador(loc.indic_iqa)}})</span></span>
          <div class="border border-1 bg-gray">
            <div class="classificacao row text-white bg-primary no-wrap" :class="{'selected': loc.indic_iqa > 79}">
              <div class="col-7">Ótima</div>
              <div class="col-5">IQA > 79</div>
            </div>
            <div class="classificacao row text-white bg-success no-wrap" :class="{'selected': loc.indic_iqa > 51 && loc.indic_iqa <= 79}">
              <div class="col-7">Boa</div>
              <div class="col-5">51 &lt IQA &le; 79</div>
            </div>
            <div class="classificacao row text-white bg-warning no-wrap" :class="{'selected': loc.indic_iqa > 36 && loc.indic_iqa <= 52}">
              <div class="col-7">Regular</div>
              <div class="col-5">36 &lt IQA &le; 51</div>
            </div>
            <div class="classificacao row text-white bg-danger no-wrap" :class="{'selected': loc.indic_iqa > 19 && loc.indic_iqa <= 36}">
              <div class="col-7">Ruim</div>
              <div class="col-5">19 &lt IQA &le; 36</div>
            </div>
            <div class="classificacao row text-white bg-purple no-wrap" :class="{'selected': loc.indic_iqa <= 19 && loc.indic_iqa !== null}">
              <div class="col-7">Péssima</div>
              <div class="col-5">IQA &le; 19</div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <va-divider></va-divider>
      </div>

      <div class="lead fw-bold mt-3">Indicadores referente à Resíduos</div>
      <table class="va-table w-100 fs-7 bg-gray mt-1 rounded-2">
          <thead>
          <tr>
            <th>Nome do Indicador</th>
            <th>Definição</th>
            <th>Unidade</th>
            <th>Finalidade</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="indic in residuos" :key="indic.nome">
          <td>{{ indic.descricao }}</td>
          <td>
            <span
              v-if="indic.definicao.length"
              class="text-decoration-underline"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="indic.definicao">Visualizar
            </span>
          </td>
          <td>{{ indic.unidade }}</td>
          <td>{{ indic.finalidade }}</td>
          <td>{{ indicador(relatorio.residuos[indic.nome]) }}</td>
        </tr>
        </tbody>
      </table>

      <div class="mt-3">
        <va-divider></va-divider>
      </div>

      <div class="lead fw-bold mt-3">Indicadores referente à Microdrenagem</div>
      <table class="va-table w-100 fs-7 bg-gray mt-1 rounded-2">
          <thead>
          <tr>
            <th>Nome do Indicador</th>
            <th>Definição</th>
            <th>Unidade</th>
            <th>Finalidade</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="indic in micro" :key="indic.nome">
          <td>{{ indic.descricao }}</td>
          <td>
            <span
              v-if="indic.definicao.length"
              class="text-decoration-underline"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="indic.definicao">Visualizar
            </span>
          </td>
          <td>{{ indic.unidade }}</td>
          <td>{{ indic.finalidade }}</td>
          <td>{{ indicador(relatorio.microdrenagem[indic.nome]) }}</td>
        </tr>
        </tbody>
      </table>

      <div class="mt-3">
        <va-divider></va-divider>
      </div>

      <div class="lead fw-bold mt-3">Indicadores referente à Macrodrenagem</div>
      <table class="va-table w-100 fs-7 bg-gray mt-1 rounded-2">
          <thead>
          <tr>
            <th>Nome do Indicador</th>
            <th>Definição</th>
            <th>Unidade</th>
            <th>Finalidade</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="indic in macro" :key="indic.nome">
          <td>{{ indic.descricao }}</td>
          <td>
            <span
              v-if="indic.definicao.length"
              class="text-decoration-underline"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="indic.definicao">Visualizar
            </span>
          </td>
          <td>{{ indic.unidade }}</td>
          <td>{{ indic.finalidade }}</td>
          <td>{{ indicador(relatorio.macrodrenagem[indic.nome]) }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>
<script>
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import filters from "../../../services/filters";

export default {
  name: "DadosIndicadores",
  props: ["relatorio", "isNew"],
  mixins: [filters],
  data() {
    return {
      indicadores: []
    }
  },
  computed: {
    agua() {
      return this.indicadores.filter((i) => i.tipo === "agua")
    },
    esgoto() {
      return this.indicadores.filter((i) => i.tipo === "esgoto")
    },
    residuos() {
      return this.indicadores.filter((i) => i.tipo === "residuos")
    },
    micro() {
      return this.indicadores.filter((i) => i.tipo === "microdrenagem")
    },
    macro() {
      return this.indicadores.filter((i) => i.tipo === "macrodrenagem")
    }
  },
  mounted() {
    if (this.isNew) return
    this.indicadores = filters.methods.getIndicadores()
    if (this.relatorio.esgoto)
      this.relatorio.esgoto.localidades.forEach((loc) => this.indicadores.push({
        tipo: "esgoto",
        nome: "indic_iqa_" + loc.id,
        descricao: "IQA - Índice de Qualidade das Águas - " + loc.descricao,
        definicao: "O IQA é calculado pelo produto ponderado das qualidades de água correspondentes aos parâmetros - Oxigênio Dissolvido (OD); - Coliformes Fecais; - Potencial Hidrogeniônico (pH); - Demanda Bioquímica de Oxigênio (DBO5); - Nitrogênio Total; - Fósforo Total; - Turbidez; - Sólidos Totais e; - Temperatura;",
        unidade: "0-100%",
        finalidade: "Avaliar a qualidade das águas superficiais do município"
      }))
    Array
        .from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
        .forEach(tooltipNode => new Tooltip(tooltipNode))

    this.relatorio.agua = filters.methods.calcularAgua(this.relatorio.agua)
    this.relatorio.esgoto = filters.methods.calcularEsgoto(this.relatorio.esgoto, this.relatorio.total_domicilios)
    this.relatorio.residuos = filters.methods.calcularResiduos(this.relatorio.residuos, this.relatorio.populacao_total)
    this.relatorio.microdrenagem = filters.methods.calcularMicrodrenagem(this.relatorio.microdrenagem)
    this.relatorio.macrodrenagem = filters.methods.calcularMacrodrenagem(this.relatorio.macrodrenagem)
  },
}
</script>
<style scoped>
.bg-gray {
  background-color: rgba(238, 238, 238, 0.50);
}

.va-table th {
  background-color: rgba(238, 238, 238, 0.90);
}

.va-table tr:hover {
  background-color: rgba(238, 238, 238, 0.90);
}

.va-table th, .va-table td {
  vertical-align: center;
  text-align: center;
}

.fs-7 {
  font-size: 14px
}

.bg-purple {
  background-color: rebeccapurple;
}

.classificacao:not(.selected) {
  filter: brightness(0.50);
}

.mw-50 {
  max-width: 50%;
}
</style>