import api from "./api-config";

const resource = "/relatorios";
const limit = 15

export default {
    get(page = 1) {
        return api.get(`${resource}?page=${page}&limit=${limit}\``);
    },
    getOne(id) {
        return api.get(`${resource}/${id}`)
    },
    create(data) {
        return api.post(`${resource}`, data)
    },
    update(data) {
        return api.put(`${resource}`, data)
    },
    delete(id) {
        return api.delete(`${resource}/${id}`)
    },
    download(id){
        return api.get(`${resource}/${id}/download`)
    },
    dashboard(id) {
        // return api.get(`dashboard/${id}`)
        return api.get(`dashboard`)
    },
}