export default {
    methods: {
        uppercase(val) {
            if (typeof val === "string") {
                return val.toUpperCase();
            }
            return val;
        },
        formatDate(val) {
            if (typeof val === "string") {
                return new Date(val).toLocaleDateString(
                    'pt-br'
                );
            }
        },
        formatTime(val) {
            if (typeof val === "string") {
                return new Date(val).toLocaleTimeString(
                    'pt-br'
                );
            }
        },
        formatDateTime(val) {
            if (typeof val === "string") {
                let date = new Date(val).toLocaleDateString(
                    'pt-br'
                )
                let time = new Date(val).toLocaleTimeString(
                    'pt-br'
                );
                return date + " " + time
            }
        },
        cnpjMask(val) {
            if (val && val.length === 14)
                return val.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            return val
        },
        indicador(number, decimals = 2) {
            if (isNaN(number))
                return "As informações necessárias para o cálculo não estão preenchidas"

            return typeof number === 'number' ? number.toFixed(decimals).toString().replace(".", ",") + "%" : ''
        },
        percent(x, total, percent = null) {
            if (percent)
                return (total * percent) / 100
            return (x * 100)/total
        },

        calcularAgua(agua) {
            const era = agua.total_economias_ligacoes
            const dda = agua.total_domicilios_area_atendimento - agua.total_domicilios_com_atendimento
            const dt = agua.total_domicilios_area_atendimento
            //Percentual de domicilios urbanos fora da area de atendimento
            const pdfa = this.percent(agua.domicilios_urbanos_fora_da_area, agua.total_domicilios_area_atendimento)
            //Percentual de domicilios rurais dentro da area de atendimento
            const pdda = this.percent(agua.domicilios_rurais_dentro_da_area, agua.total_domicilios_area_atendimento)
            agua.indic_ica = ( ((era+dda)*100) / (dt*(100-pdfa+pdda)) ) * 100

            const ve = agua.volume_entregue
            const vs = agua.volume_uso_social_operacional
            const vc = agua.volume_consumo
            const laa = era
            agua.indic_icp = (((ve-vs)-vc) / laa) * 100

            const qp = agua.vazao_produzida
            const capeta = agua.capacidade_eta
            agua.indic_iua = (qp*100) / capeta

            agua.indic_iqa = this.iqa_agua(agua)

            return agua
        },
        calcularEsgoto(esgoto, total_domicilios) {
            const ere = esgoto.numero_economias
            const dde = total_domicilios - esgoto.total_domicilios_com_atendimento
            const dt = esgoto.total_domicilios_area_atendimento
            const pdfe = this.percent(esgoto.domicilios_urbanos_fora_da_area, esgoto.total_domicilios_area_atendimento)
            const pdde = this.percent(esgoto.domicilios_rurais_dentro_da_area, esgoto.total_domicilios_area_atendimento)
            esgoto.indic_ice = ( ((ere+dde)*100) / (dt*(100-pdfe+pdde)) )*100

            const eaete = esgoto.economias_ete
            const eae = esgoto.numero_economias
            esgoto.indic_ite = (eaete*100) / eae

            const qt = esgoto.volume_tratado
            const capete = esgoto.capacicade_ete
            esgoto.indic_iue = (qt*100) / capete

            esgoto.localidades.forEach((e) => {
                esgoto['indic_iqa_' + e.id] = this.iqa_localidades(e)
                // e.indic_iqa = this.iqa_localidades(e)
            })

            return esgoto
        },
        calcularResiduos(residuos, populacao_total) {
            const vmAtual = this.percent((residuos.varricao_extensao_publico + residuos.varricao_extensao_privado), residuos.extensao_vias_publicas)
            const vmMin = this.percent(null, residuos.extensao_vias_publicas, 10)
            const vmMax = residuos.extensao_vias_publicas
            residuos.indic_ivm = (100 * (vmAtual - vmMin))/ (vmMax - vmMin)

            const dut = residuos.total_domicilios
            const duc = residuos.domicilios_atendidos
            const dcr = (duc/dut)*100
            const dcrmin = 0
            const dcrmax = populacao_total < 20000 ? 80 : (populacao_total < 10000 ? 90 : 95)
            residuos.indic_icr = (100 * (dcr - dcrmin)) / (dcrmax - dcrmin)

            const csMin = 0
            const csMax = residuos.coleta_seletiva_domicilios_atendidos
            const csAtual = this.percent(residuos.coleta_seletiva_domicilios_atendidos, residuos.total_domicilios)
            residuos.indic_ics = (100 * (csAtual - csMin)) / (csMax - csMin)

            const rrmin = 0
            const rrmax = this.percent(null, residuos.qtd_residuos_coletados_total, 60)
            const rratual = this.percent(residuos.qtd_residuos_coletados_reaproveitados, residuos.qtd_residuos_coletados_total)
            residuos.indic_irrv = (100*(rratual - rrmin)) / (rrmax - rrmin)

            // residuos.indic_iqr = residuos.populacao_total / residuos.total_empregados

            const n = residuos.previsao_vida_aterro //TODO Tempo em que o sistema ficará saturado, usar o campo previsão de vida do aterro?
            const nmin = 0
            const nmax = populacao_total < 20000 ? 1 : (populacao_total < 50000 ? 2 : (populacao_total < 200000 ? 3 : 5))
            residuos.indic_isr = (100 * (n - nmin)) / (nmax - nmin)

            const rimin = 0
            const rimax = this.percent(null, residuos.residuos_solidos_inertes_gerados, 60)
            const riatual = this.percent(residuos.residuos_solidos_inertes_gerados, residuos.residuos_solidos_inertes_reaproveitados)
            residuos.indic_iri = (100*(riatual - rimin)) / (rimax - rimin)

            const iqi = 0 //TODO Tabela de destinação de Inertes...
            residuos.indic_idi = 10 * iqi

            const iqs = 0 //TODO Tabela de manejo de resíduos de serviços de saúde...
            residuos.indic_ids = 10 * iqs

            // residuos.indic_receita_per_capita = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_prod_media_coleta = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_massa_per_capita = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_custo = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_massa_rcd = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_taxa_recuperacao = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_massa_recuperada = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_massa_rss = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_custo_unitario = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_prod_media_varredores = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_taxa_varredores = residuos.populacao_total / residuos.total_empregados
            // residuos.indic_extensao_varrida = residuos.populacao_total / residuos.total_empregados

            residuos.indic_geral = (
                (residuos.indic_ivm * 1) +
                (residuos.indic_icr * 1.5) +
                (residuos.indic_ics * 1) +
                (residuos.indic_irrv * 1) +
                (residuos.indic_iqr * 2) +
                (residuos.indic_isr * 1) +
                (residuos.indic_iri * 0.5) +
                (residuos.indic_idi * 0.5) +
                (residuos.indic_ids * 1.5)
            ) / 10
            return residuos
        },
        calcularMicrodrenagem(microdrenagem) {
            microdrenagem.indic_geral = 0

            //Institucionalização
            microdrenagem.indic_geral += microdrenagem.existe_padrao ? 0.5 : 0
            microdrenagem.indic_geral += microdrenagem.servico_analise ? 0.5 : 0
            microdrenagem.indic_geral += microdrenagem.estrutura_inspecao ? 0.5 : 0
            microdrenagem.indic_geral += microdrenagem.monitoramento_chuva ? 0.5 : 0
            microdrenagem.indic_geral += microdrenagem.registro_incidentes ? 0.5 : 0
            //Cobertura
            microdrenagem.indic_geral += 2.5*(microdrenagem.extensao_ruas/microdrenagem.extensao_ruas_urbanas)
            //Eficiência
            microdrenagem.indic_geral += 2.5*(1-(parseInt(microdrenagem.qtd_dias_incidentes)/parseInt(microdrenagem.qtd_dias_chuva_ano)))
            //Gestão
            microdrenagem.indic_geral += 1.5*(1-(parseInt(microdrenagem.qtd_bocas_lobo_limpas)/parseInt(microdrenagem.qtd_total_bocas_lobo)))
            microdrenagem.indic_geral += (1-microdrenagem.total_gasto/microdrenagem.total_alocado)

            return microdrenagem
        },
        calcularMacrodrenagem(macrodrenagem) {
            macrodrenagem.indic_geral = 0

            //Institucionalização
            macrodrenagem.indic_geral += macrodrenagem.existe_plano ? 0.5 : 0
            macrodrenagem.indic_geral += macrodrenagem.existe_plano_urbano ? 0.5 : 0
            macrodrenagem.indic_geral += macrodrenagem.legislacao_especifica ? 0.5 : 0
            macrodrenagem.indic_geral += macrodrenagem.monitoramento_cursos ? 0.5 : 0
            macrodrenagem.indic_geral += macrodrenagem.registro_incidentes ? 0.5 : 0
            //Cobertura
            macrodrenagem.indic_geral += 2.5*(macrodrenagem.registro_intervencao/macrodrenagem.extensao_rede)
            //Eficiência
            macrodrenagem.indic_geral += 2.5*(1-(parseInt(macrodrenagem.qtd_dias_incidentes)/parseInt(macrodrenagem.qtd_dias_chuva_ano)))
            //Gestão
            macrodrenagem.indic_geral += (1-macrodrenagem.total_aplicado/macrodrenagem.total_alocado)

            return macrodrenagem
        },

        //ABAIXO, CÁLCULOS DOS PARÂMETROS DO IQA
        iqa_agua (agua) {
            const vm = this
            const meta_qualidade_agua = {
                cor : {
                    minimo: 0,
                    maximo: 15
                },
                turbidez : {
                    minimo: 0,
                    maximo: 5
                },
                cloro : {
                    minimo: 0.2,
                    maximo: 5
                },
                ph : {
                    minimo: 6,
                    maximo: 9.5
                },
                fluoretos : {
                    minimo: 0.6,
                    maximo: 0.9
                }
            }
            const peso_media = {
                cor : 0.2,
                turbidez: 0.3,
                ph: 0.05,
                cloro: 0.35,
                fluoretos: 0.1
            }

            let naos = agua.meses.filter(m => !m.bacteriologica).length
            let G1 = (naos / 12) * 100
            let G2 = 0 //G2 é a soma dos G2 de cor, turbidez etc..

            // ======= G2 =========
            function g2(campo) {
                let media = 0
                let desvio = 0
                let z_min = 0
                let z_max, percentual

                agua.meses.forEach((mes) => {
                    media += mes[campo] || 0
                })
                media = media/12
                agua.meses.forEach((mes) => {
                    desvio += Math.pow(mes[campo] - media, 2)
                })
                desvio = Math.sqrt(desvio) / (12 - 1)
                z_max = (meta_qualidade_agua[campo].maximo - media) / desvio
                percentual = vm.getPercentualTabela(z_max)

                if (campo === "cloro" || campo === "ph" || campo === "fluoretos") {
                    z_min = (meta_qualidade_agua[campo].minimo - media) / desvio
                    percentual += vm.getPercentualTabela(z_min)
                }
                let g2 = percentual * peso_media[campo];
                console.log(campo.toUpperCase(), "| Média: " + media, "Desvio padrão: " + desvio, "Z max: " + z_max, "Z min: " + z_min, "Percentual tabela: " + percentual, "G2: " + g2)
                return g2
            }

            let g2_cor = g2("cor")
            let g2_turbidez = g2("turbidez")
            let g2_cloro = g2("cloro")
            let g2_ph = g2("ph")
            let g2_fluoretos = g2("fluoretos")

            G2 = g2_turbidez + g2_cloro + g2_fluoretos + g2_ph + g2_cor;
            const iqa = this.format(G1 + G2 / 2)

            console.log("IQA DA ÁGUA: ", iqa)
            return iqa
        },

        iqa_localidades (loc) {
            const p1 = this.format(this.coliformes(loc.coliformes) ** 0.15)
            const p2 = this.format(this.pH(loc.ph) ** 0.12)
            const p3 = this.format(this.dbo(loc.demanda_bioquimica) ** 0.10)
            const p4 = this.format(this.nitrogenio(loc.nitrogenio) ** 0.10)
            const p5 = this.format(this.fosforo(loc.fosforo) ** 0.10)
            const p6 = this.format(94 ** 0.10) //Diferença de temperatura é fixo em 94!
            const p7 = this.format(this.turbidez(loc.turbidez) ** 0.08)
            const p8 = this.format(this.residuos_totais(loc.residuos) ** 0.08)
            const p9 = this.format(this.oxigenio(loc.altitude, loc.temperatura, loc.oxigenio) ** 0.17)

            const iqa = this.format(p1 * p2 * p3 * p4 * p5 * p6 * p7 * p8 * p9)

            console.log("---------------")
            console.log("Valores dos parâmetros de IQA - " + loc.descricao)
            console.log("Coliformes", p1, "pH", p2, "DBO", p3, "Nitrogenio", p4, "Fosforo", p5, "Diff Temp", p6, "Turbidez", p7, "Residuos", p8, "Oxigenio", p9)
            console.log("IQA = " + iqa)

            return iqa
        },

        format (number) {
            return number ? parseFloat((number).toFixed(2)) : number
        },

        coliformes(x) {
            let res
            let col = Math.log10(x)
            if (col >= 0 && col <= 1) {
                res = 100 - 33 * col
            } else if (col > 1 && col <= 5) {
                res = 100 - 37.2 * col + 3.06743 * (col ** 2)
            } else if (col > 5 && col <= 30) {
                res = 3
            }
            return res
        },

        pH(x) {
            let res
            if (x > 0 && x <= 2) {
                res = 2
            } else if (x > 2 && x <= 4) {
                res = 13.6 - 10.6 * x + 2.4364 * (x ** 2)
            } else if (x > 4 && x <= 6.2) {
                res = 155.5 - 77.36 * x + 10.2481 * (x ** 2)
            } else if (x > 6.2 && x <= 7) {
                res = -657.2 + 197.38 * x - 12.9167 * (x ** 2)
            } else if (x > 7 && x <= 8) {
                res = -427.8 + 142.05 * x - 9.695 * (x ** 2)
            } else if (x > 8 && x <= 8.5) {
                res = 216 - 16 * x
            } else if (x > 8.5 && x <= 9) {
                res = 1415823 * Math.exp(-1.1507 * x)
            } else if (x > 9 && x <= 10) {
                res = 228 - 27 * x
            } else if (x > 10 && x <= 12) {
                res = 633 - 106.5 * x + 4.5 * (x ** 2)
            } else if (x > 12 && x <= 14) {
                res = 3
            }
            return res
        },

        dbo (x) {
            let res
            if (x > 0 && x <= 5) {
                res = 99.96 * Math.exp(-0.1232728 * x)
            } else if (x > 5 && x <= 15) {
                res = 104.67 - 31.5463 * Math.log10(x)
            } else if (x > 15 && x <= 30) {
                res = 4394.91 * (x ** -1.99809)
            } else if (x > 30 && x <= 100000) {
                res = 2
            }
            return res
        },

        nitrogenio (x) {
            let res
            if (x > 0 && x <= 10) {
                res = 100 - 8.169 * x + 0.3059 * (x ** 2)
            } else if (x > 10 && x <= 60) {
                res = 101.9 - 23.1023 * Math.log10(x)
            } else if (x > 60 && x <= 100) {
                res = 159.3148 * Math.exp(x*(-0.0512842))
            } else if (x > 100 && x <= 100000) {
                res = 1
            }
            return res
        },

        fosforo (x) {
            let res
            x = x * 3.066 //transformar de mgP/L > mgPO4/L
            if (x > 0 && x <= 1) {
                res = 99 * Math.exp(-0.91629 * x)
            } else if (x > 1 && x <= 5) {
                res = 57.6 - 20.178 * x + 2.1326 * (x ** 2)
            } else if (x > 5 && x <= 10) {
                res = 19.8 * Math.exp(-0.13544 * x)
            } else if (x > 10 && x <= 10000) {
                res = 5
            }
            return res
        },

        turbidez (x) {
            let res
            if (x > 0 && x <= 25) {
                res = 100.17 - 2.67 * x + 0.03775 * (x ** 2)
            } else if (x > 25 && x <= 100) {
                res = 84.76 * Math.exp(-0.016206 * x)
            } else if (x > 100 && x <= 10000) {
                res = 5
            }
            return res
        },

        residuos_totais (x) {
            let res
            if (x > 0 && x <= 150) {
                res = 79.75 + 0.166 * x - 0.001088 * (x ** 2)
            } else if (x > 150 && x <= 500) {
                res = 101.67 - 0.13917 * x
            } else if (x > 500 && x <= 100000) {
                res = 32
            }
            return res
        },

        oxigenio (altitude, temp, od) {
            let res
            let conc_saturacao = (14.62 - 0.3898 * temp + 0.006969 * (temp ** 2) - 0.00005896 * (temp ** 3)) * (1 - 0.0000228675 * altitude) ** 5.167
            let porc_saturacao = od * 100 / conc_saturacao

            if (porc_saturacao > 0 && porc_saturacao <= 50) {
                res = 3 + 0.34 * porc_saturacao + 0.008095 * (porc_saturacao ** 2) + 1.35252 * 0.00001 * (porc_saturacao ** 3)
            } else if (porc_saturacao > 50 && porc_saturacao <= 85) {
                res = 3 - 1.166 * porc_saturacao + 0.058 * (porc_saturacao ** 2) - 3.803435 * 0.0001 * (porc_saturacao ** 3)
            } else if (porc_saturacao > 85 && porc_saturacao <= 100) {
                res = 3 + 3.7745 * (porc_saturacao ** 0.704889)
            } else if (porc_saturacao > 100 && porc_saturacao <= 140) {
                res = 3 + 2.9 * porc_saturacao - 0.02496 * (porc_saturacao ** 2) + 5.60919 * 0.00001 * (porc_saturacao ** 3)
            } else if (porc_saturacao > 140 && porc_saturacao <= 1000) {
                res = 3+47
            }
            return res
        },

        getPercentualTabela(z) {
            z = Math.abs(this.format(z))
            if (z === 0) return 0
            if (z > 3.09) return 49.9
            const tabela = [
                {'z': 0.01, 'value': 0.4},
                {'z': 0.02, 'value': 0.8},
                {'z': 0.03, 'value': 1.2},
                {'z': 0.04, 'value': 1.6},
                {'z': 0.05, 'value': 1.99},
                {'z': 0.06, 'value': 23.9},
                {'z': 0.07, 'value': 27.9},
                {'z': 0.08, 'value': 3.19},
                {'z': 0.09, 'value': 3.59},
                {'z': 0.1,  'value':  3.98},
                {'z': 0.11, 'value': 4.38},
                {'z': 0.12, 'value': 4.78},
                {'z': 0.13, 'value': 5.17},
                {'z': 0.14, 'value': 5.57},
                {'z': 0.15, 'value': 5.96},
                {'z': 0.16, 'value': 6.36},
                {'z': 0.17, 'value': 6.75},
                {'z': 0.18, 'value': 7.14},
                {'z': 0.19, 'value': 7.53},
                {'z': 0.2,  'value': 7.93},
                {'z': 0.21, 'value': 8.32},
                {'z': 0.22, 'value': 8.71},
                {'z': 0.23, 'value': 9.1},
                {'z': 0.24, 'value': 9.48},
                {'z': 0.25, 'value': 9.78},
                {'z': 0.26, 'value': 10.26},
                {'z': 0.27, 'value': 10.64},
                {'z': 0.28, 'value': 11.03},
                {'z': 0.29, 'value': 11.41},
                {'z': 0.3,  'value': 11.79},
                {'z': 0.31, 'value': 12.17},
                {'z': 0.32, 'value': 12.55},
                {'z': 0.33, 'value': 12.93},
                {'z': 0.34, 'value': 13.31},
                {'z': 0.35, 'value': 13.68},
                {'z': 0.36, 'value': 14.06},
                {'z': 0.37, 'value': 14.43},
                {'z': 0.38, 'value': 14.8},
                {'z': 0.39, 'value': 15.17},
                {'z': 0.4,  'value': 15.54},
                {'z': 0.41, 'value': 15.91},
                {'z': 0.42, 'value': 16.28},
                {'z': 0.43, 'value': 16.64},
                {'z': 0.44, 'value': 17},
                {'z': 0.45, 'value': 17.36},
                {'z': 0.46, 'value': 17.72},
                {'z': 0.47, 'value': 18.08},
                {'z': 0.48, 'value': 14.44},
                {'z': 0.49, 'value': 18.79},
                {'z': 0.5,  'value': 19.15},
                {'z': 0.51, 'value': 19.5},
                {'z': 0.52, 'value': 19.85},
                {'z': 0.53, 'value': 20.19},
                {'z': 0.54, 'value': 20.54},
                {'z': 0.55, 'value': 20.88},
                {'z': 0.56, 'value': 21.23},
                {'z': 0.57, 'value': 21.57},
                {'z': 0.58, 'value': 21.9},
                {'z': 0.59, 'value': 22.24},
                {'z': 0.6,  'value': 22.57},
                {'z': 0.61, 'value': 22.91},
                {'z': 0.62, 'value': 23.24},
                {'z': 0.63, 'value': 23.57},
                {'z': 0.64, 'value': 23.89},
                {'z': 0.65, 'value': 24.22},
                {'z': 0.66, 'value': 24.54},
                {'z': 0.67, 'value': 24.86},
                {'z': 0.68, 'value': 25.17},
                {'z': 0.69, 'value': 25.49},
                {'z': 0.7,  'value':  25.8},
                {'z': 0.71, 'value': 26.11},
                {'z': 0.72, 'value': 26.42},
                {'z': 0.73, 'value': 26.73},
                {'z': 0.74, 'value': 27.03},
                {'z': 0.75, 'value': 27.34},
                {'z': 0.76, 'value': 27.64},
                {'z': 0.77, 'value': 27.94},
                {'z': 0.78, 'value': 28.23},
                {'z': 0.79, 'value': 28.52},
                {'z': 0.8,  'value': 28.81},
                {'z': 0.81, 'value': 29.1},
                {'z': 0.82, 'value': 29.39},
                {'z': 0.83, 'value': 29.67},
                {'z': 0.84, 'value': 99.95},
                {'z': 0.85, 'value': 30.23},
                {'z': 0.86, 'value': 30.51},
                {'z': 0.87, 'value': 30.78},
                {'z': 0.88, 'value': 31.06},
                {'z': 0.89, 'value': 31.33},
                {'z': 0.9,  'value': 31.59},
                {'z': 0.91, 'value': 31.86},
                {'z': 0.92, 'value': 32.12},
                {'z': 0.93, 'value': 33.38},
                {'z': 0.94, 'value': 32.64},
                {'z': 0.95, 'value': 32.89},
                {'z': 0.96, 'value': 33.15},
                {'z': 0.97, 'value': 33.4},
                {'z': 0.98, 'value': 33.65},
                {'z': 0.99, 'value': 33.89},
                {'z': 1,    'value': 34.13},
                {'z': 1.01, 'value': 34.38},
                {'z': 1.02, 'value': 34.61},
                {'z': 1.03, 'value': 34.85},
                {'z': 1.04, 'value': 35.08},
                {'z': 1.05, 'value': 35.31},
                {'z': 1.06, 'value': 35.54},
                {'z': 1.07, 'value': 35.77},
                {'z': 1.08, 'value': 35.99},
                {'z': 1.09, 'value': 36.21},
                {'z': 1.1,  'value': 36.43},
                {'z': 1.11, 'value': 36.65},
                {'z': 1.12, 'value': 36.86},
                {'z': 1.13, 'value': 37.08},
                {'z': 1.14, 'value': 37.29},
                {'z': 1.15, 'value': 37.49},
                {'z': 1.16, 'value': 37.7},
                {'z': 1.17, 'value': 37.9},
                {'z': 1.18, 'value': 38.1},
                {'z': 1.19, 'value': 38.3},
                {'z': 1.2,  'value': 38.49},
                {'z': 1.21, 'value': 38.69},
                {'z': 1.22, 'value': 38.88},
                {'z': 1.23, 'value': 39.07},
                {'z': 1.24, 'value': 39.25},
                {'z': 1.25, 'value': 39.44},
                {'z': 1.26, 'value': 39.62},
                {'z': 1.27, 'value': 39.8},
                {'z': 1.28, 'value': 99.7},
                {'z': 1.29, 'value': 40.15},
                {'z': 1.3,  'value': 40.32},
                {'z': 1.31, 'value': 40.49},
                {'z': 1.32, 'value': 40.66},
                {'z': 1.33, 'value': 40.82},
                {'z': 1.34, 'value': 40.99},
                {'z': 1.35, 'value': 41.15},
                {'z': 1.36, 'value': 41.31},
                {'z': 1.37, 'value': 41.47},
                {'z': 1.38, 'value': 41.62},
                {'z': 1.39, 'value': 41.77},
                {'z': 1.4,  'value': 41.92},
                {'z': 1.41, 'value': 42.07},
                {'z': 1.42, 'value': 42.22},
                {'z': 1.43, 'value': 42.36},
                {'z': 1.44, 'value': 42.51},
                {'z': 1.45, 'value': 42.65},
                {'z': 1.46, 'value': 42.79},
                {'z': 1.47, 'value': 42.92},
                {'z': 1.48, 'value': 43.06},
                {'z': 1.49, 'value': 43.19},
                {'z': 1.5,  'value': 43.32},
                {'z': 1.51, 'value': 43.45},
                {'z': 1.52, 'value': 43.57},
                {'z': 1.53, 'value': 43.7},
                {'z': 1.54, 'value': 43.82},
                {'z': 1.55, 'value': 43.94},
                {'z': 1.56, 'value': 44.06},
                {'z': 1.57, 'value': 44.18},
                {'z': 1.58, 'value': 44.29},
                {'z': 1.59, 'value': 44.41},
                {'z': 1.6,  'value': 44.52},
                {'z': 1.61, 'value': 44.63},
                {'z': 1.62, 'value': 44.74},
                {'z': 1.63, 'value': 44.84},
                {'z': 1.64, 'value': 44.95},
                {'z': 1.65, 'value': 45.05},
                {'z': 1.66, 'value': 45.15},
                {'z': 1.67, 'value': 45.25},
                {'z': 1.68, 'value': 45.35},
                {'z': 1.69, 'value': 45.45},
                {'z': 1.7,  'value': 45.54},
                {'z': 1.71, 'value': 45.64},
                {'z': 1.72, 'value': 45.73},
                {'z': 1.73, 'value': 45.82},
                {'z': 1.74, 'value': 45.91},
                {'z': 1.75, 'value': 45.99},
                {'z': 1.76, 'value': 46.08},
                {'z': 1.77, 'value': 46.16},
                {'z': 1.78, 'value': 46.25},
                {'z': 1.79, 'value': 46.33},
                {'z': 1.8,  'value': 46.41},
                {'z': 1.81, 'value': 46.49},
                {'z': 1.82, 'value': 46.56},
                {'z': 1.83, 'value': 46.64},
                {'z': 1.84, 'value': 46.71},
                {'z': 1.85, 'value': 46.78},
                {'z': 1.86, 'value': 46.86},
                {'z': 1.87, 'value': 46.93},
                {'z': 1.88, 'value': 46.99},
                {'z': 1.89, 'value': 47.06},
                {'z': 1.9,  'value': 47.13},
                {'z': 1.91, 'value': 47.19},
                {'z': 1.92, 'value': 47.26},
                {'z': 1.93, 'value': 47.32},
                {'z': 1.94, 'value': 47.38},
                {'z': 1.95, 'value': 47.44},
                {'z': 1.96, 'value': 47.5},
                {'z': 1.97, 'value': 47.56},
                {'z': 1.98, 'value': 47.61},
                {'z': 1.99, 'value': 47.67},
                {'z': 2,    'value': 47.72},
                {'z': 2.01, 'value': 47.78},
                {'z': 2.02, 'value': 47.83},
                {'z': 2.03, 'value': 47.88},
                {'z': 2.04, 'value': 47.93},
                {'z': 2.05, 'value': 47.98},
                {'z': 2.06, 'value': 48.03},
                {'z': 2.07, 'value': 48.08},
                {'z': 2.08, 'value': 48.12},
                {'z': 2.09, 'value': 48.17},
                {'z': 2.1,  'value': 48.21},
                {'z': 2.11, 'value': 48.26},
                {'z': 2.12, 'value': 48.3},
                {'z': 2.13, 'value': 49.34},
                {'z': 2.14, 'value': 48.38},
                {'z': 2.15, 'value': 48.42},
                {'z': 2.16, 'value': 48.46},
                {'z': 2.17, 'value': 48.5},
                {'z': 2.18, 'value': 48.54},
                {'z': 2.19, 'value': 48.57},
                {'z': 2.2,  'value': 48.61},
                {'z': 2.21, 'value': 48.64},
                {'z': 2.22, 'value': 48.68},
                {'z': 2.23, 'value': 48.71},
                {'z': 2.24, 'value': 48.75},
                {'z': 2.25, 'value': 48.78},
                {'z': 2.26, 'value': 48.81},
                {'z': 2.27, 'value': 48.84},
                {'z': 2.28, 'value': 48.87},
                {'z': 2.29, 'value': 48.9},
                {'z': 2.3,  'value': 48.93},
                {'z': 2.31, 'value': 48.96},
                {'z': 2.32, 'value': 48.98},
                {'z': 2.33, 'value': 49.01},
                {'z': 2.34, 'value': 49.04},
                {'z': 2.35, 'value': 49.06},
                {'z': 2.36, 'value': 49.09},
                {'z': 2.37, 'value': 49.11},
                {'z': 2.38, 'value': 49.13},
                {'z': 2.39, 'value': 49.16},
                {'z': 2.4,  'value': 49.18},
                {'z': 2.41, 'value': 49.2},
                {'z': 2.42, 'value': 49.22},
                {'z': 2.43, 'value': 49.25},
                {'z': 2.44, 'value': 49.27},
                {'z': 2.45, 'value': 49.29},
                {'z': 2.46, 'value': 49.31},
                {'z': 2.47, 'value': 49.32},
                {'z': 2.48, 'value': 49.34},
                {'z': 2.49, 'value': 49.36},
                {'z': 2.5,  'value': 49.38},
                {'z': 2.51, 'value': 49.4},
                {'z': 2.52, 'value': 49.41},
                {'z': 2.53, 'value': 49.43},
                {'z': 2.54, 'value': 49.45},
                {'z': 2.55, 'value': 49.46},
                {'z': 2.56, 'value': 49.48},
                {'z': 2.57, 'value': 49.49},
                {'z': 2.58, 'value': 51},
                {'z': 2.59, 'value': 49.52},
                {'z': 2.6,  'value': 49.53},
                {'z': 2.61, 'value': 49.55},
                {'z': 2.62, 'value': 49.56},
                {'z': 2.63, 'value': 49.57},
                {'z': 2.64, 'value': 49.59},
                {'z': 2.65, 'value': 49.6},
                {'z': 2.66, 'value': 49.61},
                {'z': 2.67, 'value': 49.62},
                {'z': 2.68, 'value': 49.63},
                {'z': 2.69, 'value': 49.64},
                {'z': 2.7,  'value': 49.65},
                {'z': 2.71, 'value': 49.66},
                {'z': 2.72, 'value': 49.67},
                {'z': 2.73, 'value': 49.68},
                {'z': 2.74, 'value': 49.69},
                {'z': 2.75, 'value': 49.7},
                {'z': 2.76, 'value': 49.71},
                {'z': 2.77, 'value': 49.72},
                {'z': 2.78, 'value': 49.73},
                {'z': 2.79, 'value': 49.74},
                {'z': 2.8,  'value': 49.74},
                {'z': 2.81, 'value': 49.75},
                {'z': 2.82, 'value': 49.76},
                {'z': 2.83, 'value': 49.77},
                {'z': 2.84, 'value': 49.77},
                {'z': 2.85, 'value': 49.78},
                {'z': 2.86, 'value': 49.79},
                {'z': 2.87, 'value': 49.79},
                {'z': 2.88, 'value': 49.8},
                {'z': 2.89, 'value': 49.81},
                {'z': 2.9,  'value': 49.81},
                {'z': 2.91, 'value': 49.82},
                {'z': 2.92, 'value': 49.82},
                {'z': 2.93, 'value': 49.83},
                {'z': 2.94, 'value': 49.84},
                {'z': 2.95, 'value': 49.84},
                {'z': 2.96, 'value': 49.85},
                {'z': 2.97, 'value': 49.885},
                {'z': 2.98, 'value': 49.86},
                {'z': 2.99, 'value': 49.86},
                {'z': 3,    'value': 49.87},
                {'z': 3.01, 'value': 49.87},
                {'z': 3.02, 'value': 49.87},
                {'z': 3.03, 'value': 49.88},
                {'z': 3.04, 'value': 49.88},
                {'z': 3.05, 'value': 49.89},
                {'z': 3.06, 'value': 49.89},
                {'z': 3.07, 'value': 49.89},
                {'z': 3.08, 'value': 49.9},
                {'z': 3.09, 'value': 49.9}
            ]
            return tabela.find(item => item.z === z).value
        },

        getIndicadores() {
            return [
                {
                    tipo: "agua",
                    nome: "indic_ica",
                    descricao: "ICA - Indicador de Cobertura do Serviço de Água",
                    definicao: "% economias ligadas ao sistema de abastecimento de água",
                    unidade: "%",
                    finalidade: "Quantificar o percentual de economias com disponibilidade de acesso ao sistema de abastecimento de água"
                },
                {
                    tipo: "agua",
                    nome: "indic_icp",
                    descricao: "IPF - Índice de perda de faturamento",
                    definicao: "% de perdas por faturamento",
                    unidade: "%",
                    finalidade: "Avaliar perda de Faturamento"
                },
                {
                    tipo: "agua",
                    nome: "indic_iua",
                    descricao: "IUA - Indicador de Utilização da Infraestrutura de Produção de Água",
                    definicao: "",
                    unidade: "%",
                    finalidade: "Mensurar a capacidade ociosa da estação de tratamento de água"
                },
                {
                    tipo: "agua",
                    nome: "indic_iqa",
                    descricao: "IQA - Índice de Qualidade das Águas",
                    definicao: "Relação entre probabilidade de Parametros, Turbidez, Cloro Residual, Ph, Fluor e Bacteriológica",
                    unidade: "Admensional",
                    finalidade: "Avaliar a Qualidade da água tratada"
                },
                {
                    tipo: "esgoto",
                    nome: "indic_ice",
                    descricao: "ICE - Indicador de Cobertura do Serviço de Esgotos Sanitários",
                    definicao: "% de economias com disponibilidade de acesso ao sistema de esgoto",
                    unidade: "%",
                    finalidade: "Quantificar o percentual de economias com disponibilidade de acesso ao sistema de esgotos sanitários"
                },
                {
                    tipo: "esgoto",
                    nome: "indic_ite",
                    descricao: "ITE - Indicador de Tratamento de Esgotos",
                    definicao: "% de economias ligadas ao sistema de coleta de esgoto",
                    unidade: "%",
                    finalidade: "Quantificar o percentual de economias ligadas à coleta cujos esgotos recebem tratamento"
                },
                {
                    tipo: "esgoto",
                    nome: "indic_iue",
                    descricao: "IUE - Indicador de Utilização da Infraestrutura de Esgotos",
                    definicao: "",
                    unidade: "%",
                    finalidade: "Avalia a capacidade ociosa da Estação de Tratamento de Esgotos"
                },
                {
                    tipo: "esgoto",
                    nome: "indic_iqa",
                    descricao: "IQA - Índice de Qualidade das Águas",
                    definicao: "Relação entre probabilidade de Parametros, Turbidez, Cloro Residual, Ph, Fluor e Bacteriológica",
                    unidade: "Admensional",
                    finalidade: "Avaliar a qualidade da água tratada"
                },
                {
                    tipo: "residuos",
                    nome: "indic_geral",
                    descricao: "Indicador Geral dos Resíduos",
                    definicao: "Indicador Geral do somatório da média de todos os manejos de resíduos do município.",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_ivm",
                    descricao: "IVM – Indicador do Serviço de Varrição das Vias",
                    definicao: "Quantifica as vias urbanas atendidas pelo serviço de varrição, tanto manual quanto mecanizada, onde houver.",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_icr",
                    descricao: "ICR – Indicador do Serviço de Coleta Regular",
                    definicao: "Quantifica os domicílios atendidos por coleta de resíduos sólidos domiciliares",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_ics",
                    descricao: "ICS – Indicador do Serviço de Coleta Seletiva ",
                    definicao: "Quantifica os domicílios atendidos por coleta seletiva de resíduos sólidos recicláveis, também denominados lixo seco.",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_irrv",
                    descricao: "IRR – Indicador do Reaproveitamento dos RSD",
                    definicao: "Traduz o grau de reaproveitamento dos meterias reaproveitáveis presentes nos resíduos sólidos domiciliares e deve sua importância a obrigatoriedade ditada pela nova legislação federal referente à politica Nacional dos Resíduos Sólidos ",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_iqr",
                    descricao: "IQR – Indicador da Destinação Final dos RSD",
                    definicao: "É utilizado pela Cetesb para avaliar as condições dos sistemas de disposição de resíduos sólidos domiciliares. O índice IQR é apurado com base em informações coletadas nas inspeções de cada unidade de disposição final e processadas a partir da aplicação de questionário padronizado.<br/> <b>Segue tabela:</b><br/><table class='table w-100 fs-7'><tr><td>IQR</td><td>Enquadramento</td></tr><tr><td>0,0 à 6,0</td><td>Condições Inadequadas</td></tr><tr><td>6,1 à 8,0</td><td>Condições Controladas</td></tr><tr><td>8,1 à 10,0</td><td>Condições Adequadas</td></tr></table>",
                    unidade: "0-10",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_isr",
                    descricao: "ISR - Indicador de Saturação do Tratamento e Disposição Final dos RSD",
                    definicao: "Demonstra a capacidade restante do local de disposição final dos RSD",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_iri",
                    descricao: "IRI – Indicador do Reaproveitamento dos RSJ",
                    definicao: "Traduz o grau de reaproveitamento dos materiais reaproveitáveis presentes na composição dos resíduos sólidos inertes e, embora também esteja vinculada de certa forma a obrigatoriedade ditada pela nova legislação federal referente à Politica Nacional dos Resíduos Sólidos, não tem a mesma importância do reaproveitamento dos RSD.",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_idi",
                    descricao: "IDI – Indicador da Destinação Final dos RSI",
                    definicao: "Possibilita avaliar as condições dos sistemas de disposição de resíduos sólidos inertes que, embora ofereça menores riscos do que os relativos à destinação dos RSD, se não forem bem operados podem gerar o assoreamento de drenagens e acabarem sendo, em muitos casos, responsáveis por inundações localizadas<br/><b>Valores:</b><br/><table class='table w-100 fs-7'><tr><td>0%</td><td>Sem triagem prévia / sem configuração topográfica / sem drenagem superficial</td></tr><tr><td>20%</td><td>Com triagem prévia / sem configuração topográfica / sem drenagem superficial</td></tr><tr><td>40%</td><td>Com triagem prévia / com configuração topográfica / sem drenagem superficial</td></tr><tr><td>60%</td><td>Com triagem prévia / com configuração topográfica / com drenagemsuperficial</td></tr><tr><td>80%</td><td>Com triagem prévia / sem britagem / com reaproveitamento</td></tr><tr><td>100%</td><td>Com triagemprévia / com britagem / com reaproveitamento</td></tr></table>",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "residuos",
                    nome: "indic_ids",
                    descricao: "IDS – Indicador do Manejo e Destinação dos RSS",
                    definicao: "Traduz as condições do manejo dos resíduos dos serviços de saúde, desde sua forma de estocagem para conviver com baixas frequências de coleta até o transporte, tratamento e disposição final dos rejeitos \n <b>Valores</b>:<br/> <table class='table w-100 fs-7'><tr><td>0%</td><td>Com baixa frequência e  sem estocagem refrigerada / sem transporte adequado / sem tratamento licenciado / sem disposição final adequada dos rejeitos tratados</td></tr><tr><td>20%</td><td>Com baixa frequência e com estocagem refrigerada / sem transporte adequado / sem tratamento licenciado / sem disposição final adequada dos rejeitos tratados</td></tr><tr><td>40%</td><td>Com frequência adequada / sem transporte adequado / sem tratamento licenciado / sem disposição final adequada dos rejeitos tratados</td></tr><tr><td>60%</td><td>Com frequência adequada / com transporte adequado / sem tratamento licenciado / sem disposição final adequada dos rejeitos tratados</td></tr><tr><td>80%</td><td>Com frequência adequada / com transporte adequado / com tratamento licenciado / sem disposição final adequada dos rejeitos tratados</td></tr><tr><td>100%</td><td>Com frequência adequada / com transporte adequado / com tratamento licenciado / com disposição final adequada dos rejeitos tratados.</td></tr></table>",
                    unidade: "%",
                    finalidade: ""
                },
                // {
                //     tipo: "residuos",
                //     nome: "indic_receita_per_capita",
                //     descricao: "Receita arrecadada per capita com taxas ou outras formas de  cobrança pela prestação de serviços de manejo de RSU",
                //     definicao: "Relação entre o valor arrecadado com serviços de manejo de RSU e a pop. Urbana.",
                //     unidade: "R$/habitante/ano",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_prod_media_coleta",
                //     descricao: "Produtividade média dos empregados na coleta de acordo com a massa coletada",
                //     definicao: "Relação entre quantidade total coletada e a quantidade total de envolvidos na coleta no ano",
                //     unidade: "Kg/empregado/dia",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_massa_per_capita",
                //     descricao: "Massa coletada per capita de acordo com a pop. Urbana",
                //     definicao: "Relação entre a quantidade total coletada a pop. Urbana",
                //     unidade: "Kg/habitante/dia",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_custo",
                //     descricao: "Custo Unitário Médio do serviço de coleta (Rdo+Rpu)",
                //     definicao: "Relação entre despesa total da prefeitura com serviços de coleta e quantidade coletada por (Prefeitura + emp. Contrat.= coop./assoc. catadores)",
                //     unidade: "R$/tonelada",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_massa_rcd",
                //     descricao: "Massa de RCD per capita de acordo com a pop. Urbana",
                //     definicao: "Relação entre RCD e a pop. Urbana",
                //     unidade: "Kg/habitante/dia",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_taxa_recuperacao",
                //     descricao: "Taxa de recuperação de matérias recicláveis",
                //     definicao: "Relação entre quantidade total de matérias recicláveis pela quantidade total de Rdo + Rpu coletada",
                //     unidade: "%",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_massa_recuperada",
                //     descricao: "Massa Recuperada per capita de matérias recicláveis",
                //     definicao: "Relação entre quantidade total de materiais recicláveis recuperados pela pop. Urbana",
                //     unidade: "Kg/habitante/ano",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_massa_rss",
                //     descricao: "Massa de RSS Coletada per capita",
                //     definicao: "Relação entre a quantidade total de RSS coletada e a pop. Urbana",
                //     unidade: "Kg/habitantes/ano",
                //     finalidade: "",
                //     subtitulo: "INDICADORES DE RESÍDUOS SÓLIDOS DE SERVIÇOS DE SAÚDE"
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_custo_unitario",
                //     descricao: "Custo Unitário médio do serviço de varrição",
                //     definicao: "Relação entre as despesas totais da prefeitura com serviço de varrição e a extensão total de sarjeta varrida",
                //     unidade: "R$/km",
                //     finalidade: "",
                //     subtitulo: "INDICADORES DE RESÍDUOS SOBRE SERVIÇOS DE VARRIÇÃO"
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_prod_media_varredores",
                //     descricao: "Produtividade média dos varredores",
                //     definicao: "Relação entre a extensão total de sarjeta varrida e a quantidade total de varredores",
                //     unidade: "Km/empregado/dia",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_taxa_varredores",
                //     descricao: "Taxa de varredores de acordo com  pop. Urbana",
                //     definicao: "Relação entre a quantidade total de varredores e a pop. Urbana atendida",
                //     unidade: "Empregado/1000 habitantes",
                //     finalidade: ""
                // },
                // {
                //     tipo: "residuos",
                //     nome: "indic_extensao_varrida",
                //     descricao: "Extensão total anual varrida per capita",
                //     definicao: "Relação entre extensão total de sarjeta varrida no ano e a pop. Urbana",
                //     unidade: "Km/habitante/ano",
                //     finalidade: ""
                // },
                {
                    tipo: "microdrenagem",
                    nome: "indic_geral",
                    descricao: "Cálculo do Indicador de Drenagem Urbana - Microdenagem",
                    definicao: "",
                    unidade: "%",
                    finalidade: ""
                },
                {
                    tipo: "macrodrenagem",
                    nome: "indic_geral",
                    descricao: "Cálculo do Indicador de Drenagem Urbana - Macrodenagem",
                    definicao: "",
                    unidade: "%",
                    finalidade: ""
                }
            ]
        }
    },
};