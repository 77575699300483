import api from "./api-config";

const resource = "/localidade";

export default {
    addImg(data, idlocalidade) {
        console.log('data localidade service: ', data);
        return api.post(`${resource}-img/${idlocalidade}`, data)
    },
    deleteImg(idlocalidade) {
        return api.delete(`${resource}-img/${idlocalidade}`)
    }
}