import axios from 'axios'
import env from '../../services/env'
import VueJwtDecode from 'vue-jwt-decode'

export const auth = {
    namespaced: true,
    state: {
        token: sessionStorage.getItem('user-token') || '',
        status: '',
        role: ''
    },
    getters: {
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        getRole: state => {
            if (!state.role) {
                let decoded = VueJwtDecode.decode(state.token);
                state.role = decoded.role
            }
            return state.role
        },
    },
    actions: {
        doLogin: ({commit, dispatch}, user) => {
            return new Promise((resolve, reject) => {
                commit("logging")
                axios({url: env.BASE_URL + '/login', data: user, method: 'POST'})
                    .then(resp => {
                        //Seta token no session storage
                        const token = resp.data.token
                        sessionStorage.setItem('user-token', token)
                        sessionStorage.setItem('username', resp.data.user.nome)

                        //Decodifica token para pegar role do usuario e setar na variável do Vuex
                        let decoded = VueJwtDecode.decode(token)

                        //Token no authorization para backend aceitar requests
                        axios.defaults.headers.common['Authorization'] = token

                        commit("loggedIn", {
                            'token': token,
                            'role': decoded.role
                        })

                        resolve(resp)
                    })
                    .catch(err => {
                        commit("loginError", err)
                        sessionStorage.removeItem('user-token')
                        reject(err)
                    })
            })
        },
        doLogout: ({commit, dispatch}) => {
            return new Promise((resolve, reject) => {
                commit("logout")
                sessionStorage.removeItem('user-token')
                // remove the axios default header
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },
    mutations: {
        logging: (state) => {
            state.status = 'loading'
        },
        loggedIn: (state, params) => {
            state.status = 'success'
            state.token = params.token
            state.role = params.role
        },
        loginError: (state) => {
            state.status = 'error'
        },
        logout: (state) => {
            state.status = ''
            state.token = null
            state.role = ''
        }
    }
}