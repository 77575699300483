import {createRouter, createWebHistory} from 'vue-router'
import store from '../store' // your vuex store
import { Role } from '../services/role'

import Login from '../views/Login.vue'
import AdminLayout from '../views/Admin/AdminLayout.vue'
import Dashboard from '../views/Admin/Dashboard.vue'
import Perfil from '../views/Admin/Perfil.vue'
import Usuarios from '../views/Admin/Usuarios.vue'
import NotFound from '../views/Admin/NotFound.vue'

import Relatorio from '../views/Admin/Relatorio/Form.vue'
import Metas from '../views/Admin/Metas/Form.vue'
import VisualizarRelatorios from '../views/Admin/Relatorio/Visualizar.vue'
import DownloadPDF from "../components/DownloadPDF";

const routes = [
    {
        path: '/login',
        alias: '/',
        name: 'Login',
        component: Login
    },
    {
        component: AdminLayout,
        meta: {
            requiresAuth: true,
            authorize: [Role.User, Role.Admin]
        },
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard
            },
            {
                path: '/perfil',
                name: 'Perfil',
                component: Perfil
            },
            {
                path: '/relatorios/novo',
                name: 'NovoRelatorio',
                component: Relatorio
            },
            {
                path: '/relatorios/editar/:_id',
                name: 'EditarRelatorio',
                props: true,
                component: Relatorio
            },
            {
                path: '/relatorios/visualizar',
                name: 'VisualizarRelatorios',
                component: VisualizarRelatorios
            },
            {
                path: '/metas/:_id',
                name: 'Metas',
                props: true,
                component: Metas
            },
            {
                path: '/:catchAll(.*)',
                name: 'NotFound',
                component: NotFound
            },
            {
                path: '/usuarios',
                name: 'Usuarios',
                component: Usuarios,
                meta: {
                    authorize: [Role.Admin]
                }
            }
        ]
    },
    {
        path: '/relatorios/download/:_id',
        name: 'DownloadRelatorio',
        props: true,
        meta: {
            requiresAuth: true,
            authorize: [Role.User, Role.Admin]
        },
        component: DownloadPDF
    }
]

const verifyAuth = (to, from, next) => {
    const isAuthenticated = store.getters["auth/isAuthenticated"]
    const { authorize } = to.meta;

    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            return next({
                path: '/login',
                query: { nextUrl: to.fullPath }
            })
        } else {
            if (authorize) {
                const role = store.getters["auth/getRole"]
                if (authorize.length && !authorize.includes(role)) {
                    //Role not authorized, redirect to home
                    return next({
                        path: '/dashboard'
                    })
                }
            }
            if (isAuthenticated && to.path === 'Login') {
                return next('/dashboard')
            }
            next()
        }
    } else {
        if (isAuthenticated && to.name === 'Login') {
            return next('/dashboard')
        } else {
            return next()
        }
    }
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
router.beforeEach(verifyAuth)

export default router
