<template>
  <div class="flex">
    <va-card :bordered="false">
      <va-card-title>
        <va-breadcrumbs color="black">
          <va-breadcrumbs-item>
            <router-link :to="{name:'Dashboard'}" class="text-decoration-none text-dark">Painel Inicial</router-link>
          </va-breadcrumbs-item>
          <va-breadcrumbs-item label="Perfil"/>
        </va-breadcrumbs>
      </va-card-title>
      <va-card-content>
        <div class="d-flex flex-column mb-3">
          <div class="text-lead fs-5 fw-bold">Perfil</div>
          <va-form ref="formUsuario" v-on:keyup.enter="salvar()" enctype="multipart/form-data">
            <div class="mt-3">
              <span v-show="!image" class="text-secondary small">
                Clique para selecionar uma imagem ou arraste e solte aqui
              </span>
            </div>
            <va-file-upload
                v-show="!image"
                class="col-8 col-md-6"
                v-model="uploadArray"
                type="single"
                file-types="image/*"
                dropzone
            />
            <img
                v-if="image"
                :src="image"
                class="mt-3"
                height="90"
            />
            <div v-if="image" @click="showModalDelete = true" class="btn btn-danger btn-sm ms-2" title="Remover imagem">
              <i class="bi-trash"></i>
            </div>
            <va-input
                label="nome"
                v-model="user.nome"
                class="mt-3 col-12 col-md-6"
                outline
            />
            <va-input
                label="E-mail"
                v-model="user.email"
                class="mt-3 col-12 col-md-6"
                outline
            />
            <va-button @click="showModalPassword = !showModalPassword" outline class="mt-4 me-3">
              <i class="bi-key me-2"/>Alterar senha
            </va-button>
            <va-button @click="salvar()" color="success" class="mt-3">
              <i class="bi-save me-2"/>Salvar
            </va-button>
          </va-form>
        </div>
      </va-card-content>
    </va-card>

    <!--------- MODAL PASSWORD --------->
    <va-modal
        v-model="showModalPassword"
        title="Alterar a senha"
        hide-default-actions
    >
      <slot>
        <div>
          <va-form ref="formPassword" v-on:keyup.enter="updatePassword()">
            <va-input
                :type=" showPass ? 'text' : 'password'"
                label="Senha"
                v-model="user.senha"
                ref="pass"
                class="mb-4"
                :rules="[value => (value && value.length > 5) || 'Campo senha deve possuir no mínimo 5 caracteres']"
            >
              <template #appendInner>
                <va-icon :name="showPass ? 'visibility_off' : 'visibility'" class="pointer"
                         @click="showPass = !showPass"/>
              </template>
            </va-input>
            <va-input
                :type=" showPass ? 'text' : 'password'"
                label="Repita a senha"
                v-model="senha2"
                ref="pass2"
                :rules="[value => (user.senha === senha2) || 'As senhas não coincidem']"
            />
          </va-form>
        </div>
      </slot>
      <template #footer>
        <va-button @click="showModalPassword = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="updatePassword()">
          Salvar
        </va-button>
      </template>
    </va-modal>

    <!--------- MODAL DELETE --------->
    <va-modal v-model="showModalDelete" hide-default-actions>
      <slot>
        <span class="text-primary">
          Deseja remover a imagem do perfil ?
        </span>
      </slot>
      <template #footer>
        <va-button @click="showModalDelete = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="confirmDelete()" color="warning">
          Excluir
        </va-button>
      </template>
    </va-modal>

  </div>
</template>
<script>
import perfilService from '../../services/perfil-service'

export default {
  name: 'Perfil',
  data() {
    return {
      user: {},
      showModalPassword: false,
      showModalDelete: false,
      showPass: false,
      senha2: '',
      uploadArray: []
    }
  },
  computed: {
    image() {
      if (this.uploadArray[0])
        return URL.createObjectURL(this.uploadArray[0])
      if (this.user.url)
        return this.user.url
      return ''
    }
  },
  async created() {
    await this.getPerfil()
  },
  methods: {
    changePasssword() {
      this.showModalPassword = true
    },

    async confirmDelete() {
      const vm = this
      const handleError = function (err, msg = null) {
        let message
        if (msg)
          message = msg
        else
          message = err.response.data && err.response.data.message ? err.response.data.message : err.message
        vm.$vaToast.init({message: message, color: 'warning'})
        console.log(message, err.response.data)
      }

      await perfilService.deleteImg().catch(handleError)
      await this.getPerfil()
      this.showModalDelete = false
    },

    async getPerfil() {
      const vm = this
      const handleError = function (err, msg = null) {
        let message
        if (msg)
          message = msg
        else
          message = err.response.data && err.response.data.message ? err.response.data.message : err.message
        vm.$vaToast.init({message: message, color: 'warning'})
        console.log(message, err.response.data)
      }

      let success = await perfilService.get().catch(handleError)
      if (success) {
        if (success.data.erro) {
          handleError(success, success.data.mensagem)
        } else {
          this.user = success.data.user
        }
      }
    },

    async updatePassword() {
      const vm = this
      const handleError = function (err, msg = null) {
        console.log(err, msg)
        let message
        if (msg) {
          message = msg
        } else {
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message
          else
            message = err.message
        }
        vm.$vaToast.init({message: message, color: 'warning'})
      }
      if (this.$refs.formPassword.validate()) {
        let success = await perfilService.updatePassword({
          senha: this.user.senha
        }).catch(handleError)
        if (success)
          if (success.data.erro) {
            handleError(success, success.data.mensagem)
          } else {
            this.showModalPassword = false
            this.$vaToast.init({message: 'Senha alterada', color: 'success'})
            this.user.senha = this.senha2 = null
          }
      }
    },

    async salvar() {
      const vm = this
      const handleError = function (err, msg = null) {
        let message
        if (msg)
          message = msg
        else
          message = err.response.data && err.response.data.message ? err.response.data.message : err.message
        vm.$vaToast.init({message: message, color: 'warning'})
        console.log(message, err.response.data)
      }
      if (this.$refs.formUsuario.validate()) {
        let success
        success = await perfilService.update({
          'nome': this.user.nome,
          'email': this.user.email,
          'url' : this.user.url
        }).catch(handleError)
        if (success) {
          if (success.data.erro) {
            handleError(success, success.data.mensagem)
          } else {
            sessionStorage.setItem('username', success.data.user.nome)
            this.showModalUsuario = false
            this.$vaToast.init({message: 'Dados salvos', color: 'success'})
          }
        }
        if (this.uploadArray[0]) {
          let formData = new FormData()
          formData.append('file', this.uploadArray[0])
          success = await perfilService.addImg(formData).catch(handleError)
        }
        await this.getPerfil()
      }
    }
  }
}
</script>