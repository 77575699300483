<template>
  <template @requestError="handleError"/>
</template>
<script>
export default {
  name: "ToastInterceptor",
  methods: {
    handleError(message) {
      this.$vaToast.init({
        message: message,
        color: 'warning'
      })
    }
  }
};
</script>