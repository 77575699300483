<template>
  <div>
    <va-card :bordered="false">
      <va-card-title>
        <va-breadcrumbs color="black">
          <va-breadcrumbs-item>
            <router-link :to="{name:'Dashboard'}" class="text-decoration-none text-dark">Painel Inicial</router-link>
          </va-breadcrumbs-item>
          <va-breadcrumbs-item label="Usuários"/>
        </va-breadcrumbs>
        <div class="btn btn-primary btn-sm" @click="add()">
          <i class="bi-plus"></i>
        </div>
      </va-card-title>
      <va-card-content>
        <table class="va-table va-table--hoverable">
          <thead>
          <tr>
            <th class="d-none d-md-table-cell">Id</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th class="d-none d-md-table-cell">Data do cadastro</th>
            <th class="d-none d-md-table-cell">Bloqueia em</th>
            <th class="d-none d-md-table-cell">Nível</th>
            <th class="centered">Editar/Alterar Senha/Excluir</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="usuario in usuarios" :key="usuario.id">
            <td class="d-none d-md-table-cell">{{ usuario.id }}</td>
            <td>{{ usuario.nome }}</td>
            <td>{{ usuario.email }}</td>
            <td class="d-none d-md-table-cell">{{ formatDate(usuario.createdAt) }}</td>
            <td class="d-none d-md-table-cell">{{ usuario.role !== 'Admin' ? formatDate(usuario.data_expira) : '' }}</td>
            <td class="d-none d-md-table-cell">{{ usuario.role }}</td>
            <td class="centered">
              <div @click="edit(usuario)" class="btn btn-primary btn-sm" title="Editar dados">
                <i class="bi-pen"></i>
              </div>
              <div v-if="isBloqueado(usuario)" @click="desbloquear(usuario)" class="btn btn-warning btn-sm ms-2" title="Desbloquear acesso">
                <i class="bi-unlock"></i>
              </div>
              <div v-if="!isBloqueado(usuario)" @click="changePasssword(usuario)" class="btn btn-success btn-sm ms-2" title="Alterar senha">
                <i class="bi-key"></i>
              </div>
              <div @click="remove(usuario)" class="btn btn-danger btn-sm ms-2" title="Remover usuário">
                <i class="bi-trash"></i>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-evenly" v-if="totalPages > 1">
          <va-pagination
              v-model="page"
              :pages="totalPages"
              size="small"
              color="secondary"
          />
        </div>
      </va-card-content>
    </va-card>

    <!--------- MODAL USUARIO --------->
    <va-modal
        v-model="showModalUsuario"
        :title="editUsuario.id ? 'Editar Usuário #' + editUsuario.id : 'Adicionar usuário'"
        size="large"
        hide-default-actions
    >
      <slot>
        <div>
          <va-form ref="formUsuario" v-on:keyup.enter="salvar()">
            <va-input
                class="mb-4"
                label="Nome"
                v-model="editUsuario.nome"
                :rules="[value => (value && value.length > 0) || 'Campo obrigatório']"
            />
            <va-input
                class="mb-4"
                label="E-mail"
                v-model="editUsuario.email"
                :rules="[value => (value && value.includes('@') && value.includes('.')) || 'Digite um e-mail válido']"
            />
            <va-select
                class="mb-4"
                v-model="editUsuario.role"
                label="Tipo"
                :options="roles"
                outline
                :rules="[value => (value && value.length > 0) || 'Selecione o tipo de usuário']"
            />
            <va-input
                class="mb-4"
                label="CNPJ"
                v-model="editUsuario.cnpj"
                :mask="{blocks: [2, 3, 3, 4, 2], delimiters: ['.', '.', '/', '-']}"

            />
            <va-input
                class="mb-4"
                label="Endereço"
                v-model="editUsuario.endereco"
            />
            <template v-if="!editUsuario.id" class="d-flex">
              <va-input
                  :type=" showPass ? 'text' : 'password'"
                  label="Senha"
                  v-model="editUsuario.senha"
                  ref="password"
                  class="mb-4 mt-4"
                  :rules="[value => (value && value.length > 5) || 'Campo senha deve possuir no mínimo 6 caracteres']"
              >
                <template #appendInner>
                  <va-icon :name="showPass ? 'visibility_off' : 'visibility'" class="pointer" @click="showPass = !showPass"/>
                </template>
              </va-input>
              <va-input
                  :type=" showPass ? 'text' : 'password'"
                  label="Repita a senha"
                  v-model="editUsuario.senha2"
                  ref="password2"
                  :rules="[value => (editUsuario.senha === editUsuario.senha2) || 'As senhas não coincidem']"
              />
            </template>
          </va-form>
        </div>
      </slot>
      <template #footer>
        <va-button @click="showModalUsuario = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="salvar()">
          Salvar
        </va-button>
      </template>
    </va-modal>

    <!--------- MODAL PASSWORD --------->
    <va-modal
        v-model="showModalPassword"
        :title="'Alterar a senha de ' + editUsuario.nome"
        hide-default-actions
    >
      <slot>
        <div>
          <va-form ref="formPassword" v-on:keyup.enter="updatePassword()">
            <va-input
                :type=" showPass ? 'text' : 'password'"
                label="Nova Senha"
                v-model="editUsuario.pass"
                ref="pass"
                class="mt-2 mb-4"
                :rules="[value => (value && value.length > 5) || 'Campo senha deve possuir no mínimo 5 caracteres']"
            >
              <template #appendInner>
                <va-icon :name="showPass ? 'visibility_off' : 'visibility'" class="pointer fs-4" @click="showPass = !showPass"/>
              </template>
            </va-input>
            <va-input
                :type=" showPass ? 'text' : 'password'"
                label="Repita a senha"
                v-model="editUsuario.pass2"
                ref="pass2"
                :rules="[value => (editUsuario.pass === editUsuario.pass2) || 'As senhas não coincidem']"
            />
          </va-form>
        </div>
      </slot>
      <template #footer>
        <va-button @click="showModalPassword = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="updatePassword()" color="success">
          Salvar
        </va-button>
      </template>
    </va-modal>

    <!--------- MODAL DELETE --------->
    <va-modal
        v-model="showModalDelete"
        hide-default-actions
    >
      <slot>
        <div class="text-center">
          <span class="text-primary">
            Confirma a exclusão do usuário <b> {{ editUsuario.nome }} </b> ?
          </span>
          <br/>
          <span class="fs-7 text-danger"><b>Atenção</b>, os relatórios do usuário serão excluídos!</span>
        </div>
      </slot>
      <template #footer>
        <va-button @click="showModalDelete = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="confirmDelete()" color="danger">
          Excluir
        </va-button>
      </template>
    </va-modal>

    <!--------- MODAL DESBLOQUEAR --------->
    <va-modal
        v-model="showModalDesbloquear"
        hide-default-actions
    >
      <slot>
        <span class="text-primary">
          Deseja desbloquear o acesso do usuário <b> {{ editUsuario.nome }} </b> ?
        </span>
      </slot>
      <template #footer>
        <va-button @click="showModalDesbloquear = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="confirmDesbloqueio()" color="warning">
          Desbloquear
        </va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import usuarioService from "../../services/usuario-service"
import filters from "../../services/filters"

export default {
  name: 'Dashboard',
  mixins: [filters],
  data() {
    return {
      page: 1,
      totalPages: 1,
      roles: ['Admin', 'User'],
      usuarios: [],
      showModalUsuario: false,
      showModalPassword: false,
      showModalDelete: false,
      showModalDesbloquear: false,
      editUsuario: {},
      showPass: false
    }
  },
  watch: {
    page() {
      this.getUsuarios()
    }
  },
  created() {
    this.getUsuarios()
  },
  methods: {
    async getUsuarios() {
      const {data} = await usuarioService.get(this.page)
      this.usuarios = data.regs.docs
      this.totalPages = data.regs.pages
    },

    add() {
      this.showModalUsuario = true
      this.editUsuario = { role: 'User'}
    },

    edit(usuario) {
      this.showModalUsuario = true
      this.editUsuario = {...usuario}
    },

    remove(usuario) {
      this.showModalDelete = true
      this.editUsuario = {...usuario}
    },

    changePasssword(usuario) {
      this.showModalPassword = true
      this.editUsuario = {...usuario}
    },

    isBloqueado(usuario) {
      return usuario.role !== 'Admin' && (new Date(usuario.data_expira)) < (new Date())
    },

    desbloquear(usuario) {
      this.showModalDesbloquear = true
      this.editUsuario = {...usuario}
    },

    async salvar() {
      const vm = this
      //Monta função pra gerenciar erros
      const handleError = function (err, msg = null) {
        let message
        if (msg)
          message = msg
        else
          message = err.response.data && err.response.data.message ? err.response.data.message : err.message
        vm.$vaToast.init({message: message, color: 'warning'})
        console.log(message, err.response.data)
      }
      //Valida formulário
      if (this.$refs.formUsuario.validate()) {
        let success

        if (this.editUsuario.id) {
          delete this.editUsuario.senha
          success = await usuarioService.update(this.editUsuario.id, this.editUsuario).catch(handleError)
        } else {
          success = await usuarioService.create(this.editUsuario).catch(handleError)
        }

        if (success) {
          if (success.data.erro) {
            handleError(success, success.data.mensagem)
          } else {
            this.showModalUsuario = false
            await this.getUsuarios()
            this.$vaToast.init({message: 'Dados salvos', color: 'success'})
          }
        }
      }
    },

    async updatePassword() {
      const vm = this
      const handleError = function (err, msg = null) {
        let message
        if (msg)
          message = msg
        else
          message = err.response.data && err.response.data.message ? err.response.data.message : err.message
        vm.$vaToast.init({message: message, color: 'warning'})
        console.log(message, err.response.data)
      }
      if (this.$refs.formPassword.validate()) {
        let success = await usuarioService.updatePassword(this.editUsuario.id, {
          senha: this.editUsuario.pass
        }).catch(handleError)
        if (success)
          if (success.data.erro) {
            handleError(success, success.data.mensagem)
          } else {
            this.showModalPassword = false
            this.$vaToast.init({message: 'Senha alterada', color: 'success'})
          }
      }
    },

    async confirmDelete() {
      const vm = this
      const handleError = function (err, msg = null) {
        let message
        if (msg)
          message = msg
        else
          message = err.response.data && err.response.data.message ? err.response.data.message : err.message
        vm.$vaToast.init({message: message, color: 'warning'})
        console.log(message, err.response.data)
      }
      let success = await usuarioService.delete(this.editUsuario.id).catch(this.handleError)
      if (success)
        if (success.data.erro) {
          handleError(success, success.data.mensagem)
        } else {
          this.showModalDelete = false
          await this.getUsuarios()
          this.$vaToast.init({message: 'Usuário excluído', color: 'success'})
        }
    },

    async confirmDesbloqueio() {
      const vm = this
      const handleError = function (err, msg = null) {
        let message
        if (msg)
          message = msg
        else
          message = err.response.data && err.response.data.message ? err.response.data.message : err.message
        vm.$vaToast.init({message: message, color: 'warning'})
        console.log(message, err.response.data)
      }
      let success = await usuarioService.desbloquear(this.editUsuario.id).catch(vm.handleError)
      if (success)
        if (success.data.erro) {
          handleError(success, success.data.mensagem)
        } else {
          this.showModalDesbloquear = false
          await this.getUsuarios()
          this.$vaToast.init({message: 'Usuário desbloqueado', color: 'success'})
        }
    }
  },
}
</script>
<style scoped>
.centered {
  text-align: center;
}
@media (max-width: 576px) {
  .va-table {
    font-size: 12px;
  }
  .va-table th, .va-table td {
    padding: 3px;
  }
}
</style>