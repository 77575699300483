<template>
  <div>
    <va-card :bordered="false">
      <va-card-title>
        <span class="lead fw-bold">Painel inicial</span>
        <div class="row justify-content-end">
          <div class="col-8 col-md-3">
            <va-select
                class="mb-4"
                label="Período"
                noOptionsText="Sem registros"
                :options="periodos"
                v-model="selecionado"
            />
          </div>
        </div>
      </va-card-title>
      <va-card-content>
        <div class="text-center mb-3">
          <span class="h5 fw-bold">Indicadores</span>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 text-center mb-3">
            <apexchart type="radialBar" :options="radialOptions" :series="iqa_agua"></apexchart>
            <span>IQA - Água</span>
          </div>
          <div class="col-12 col-md-4 text-center mb-3">
            <apexchart type="radialBar" :options="radialOptions" :series="iqa_esgoto"></apexchart>
            <span>IQA - Localidades</span>
          </div>
          <div class="col-12 col-md-4 text-center mb-2">
            <apexchart type="radialBar" :options="radialOptions" :series="geral_residuos"></apexchart>
            <span>Geral - Resíduos</span>
          </div>
        </div>
        <va-divider/>
        <div class="mt-4">
          <div class="text-center">
            <span class="h5 fw-bold">Progresso Anual</span>
          </div>
          <div class="row mt-2">
            <apexchart class="col-12 col-md-4" width="300" type="bar" :options="columnOptions('IQA - Água')" :series="agua"/>
            <apexchart class="col-12 col-md-4" width="300" type="bar" :options="columnOptions('ICE - Esgoto')" :series="esgoto"/>
            <apexchart class="col-12 col-md-4" width="300" type="bar" :options="columnOptions('Geral - Resíduos')" :series="residuos"/>
          </div>
          <div class="row mt-4">
            <apexchart class="col-12 col-md-4 offset-md-2" width="300" type="bar" :options="columnOptions('Geral - Microdrenagem')" :series="micro"/>
            <apexchart class="col-12 col-md-4" width="300" type="bar" :options="columnOptions('Geral - Macrodrenagem')" :series="macro"/>
          </div>
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import relatorioService from "../../services/relatorio-service";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'Dashboard',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      relatorios: [],
      periodos: [],
      selecionado: '',
      agua: [],
      esgoto: [],
      residuos: [],
      micro: [],
      macro: [],
      iqa_agua: [0],
      iqa_esgoto: [0],
      geral_residuos: [0],
      radialOptions: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        theme: {palette: 'palette3'},
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: ['Average Results'],
      },
    }
  },
  async created() {
    let success = await relatorioService.dashboard()
    if (success) {
      if (success.data.reg.length) {
        this.selecionado = success.data.reg[0].periodo
        this.relatorios = success.data.reg
        this.periodos = this.relatorios
            .map(p => p.periodo)
            .filter((el, idx, self) => {
              return self.indexOf(el) === idx
            })
        this.relatorios.forEach((r) => {
          this.agua.push({
            name: r.periodo,
            data: r.agua.indic_iqa ? [parseFloat(r.agua.indic_iqa).toFixed(2)] : [0]
          })
          this.esgoto.push({
            name: r.periodo,
            data: r.esgoto.indic_ice ? [parseFloat(r.esgoto.indic_ice).toFixed(2)] : [0]
          })
          this.residuos.push({
            name: r.periodo,
            data: r.residuos.indic_geral ? [parseFloat(r.residuos.indic_geral).toFixed(2)] : [0]
          })
          this.micro.push({
            name: r.periodo,
            data: r.microdrenagem.indic_geral ? [parseFloat(r.microdrenagem.indic_geral).toFixed(2)] : [0]
          })
          this.macro.push({
            name: r.periodo,
            data: r.macrodrenagem.indic_geral ? [parseFloat(r.macrodrenagem.indic_geral).toFixed(2)] : [0]
          })
        })
      }
    }
  },
  watch: {
    selecionado() {
      this.update()
    },
  },
  methods: {
    update: function () {
      let rel = this.relatorios.filter((r) => r.periodo === this.selecionado)
      if (rel.length) {
        rel = rel[0]

        if (rel.agua.indic_iqa)
          this.iqa_agua = [parseFloat(rel.agua.indic_iqa).toFixed(2)]
        else
          this.iqa_agua = [0]

        if (rel.residuos.indic_geral)
          this.geral_residuos = [parseFloat(rel.residuos.indic_geral).toFixed(2)]
        else
          this.geral_residuos = [0]

        if (rel.esgoto.localidades.length)
          this.iqa_esgoto = rel.esgoto.localidades.map((l) => l.indic_iqa ? parseFloat(l.indic_iqa).toFixed(2) : 0)
        else
          this.iqa_esgoto = [0]
      }
    },
    columnOptions: function (label) {
      return {
        chart: {height: 300},
        xaxis: {categories: [label],},
        theme: {palette: 'palette3'},
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " %"
            }
          }
        }
      }
    }
  }
}
</script>
